import React from "react";

import { useState } from "react";
import {
  IonButton,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonText,
} from "@ionic/react";
import "./RenameDevice.css";

const RenameDevice = (props: any) => {
  const [deviceName, setDeviceName] = useState<string>(props?.deviceName);

  return (
    <IonGrid>
      <IonRow className="ion-justify-content-center ion-padding-bottom">
        <IonText className="primary-heading font-20 ion-padding">
          Rename Device
        </IonText>
      </IonRow>
      <IonRow className="ion-padding-horizontal ion-margin-top ion-margin-start">
        <IonLabel>
          <IonText className="primary-heading font-18">
            Device Name: &nbsp;
          </IonText>
          {props?.deviceName}
        </IonLabel>
      </IonRow>
      <IonRow className="ion-justify-content-center">
        <IonItem className="ion-padding pb-0 width-100">
          <IonInput
            label="Enter new device name"
            labelPlacement="floating"
            value={deviceName}
            maxlength={28}
            onIonInput={(e: any) => {
              setDeviceName(e?.detail?.value);
            }}
          ></IonInput>
        </IonItem>
      </IonRow>
      <IonRow className="ion-justify-content-end">
        <IonButton
          fill="outline"
          size="small"
          onClick={() => {
            props.onclose();
          }}
        >
          Close
        </IonButton>
        <IonButton
          size="small"
          disabled={!deviceName}
          onClick={() => {
            props?.onChange(deviceName);
          }}
        >
          Update
        </IonButton>
      </IonRow>
    </IonGrid>
  );
};
export default RenameDevice;
