import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonGrid,
  IonInput,
  IonItem,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  IonToolbar,
  IonButtons,
  IonIcon,
  IonTitle,
  IonCol,
  IonLabel,
  IonCard,
  IonFooter,
  IonSkeletonText,
  IonCheckbox,
} from "@ionic/react";
import {
  arrowDownOutline,
  arrowUpOutline,
  chevronBackOutline,
  chevronForwardOutline,
  playSkipBackOutline,
  playSkipForwardOutline,
  search,
  reloadOutline,
} from "ionicons/icons";

import "./AppDataTable.css";
import config from "../../common/Config";

interface TableProps {
  loading: boolean;
  headers: any;
  data: any;
  order: string;
  orderBy: string;
  title?: string;
  rowsPerPage?: number;
  enableSearch?: boolean;
  searchBy?: string;
  totalRecords?: number;
  start?: number;
  reloadData?: any;
  hidePagination?: boolean;
  enableRefresh?: any;
  onSelectRow?: any;
  onCheckBoxClick?: any;
}

function AppDataTable(props: TableProps) {
  const [isAsc, setIsAsc] = useState<boolean>(
    props?.order === "asc" ? true : false
  );
  const [rows, setRows] = useState<any>(props?.data);
  const [orderBy, setOrderBy] = useState<string>(props?.orderBy);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(0);
  const [enableSearchBox, setEnableSearchBox] = useState<boolean>(false);

  useEffect(() => {
    setRows(props?.data);
    setPage(props?.start || 0);
    if (!rowsPerPage) {
      setRowsPerPage(props?.rowsPerPage || config.rowsPerPage);
    }
  }, [props]);

  const onButtonClick = (header: any) => {
    setOrderBy(header.id);
    setIsAsc((isAsc) => !isAsc);
  };

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const getCurrentPageRecords = () => {
    let orderByKey: string;
    if (props?.headers?.length) {
      const filteredHeader = props?.headers?.filter((header: any) => {
        return header.id === orderBy;
      });
      if (filteredHeader?.length) {
        orderByKey = filteredHeader[0]?.orderBy
          ? filteredHeader[0]?.orderBy
          : filteredHeader[0]?.id;
      }
    }

    let filteredRecords = [];

    filteredRecords = rows?.sort((a: any, b: any) => {
      const order = isAsc
        ? -descendingComparator(a, b, orderByKey)
        : descendingComparator(a, b, orderByKey);
      return order;
      return a - b;
    });

    return filteredRecords?.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    // onRowsPerPage
  };

  const paginationNextPage = () => {
    setPage((page) => page + 1);
  };

  const paginationPrevPage = () => {
    setPage((page) => page - 1);
  };

  const paginationFirstPage = () => {
    setPage(0);
  };

  const paginationLastPage = () => {
    if (props?.data?.length) {
      if (props?.data?.length % rowsPerPage === 0) {
        setPage((props?.data?.length % rowsPerPage) - 1);
      } else {
        setPage(Math.floor(props?.data?.length / rowsPerPage));
      }
    }
  };

  const onLinkClick = (data: any) => {
    console.log("data", data);
    if (props?.onSelectRow) {
      props.onSelectRow(data);
    }
  };

  const onCheckBoxClick = (data: any) => {
    console.log("data", data);
    if (props?.onCheckBoxClick) {
      props.onCheckBoxClick(data);
    }
  };
  const searchRowData = (searchText: string) => {
    const filteredRows = props.data.filter((row: any) => {
      return row[props?.searchBy || ""]
        .toLowerCase()
        .includes(searchText.toLowerCase());
    });
    setRows(filteredRows);
  };

  const onChangeSearchInputChange = (e: any) => {
    searchRowData(e?.target?.value);
  };

  const getCurrentPageInfo = () => {
    if (page * rowsPerPage + rowsPerPage > (props?.totalRecords || 0)) {
      return `${page * rowsPerPage + 1} - ${props?.totalRecords} of ${
        props?.totalRecords
      }`;
    } else {
      return `${page * rowsPerPage + 1} - ${
        page * rowsPerPage + rowsPerPage
      } of ${props?.totalRecords}`;
    }
  };

  return (
    <IonGrid>
      <IonCard>
        <IonToolbar mode="md">
          <IonButtons slot="primary">
            {props?.enableRefresh && (
              <IonButton disabled={props?.loading} onClick={() => props?.reloadData()}>
                <IonIcon slot="icon-only" icon={reloadOutline} ></IonIcon>
              </IonButton>
            )}
            {props?.enableSearch && !enableSearchBox && (
              <IonButton onClick={() => setEnableSearchBox(true)}>
                <IonIcon slot="icon-only" icon={search}></IonIcon>
              </IonButton>
            )}

            {enableSearchBox && (
              <IonItem>
                <IonInput
                  clearInput={true}
                  placeholder="Search"
                  className="search-input"
                  onIonInput={(e) => {
                    onChangeSearchInputChange(e);
                  }}
                ></IonInput>
              </IonItem>
            )}
          </IonButtons>
          {props?.title && (
            <IonTitle className="ion-text-left p-10">{props?.title}</IonTitle>
          )}
        </IonToolbar>
        <IonGrid>
          <IonRow className="ion-padding-bottom ion-align-items-start">
            {props?.headers?.map((header: any, index: number) => (
              <IonCol key={index} className={`ion-text-${header?.align}`}>
                <IonLabel
                  className="cursor-pointer text-bold label-dark table-header-label"
                  onClick={() => onButtonClick(header)}
                >
                  {header.label}
                  <IonIcon
                    icon={isAsc ? arrowUpOutline : arrowDownOutline}
                    className={`arrow-icon  ${
                      header?.id !== orderBy && "opacity-0"
                    }`}
                  ></IonIcon>
                </IonLabel>
              </IonCol>
            ))}
          </IonRow>
          {!props?.loading
            ? getCurrentPageRecords().map((data: any, index: number) => {
                return (
                  <IonRow
                    key={index}
                    className="ion-align-items-center tbody-row"
                  >
                    {props?.headers?.map((header: any, index: number) => (
                      <IonCol
                        key={index}
                        className={`ion-text-${header?.align}`}
                      >
                        {header?.type === "link" ? (
                          <>
                            {data[header.id] !== null &&
                            data[header.id] !== undefined ? (
                              <IonText
                                className="link-text table-col-text  label-dark cursor-pointer"
                                onClick={() => onLinkClick(data)}
                              >
                                {data[header.id]}
                              </IonText>
                            ) : (
                              <IonText className="table-col-text label-dark ">
                                {header?.defaultText || ""}
                              </IonText>
                            )}
                          </>
                        ) : header?.type === "checkbox" ? (
                          <>
                            {!data?.hideCheckbox && (
                              <IonCheckbox
                                value={data[header.id]}
                                checked={data[header.id]}
                                onIonChange={(e) => {
                                  onCheckBoxClick(data);
                                }}
                              ></IonCheckbox>
                            )}
                          </>
                        ) : (
                          <IonText className="table-col-text label-dark ">
                            {data[header.id] !== null &&
                            data[header.id] !== undefined
                              ? data[header.id]
                              : header?.defaultText}
                          </IonText>
                        )}
                      </IonCol>
                    ))}
                  </IonRow>
                );
              })
            : [...Array(rowsPerPage || 5)].map((value, index) => {
                return (
                  <IonRow key={index} className="ion-align-items-center">
                    <IonSkeletonText
                      animated={true}
                      style={{ height: "3em" }}
                    ></IonSkeletonText>
                  </IonRow>
                );
              })}
          {!props?.loading && rows?.length === 0 && (
            <IonRow className="ion-justify-content-center ion-padding">
              <IonText color="primary no-record-text">
                No records found!
              </IonText>
            </IonRow>
          )}
          {!props?.hidePagination && (
            <IonFooter className="table-header">
              <IonRow className="ion-align-items-center ion-justify-content-end">
                <IonCol size="3" className="ion-text-end">
                  <IonLabel>Rows per page:</IonLabel>
                </IonCol>
                <IonCol size="1">
                  <IonItem lines="none" detail={false}>
                    <IonSelect
                      aria-label="rows"
                      interface="popover"
                      value={rowsPerPage}
                      onIonChange={handleChangeRowsPerPage}
                    >
                      <IonSelectOption value={5}>5</IonSelectOption>
                      <IonSelectOption value={10}>10</IonSelectOption>
                      <IonSelectOption value={15}>15</IonSelectOption>
                    </IonSelect>
                  </IonItem>
                </IonCol>
                <IonCol size="2" className="ion-text-end">
                  <IonLabel>{getCurrentPageInfo()}</IonLabel>
                </IonCol>
                <IonCol size="3" className="ion-text-end">
                  <IonButton
                    fill="clear"
                    className="pagination-icon-btn"
                    onClick={paginationFirstPage}
                    disabled={page === 0}
                  >
                    <IonIcon
                      slot="icon-only"
                      icon={playSkipBackOutline}
                      className="pagination-icon"
                    ></IonIcon>
                  </IonButton>
                  <IonButton
                    fill="clear"
                    className="pagination-icon-btn"
                    onClick={paginationPrevPage}
                    disabled={page === 0}
                  >
                    <IonIcon
                      icon={chevronBackOutline}
                      className="pagination-icon"
                    ></IonIcon>
                  </IonButton>
                  <IonButton
                    fill="clear"
                    className="pagination-icon-btn"
                    disabled={
                      page * rowsPerPage + rowsPerPage >=
                      (props?.totalRecords || 0)
                    }
                    onClick={paginationNextPage}
                  >
                    <IonIcon
                      icon={chevronForwardOutline}
                      className="pagination-icon"
                    ></IonIcon>
                  </IonButton>
                  <IonButton
                    fill="clear"
                    className="pagination-icon-btn"
                    disabled={
                      page * rowsPerPage + rowsPerPage >=
                      (props?.totalRecords || 0)
                    }
                    onClick={paginationLastPage}
                  >
                    <IonIcon
                      icon={playSkipForwardOutline}
                      className="pagination-icon"
                    ></IonIcon>
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonFooter>
          )}
        </IonGrid>
      </IonCard>
    </IonGrid>
  );
}

export default AppDataTable;
