import React, { useContext } from "react";
import { IonRouterOutlet } from "@ionic/react";
import { Redirect, Route, Switch, useLocation } from "react-router";
import routeList from "./RouteList";
import { GlobalContext } from "../context/Provider";
import { isRoleExist } from "../common/Util";
import {
  AUTHENTICATION_IS_SCHOOL_ADMIN,
  AUTHENTICATION_PRIMARY_CAREGIVER,
} from "../constants/permissions";
// import { fbSetCurrentScreen } from "../Services/Analystics";

function AppRoute(props: any) {
  const { roleState } = useContext(GlobalContext);
  const location = useLocation();

  const isAuthenticated = () => {
    return localStorage.access && localStorage.refresh;
  };

  const getRolesList = () => {
    return roleState?.length
      ? roleState
      : localStorage.permissions
      ? localStorage.permissions?.split(",")
      : [];
  };

  const verifyAuthPermission = (route: any) => {
    let isAllowed = false;
    if (route?.isAccessToAll) {
      isAllowed = true;
    } else {
      const rolesList = getRolesList();
      route?.roles?.map((role: any) => {
        if (rolesList?.indexOf(role) !== -1) {
          isAllowed = true;
        }
      });
    }
    return isAllowed;
  };

  const setDocumentTitle = (title: string) => {
    document.title = title;
  };
  const RenderRoute = (route: any) => {
    console.log("Routes::", route);
    const rolesList = getRolesList();
    console.log("Auth permissions::", rolesList);

    const isPermitted = verifyAuthPermission(route);
    const redirectComponent = route.component;
    console.log("Is allowed to access:::", isPermitted);
    console.log("Is isAuthenticated:::", isAuthenticated());
    console.log("isPermitted::", isPermitted);

    if (isPermitted && route?.isAuthRequired && !isAuthenticated()) {
      // fbSetCurrentScreen("Login");
      setDocumentTitle("Login");
      return <Redirect push to="/login" />;
    } else if (isPermitted && !route?.isAuthRequired && isAuthenticated()) {
      // fbSetCurrentScreen("Home");
      setDocumentTitle("Home");
      return <Redirect push to="/home" />;
    } else if (!isPermitted && route?.isAuthRequired && isAuthenticated()) {
      // fbSetCurrentScreen("Home");
      setDocumentTitle("Home");
      return <Redirect push to="/home" />;
    }

    if (
      isRoleExist(rolesList, [AUTHENTICATION_IS_SCHOOL_ADMIN]) &&
      localStorage.is_profile_created === "false" &&
      location.pathname !== "/school/create"
    ) {
      return <Redirect push to="/school/create" />;
    }

    if (
      isRoleExist(rolesList, [AUTHENTICATION_PRIMARY_CAREGIVER]) &&
      localStorage.is_profile_created === "false" &&
      location.pathname !== "/account/profile"
    ) {
      return <Redirect push to="/account/profile" />;
    }
    // fbSetCurrentScreen(route.title);
    setDocumentTitle(route.title);
    return (
      <Route path={route.path} exact component={redirectComponent}></Route>
    );
  };

  return (
    <IonRouterOutlet id="main">
      <Switch>
        {routeList.map((route, index) => (
          <RenderRoute {...route} key={route.id}></RenderRoute>
        ))}
      </Switch>
    </IonRouterOutlet>
  );
}

export default AppRoute;
