import React, { useEffect } from "react";
import { useState } from "react";
import { InputInputEventDetail, IonInputCustomEvent } from "@ionic/core";
import {
  IonButton,
  IonCol,
  IonContent,
  IonDatetime,
  IonDatetimeButton,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  useIonToast,
  useIonAlert,
} from "@ionic/react";
import { addCircle, removeCircle } from "ionicons/icons";

import Hamburger from "../../components/HamburgerMenu/Hamburger";
import "./AddChildren.css";
import { format } from "date-fns";
import { getAllClassrooms } from "../../Services/Teacher";
import { addNewChildren, verifyChild } from "../../Services/Child";
import toastConfig from "../../common/toast-config";
import Classroom from "../../components/Classroom/Classroom";
import { useHistory } from "react-router";
import AppInput from "../../components/AppInput/AppInput";
import AppButton from "../../components/AppButton/AppButton";
(window as any).format = format;

interface Child {
  id?: number;
  first_name: string;
  last_name: string;
  dob: string;
  gender: string;
}

const AddChildren: React.FC = () => {
  const [present] = useIonToast();
  const [presentAlert] = useIonAlert();
  const [newChild, setNewChild] = useState<Child>({
    first_name: "",
    last_name: "",
    dob: `${format(new Date(), "yyyy-MM-dd")}`,
    gender: "",
  });
  const [childList, setChildList] = useState<any>([]);
  const [clicked, setClicked] = useState(false);
  const [selectedClassroom, setSelectedClassroom] = useState<number | null>(
    parseInt(localStorage?.classroom_id, 10)
  );
  const [isAPIUpdating, setAPIUpdating] = useState<boolean>(false);
  const history = useHistory();

  const addNewChild = (isSaveContinue?: boolean) => {
    if (!isSaveContinue) {
      setChildList((childList: any) => [newChild, ...childList]);
    }

    setNewChild({
      first_name: "",
      last_name: "",
      dob: `${format(new Date(), "yyyy-MM-dd")}`,
      gender: "",
    });
    console.log("Child >>>", childList);
    if (isSaveContinue) {
      saveChildren();
    }
  };

  const removeChild = (child: any, index: number) => {
    const removeChild = childList[index];
    if (removeChild?.first_name === child?.first_name) {
      childList?.splice(index, 1);
      setChildList((childList: any) => [...childList]);
    }
  };

  const saveChildren = () => {
    let children = [];
    if (childList?.length) {
      children = childList;
    }

    if (
      newChild?.first_name?.length &&
      newChild?.last_name?.length &&
      newChild?.dob?.length &&
      newChild?.gender?.length
    ) {
      children.push(newChild);
    }
    setAPIUpdating(true);
    addNewChildren(children, selectedClassroom || 0)
      .then((res: any) => {
        if (res?.status === 201) {
          setAPIUpdating(false);
          setNewChild({
            first_name: "",
            last_name: "",
            dob: `${format(new Date(), "yyyy-MM-dd")}`,
            gender: "",
          });
          setChildList([]);
          present({
            ...toastConfig.success,
            message: `Children added successfully!`,
          });
          history.push("/home");
        }
      })
      .catch(() => {
        present({
          ...toastConfig.error,
          message: `Unable to add children at the moment. Please try again later.`,
        });
        setAPIUpdating(false);
      });
  };

  const showChildDuplicateAlert = () => {
    presentAlert({
      message: `${newChild?.first_name} ${newChild?.last_name} is already entered in this classroom.`,
      buttons: [
        {
          text: "Close",
          role: "cancel",
          handler: () => {
            setNewChild({
              first_name: "",
              last_name: "",
              dob: `${format(new Date(), "yyyy-MM-dd")}`,
              gender: "",
            });
          },
        },
      ],
    });
  };

  const showChildAssignedAlert = (
    child_name: string,
    classroom_name: string,
    isSaveContinue?: boolean
  ) => {
    presentAlert({
      message: `${child_name} is assigned to ${classroom_name}. Do you want to remove from ${classroom_name} and add to the current classroom?`,
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          handler: () => {
            setNewChild({
              first_name: "",
              last_name: "",
              dob: `${format(new Date(), "yyyy-MM-dd")}`,
              gender: "",
            });
          },
        },
        {
          text: "Continue",
          role: "confirm",
          handler: () => {
            addNewChild(isSaveContinue);
          },
        },
      ],
    });
  };

  const showSameClassroomAlert = (newChild: any) => {
    presentAlert({
      message: `${newChild?.first_name} ${newChild?.last_name} is already added in this classroom.`,
      buttons: [
        {
          text: "Close",
          role: "cancel",
          handler: () => {
            setNewChild({
              first_name: "",
              last_name: "",
              dob: `${format(new Date(), "yyyy-MM-dd")}`,
              gender: "",
            });
          },
        },
      ],
    });
  };

  const showChildExistAlert = (newChild: any, isSaveContinue?: boolean) => {
    presentAlert({
      message: `${newChild?.first_name} ${newChild?.last_name} already exists. Do you want to add to the current classroom?`,
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          handler: () => {
            setNewChild({
              first_name: "",
              last_name: "",
              dob: `${format(new Date(), "yyyy-MM-dd")}`,
              gender: "",
            });
          },
        },
        {
          text: "Continue",
          role: "confirm",
          handler: () => {
            addNewChild(isSaveContinue);
          },
        },
      ],
    });
  };

  const verifyCurrentEntries = () => {
    const filteredChild = childList?.filter((child: any) => {
      return (
        child?.first_name === newChild.first_name &&
        child.last_name === newChild.last_name &&
        child.dob === newChild.dob &&
        child.gender === newChild.gender
      );
    });
    return !filteredChild?.length;
  };

  const verifyChildren = (isSaveContinue?: boolean) => {
    if (verifyCurrentEntries()) {
      verifyChild(selectedClassroom || 0, newChild)
        .then((res: any) => {
          const childInfo = res?.data;
          if (!childInfo?.child_id) {
            addNewChild(isSaveContinue);
          }

          if (
            childInfo?.child_id &&
            childInfo?.child_classroom_other &&
            childInfo?.child_classroom_other_name
          ) {
            newChild.id = childInfo?.child_id;
            setNewChild(newChild);
            showChildAssignedAlert(
              `${newChild?.first_name} ${newChild?.last_name}`,
              childInfo?.child_classroom_other_name,
              isSaveContinue
            );
          } else if (childInfo?.child_id && !childInfo?.child_classroom_same) {
            newChild.id = childInfo?.child_id;
            showChildExistAlert(newChild, isSaveContinue);
          } else if (childInfo?.child_id && childInfo?.child_classroom_same) {
            showSameClassroomAlert(newChild);
          }

          setClicked(false);
        })
        .catch(() => {
          setClicked(false);
          console.log("error")
        });
    } else {
      setClicked(false);
      showChildDuplicateAlert();
    }
  };

  const validateAndSaveChildren = () => {
    if (newChild?.first_name && newChild?.last_name && newChild?.gender) {
      verifyChildren(true);
    } else {
      saveChildren();
    }
  };

  return (
    <IonPage>
      <Hamburger></Hamburger>
      <IonContent fullscreen>
        <IonGrid className="container-lg ion-padding-top">
          <IonRow className="ion-justify-content-center">
            <IonText className="primary-heading"> Add Children</IonText>
          </IonRow>

          <IonRow className="ion-margin-top ion-padding-top">
            <IonCol size="3">
              <IonLabel className="text-bold">Child First Name*</IonLabel>
            </IonCol>

            <IonCol size="3">
              <IonLabel className="text-bold">Child Last Name*</IonLabel>
            </IonCol>

            <IonCol size="3">
              <IonLabel className="text-bold">Child DOB* </IonLabel>
            </IonCol>

            <IonCol size="2">
              <IonLabel className="text-bold">Child’s Gender</IonLabel>
            </IonCol>

            <IonCol size="1"></IonCol>
          </IonRow>

          {/* iterate  */}
          {childList.map((child: any, index: number) => (
            <IonRow key={index} className="ion-align-items-end">
              <IonCol size="3">
                <AppInput
                  itemDisabled={true}
                  itemClassName="ion-no-padding"
                  placeholder="Enter"
                  value={child.first_name}
                  onInputChange={(value: any) => {
                    child.first_name = value;
                    setChildList([...childList]);
                  }}
                ></AppInput>
              </IonCol>

              <IonCol size="3">
                <AppInput
                  itemClassName="ion-no-padding"
                  itemDisabled={true}
                  placeholder="Enter"
                  value={child.last_name}
                  onInputChange={(value: any) => {
                    child.last_name = value;
                    setChildList([...childList]);
                  }}
                ></AppInput>
              </IonCol>

              <IonCol size="3">
                <IonItem detail={false} className="ion-no-padding" disabled>
                  <IonDatetimeButton
                    datetime={`datetime${index}`}
                  ></IonDatetimeButton>
                  <IonModal keepContentsMounted={true}>
                    <IonDatetime
                      id={`datetime${index}`}
                      presentation="date"
                      showDefaultButtons={true}
                      value={child?.dob}
                      max={format(new Date(), "yyyy-MM-dd") + "T00:00:00"}
                      onIonChange={(event: any) => {
                        setNewChild((child: any) => ({
                          ...child,
                          dob: event?.detail?.value.split("T")[0],
                        }));
                      }}
                    ></IonDatetime>
                  </IonModal>
                </IonItem>
              </IonCol>

              <IonCol size="2">
                <IonItem detail={false} className="ion-no-padding" disabled>
                  <IonSelect
                    value={child.gender}
                    className="primary-dark"
                    labelPlacement="floating"
                    interface="popover"
                    onIonChange={(event) => {
                      setNewChild((child: any) => ({
                        ...child,
                        gender: event?.detail?.value,
                      }));
                    }}
                  >
                    <IonSelectOption value="female">Female</IonSelectOption>
                    <IonSelectOption value="male">Male</IonSelectOption>
                    <IonSelectOption value="other">Other</IonSelectOption>
                  </IonSelect>
                </IonItem>
              </IonCol>

              <IonCol size="1" className="ion-no-padding">
                <IonButton
                  fill="clear"
                  className="children-icon-btn"
                  onClick={() => {
                    removeChild(child, index);
                  }}
                >
                  <IonIcon
                    slot="icon-only"
                    color="danger"
                    icon={removeCircle}
                  ></IonIcon>
                </IonButton>
              </IonCol>
            </IonRow>
          ))}

          {/* new child fields */}
          <IonRow className="ion-align-items-end">
            <IonCol size="3">
              <IonItem detail={false} className="ion-no-padding">
                <IonInput
                  type="text"
                  placeholder="Enter"
                  value={newChild.first_name}
                  className="child-input"
                  onIonInput={(
                    event: IonInputCustomEvent<InputInputEventDetail>
                  ) => {
                    setNewChild((newChild: any) => ({
                      ...newChild,
                      first_name: event?.target?.value,
                    }));
                  }}
                ></IonInput>
              </IonItem>
            </IonCol>

            <IonCol size="3">
              <IonItem detail={false} className="ion-no-padding">
                <IonInput
                  type="text"
                  placeholder="Enter"
                  value={newChild.last_name}
                  className="child-input"
                  onIonInput={(
                    event: IonInputCustomEvent<InputInputEventDetail>
                  ) => {
                    setNewChild((newChild: any) => ({
                      ...newChild,
                      last_name: event?.target?.value,
                    }));
                  }}
                ></IonInput>
              </IonItem>
            </IonCol>

            <IonCol size="3">
              <IonItem detail={false} className="ion-no-padding">
                <IonDatetimeButton datetime="datetime"></IonDatetimeButton>
                <IonModal keepContentsMounted={true}>
                  <IonDatetime
                    id="datetime"
                    presentation="date"
                    showDefaultButtons={true}
                    value={newChild?.dob}
                    max={format(new Date(), "yyyy-MM-dd") + "T00:00:00"}
                    onIonChange={(event: any) => {
                      setNewChild((newChild: any) => ({
                        ...newChild,
                        dob: event?.detail?.value.split("T")[0],
                      }));
                    }}
                  ></IonDatetime>
                </IonModal>
              </IonItem>
            </IonCol>

            <IonCol size="2">
              <IonItem detail={false} className="ion-no-padding">
                <IonLabel className="primary-dark" position="floating">
                  Gender
                </IonLabel>
                <IonSelect
                  value={newChild.gender}
                  name="gender"
                  className="primary-dark"
                  labelPlacement="floating"
                  interface="popover"
                  onIonChange={(event) => {
                    setNewChild((newChild: any) => ({
                      ...newChild,
                      gender: event?.detail?.value,
                    }));
                  }}
                >
                  <IonSelectOption value="female">Female</IonSelectOption>
                  <IonSelectOption value="male">Male</IonSelectOption>
                  <IonSelectOption value="other">Other</IonSelectOption>
                </IonSelect>
              </IonItem>
            </IonCol>
            <IonCol size="1" className="ion-no-padding">
              <IonButton
                fill="clear"
                className="children-icon-btn"
                disabled={
                  !newChild?.first_name?.length ||
                  !newChild?.last_name?.length ||
                  !newChild?.dob?.length ||
                  !newChild?.gender?.length ||
                  clicked
                }
                onClick={() => {
                  // addNewChild();
                  setClicked(true);
                  verifyChildren();
                }}
              >
                <IonIcon
                  color="secondary"
                  slot="icon-only"
                  icon={addCircle}
                ></IonIcon>
              </IonButton>
            </IonCol>
          </IonRow>

          <IonRow className="ion-justify-content-center ion-margin-top">
            <AppButton
              className="width-35 ion-margin-top"
              buttonText="Save"
              isLoading={isAPIUpdating}
              disabled={
                (!childList?.length || isAPIUpdating) &&
                (!newChild?.first_name?.length ||
                  !newChild?.last_name?.length ||
                  !newChild?.dob?.length ||
                  !newChild?.gender?.length)
              }
              onButtonClick={() => validateAndSaveChildren()}
            />
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default AddChildren;
