import { App, AppInfo } from "@capacitor/app";
import { Device, DeviceId } from "@capacitor/device";
import GitInfo from "react-git-info/macro";
import config from "../conf.json";
import { Capacitor } from "@capacitor/core";

type AppInformation = {
  label: string;
  value: string | number;
  order: number;
};

export const validateEmailFormat = (email: string) => {
  return email?.match(
    // eslint-disable-next-line
    /^([a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)$/
  );
};

export const isRoleExist = (authPermissions: any, currentPermission: any) => {
  let isExist = false;
  if (currentPermission?.length) {
    currentPermission?.map((role: string) => {
      if (authPermissions?.includes(role)) {
        isExist = true;
      }
    });
  }
  return isExist;
};

export const sortList = (list: any, key: any) => {
  if (list?.length) {
    list?.sort((a: any, b: any) => {
      if (a[key]?.toLowerCase() < b[key]?.toLowerCase()) {
        return -1;
      }
      if (a[key]?.toLowerCase() > b[key]?.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  }
  return list;
};

export const isAccountSetupComplete = () => {
  let isComplete = true;
  if (
    localStorage.is_school_assigned === "false" ||
    localStorage.is_profile_created === "false"
  ) {
    isComplete = false;
  }

  return isComplete;
};

export const getGoalCompleteStatus = (goal: number, total_ctc: number) => {
  const average = (total_ctc / goal) * 100;
  if (average >= 100) {
    return ["primary", "primary", "primary", "primary"];
  } else if (average >= 75) {
    return ["primary", "primary", "primary", "medium"];
  } else if (average >= 50) {
    return ["primary", "primary", "medium", "medium"];
  } else if (average >= 25) {
    return ["primary", "medium", "medium", "medium"];
  } else {
    return ["medium", "medium", "medium", "medium"];
  }
};

export const getAppInfo = async () => {
  const info: AppInformation[] = [];
  let appInfo: AppInfo = {} as AppInfo;
  let idInfo: DeviceId = {} as DeviceId;
  const isNativePlatform = Capacitor.isNativePlatform();

  const gitInfo = GitInfo();
  if (isNativePlatform) {
    appInfo = await App.getInfo();
    idInfo = await Device.getId();
  }
  console.log("App Info::", info);
  info.push(
    {
      label: "App Version",
      value: appInfo?.version || "",
      order: 1,
    },
    {
      label: "Build Version",
      value: appInfo?.build || "",
      order: 2,
    },
    {
      label: "Tablet Unique Identifier",
      value: idInfo?.identifier,
      order: 3,
    },
    {
      label: "Environment",
      value: config?.environment,
      order: 4,
    },
    {
      label: "Git Hash",
      value: gitInfo?.commit?.shortHash,
      order: 5,
    }
  );
  return info;
};

const checkPad = (value: number) => {
  if (value < 10) {
    return `0${value}`;
  }
  return value;
};

export const getMinutesToHours = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const minute = minutes % 60;

  return `${checkPad(hours)} Hr ${checkPad(minute)} Min`;
};

export const setAppVersionInfo = async () => {
  console.log("Calling set app and build version >>>>");
  const { version, build } = await App.getInfo();
  console.log("Util::App Info >>>>>>>>>>>>>>>>>> Version::", version);
  console.log("Util::App Info >>>>>>>>>>>>>>>>>> Build::", build);

  if (version) {
    localStorage.app_version = version;
  }
  if (build) {
    localStorage.build_version = build;
  }
};
