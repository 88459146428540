import React from 'react'
import {
  IonAccordion,
  IonIcon,
  IonItem,
  IonLabel,
  IonMenuToggle,
  IonText,
} from "@ionic/react";

import { useHistory } from "react-router-dom";

import "./Menu.css";

interface SubMenu {
  id?: string;
  title?: string;
  path?: string;
  subMenu?: any;
  icon?: string;
}

interface Routes {
  id?: string;
  title?: string;
  path?: string;
  icon?: string;
  menu?: SubMenu;
  subMenu?: SubMenu[];
  activeMenu?: any;
}

const MenuItem: React.FC<Routes> = (props?: Routes) => {
  const history = useHistory();
  return (
    <>
      {props?.menu?.subMenu?.length > 0 ? (
        <IonAccordion value={props?.menu?.id}>
          <IonItem
            slot="header"
            lines="none"
            className={`cursor-pointer primary-heading ${
              props?.activeMenu === props?.menu?.id ? "hamburger-active" : ""
            }`}
          >
            {/* <IonIcon
              key={props?.menu?.icon}
              src={props?.menu?.icon}
              color="primary"
              className="primary menu-icon"
            ></IonIcon> */}
            <IonLabel className="primary-heading">{props?.menu?.title}</IonLabel>
          </IonItem>
          <div slot="content">
            <IonMenuToggle>
              {props?.menu?.subMenu?.map((subMenu: any, index: number) => {
                return (
                  <IonItem
                    lines="none"
                    key={index}
                    routerLink={subMenu?.path}
                    detail={false}
                    className={`cursor-pointer sub-menu-item ${
                      location.pathname === subMenu.path
                        ? "hamburger-active"
                        : ""
                    } ${index === 0 ? "m-t-5p" : ""}`}
                  >
                    <IonIcon
                      color="primary"
                      src={subMenu.path}
                      className="menu-icon"
                    ></IonIcon>
                    <IonText className="primary">{subMenu.title}</IonText>
                  </IonItem>
                );
              })}
            </IonMenuToggle>
          </div>
        </IonAccordion>
      ) : (
        <IonMenuToggle>
          <IonItem
            lines="none"
            key={props?.menu?.path}
            routerLink={props?.menu?.path}
            detail={false}
            className={`cursor-pointer ${
              props?.activeMenu === props?.menu?.id ? "hamburger-active" : ""
            }`}
          >
            <IonIcon
              color="primary"
              src={props?.menu?.icon}
              className="menu-icon"
            ></IonIcon>

            <IonText className="primary-heading">{props?.menu?.title}</IonText>
          </IonItem>
        </IonMenuToggle>
      )}
    </>
  );
};

export default MenuItem;
