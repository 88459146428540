import React from "react";

import { Capacitor } from "@capacitor/core";
import { Geolocation } from "@capacitor/geolocation";
import { BLE } from "@ionic-native/ble";
import {
  NativeSettings,
  AndroidSettings,
  IOSSettings,
} from "capacitor-native-settings";
import { AndroidPermissions } from "@ionic-native/android-permissions";

import { useIonAlert } from "@ionic/react";
import { AppLogger } from "../Services/Logger";

const platform = Capacitor.getPlatform();
const isNativePlatform = Capacitor.isNativePlatform();

const logger = (type: string, msg: any) => {
  if (localStorage.teacher_id) {
    AppLogger(
      {
        device_id: localStorage.teacher_id,
      },
      "Listner",
      type,
      msg
    );
  }
};

const sendLocationPermissionStatus = (status: boolean, callback: any) => {
  callback(status);
};

const sendBleStatus = (status: boolean, callback: any) => {
  callback(status);
};

const sendBleStatusWithMessage = (message: any, callback: any) => {
  callback(message);
};

const sendLocationStatusWithMessage = (message: any, callback: any) => {
  callback(message);
};

const checkPermissions = (callback: any) => {
  Geolocation.checkPermissions().then(
    (request) => {
      logger(
        "info",
        `Android checkPermissions response::, ${JSON.stringify(request)}`
      );
      console.log("Location", request);
      if (request?.location === "granted") {
        sendLocationPermissionStatus(true, callback);
      }
    },
    (error) => {
      logger(
        "info",
        `Android checkPermissions error::, ${JSON.stringify(error)}`
      );
      sendLocationPermissionStatus(false, callback);
    }
  );
};

const checkAndroidPermission = (callback: any) => {
  window.bluetoothle.isLocationEnabled(
    function (res: any) {
      logger(
        "info",
        `Android isLocationEnabled response::, ${JSON.stringify(res)}`
      );
      console.log("isLocationEnabled response::", res);
      if (res?.isLocationEnabled) {
        checkPermissions(callback);
      } else {
        sendLocationPermissionStatus(false, callback);
      }
    },

    function (err: any) {
      logger(
        "error",
        `Android isLocationEnabled error::, ${JSON.stringify(err)}`
      );
      console.log("isLocationEnabled error", err);
    }
  );
};

export const checkLocationPermission = async (callback: any) => {
  logger("info", `Checking location permission...`);
  if (platform === "android") {
    if (localStorage.osVersion && parseInt(localStorage.osVersion) >= 12) {
      console.log(
        "Android versions >>>>>>>>>>>",
        parseInt(localStorage.osVersion)
      );
      window.bluetoothle.hasPermission(function (res: any) {
        console.log("Location permission::>>>>>>>>>>>>>>>", res);
        logger(
          "info",
          `Location Android 12 hasPermission response::, ${JSON.stringify(res)}`
        );
        if (res?.hasPermission) {
          window.bluetoothle.hasPermissionBtScan(function (res: any) {
            logger(
              "info",
              `Location Android 12 hasPermissionBtScan response::, ${JSON.stringify(
                res
              )}`
            );
            console.log("hasPermissionBtScan", res);
            if (res?.hasPermission) {
              sendLocationPermissionStatus(true, callback);
            } else {
              sendLocationPermissionStatus(false, callback);
            }
          });
        } else {
          sendLocationPermissionStatus(false, callback);
        }
      });
    } else {
      checkAndroidPermission(callback);
    }
  }

  if (platform === "ios") {
    checkPermissions(callback);
  }
};

export const checkBLEPermission = (callback: any) => {
  window.bluetoothle?.isEnabled(function (res: any) {
    console.log("BLE isEnabled", res);
    logger("info", `Bluetooth permission isEnabled::, ${JSON.stringify(res)}`);
    if (res?.isEnabled) {
      if (platform === "android" && parseInt(localStorage?.osVersion) >= 12) {
        checkAndroidLatestBLE(callback);
      } else {
        sendBleStatus(true, callback);
      }
    } else {
      sendBleStatus(false, callback);
    }
  });
};

const checkAndroidLatestBLE = (callback: any) => {
  window.bluetoothle.hasPermissionBtConnect(function (res: any) {
    console.log("DP:: hasPermissionBtConnect", res);
    logger(
      "info",
      `Location Android 12 hasPermissionBtConnect response::, ${JSON.stringify(
        res
      )}`
    );
    if (res?.hasPermission) {
      sendBleStatus(true, callback);
    } else {
      sendBleStatus(false, callback);
    }
  });
};

export const enableBluetooth = async (isEnable: any, callback: any) => {
  console.log("Enable bluetooth::::", isEnable);
  logger("info", `Enable bluetooth::, ${isEnable}`);
  if (isEnable) {
    console.log("Enable Bluetooth");
    if (platform === "android") {
      try {
        const response = await BLE.enable();
        logger(
          "info",
          `Bluetooth enabled response:: ${JSON.stringify(response)}`
        );
        if (response === "OK") {
          sendBleStatus(true, callback);
        } else {
          sendBleStatus(false, callback);
        }
      } catch (error: any) {
        console.log("BLE err::", error);
        showBLEAlert(callback, true);
      }
    } else if (platform === "ios") {
      showBLEAlert(callback);
    }
  }
};

export const openIOSSettings = () => {
  logger("info", `Open iOS App settings`);
  NativeSettings.openIOS({
    option: IOSSettings.App,
  });
};

const showIOSLocationAlert = (type: string, callback: any) => {
  logger("info", `Show iOS location not enabled alert`);
  const message =
    type === "prompt"
      ? "Please enable location services in settings."
      : "Please enable location service for Luet in settings";
  sendLocationStatusWithMessage(
    {
      isEnabled: false,
      redirectToSettings: true,
      message,
    },
    callback
  );
};

const requestIOSLocation = (callback: any) => {
  logger("info", `Start::Request iOS location`);
  Geolocation.requestPermissions().then(
    (request) => {
      logger(
        "info",
        `Request iOS location permission response:: ${JSON.stringify(request)}`
      );
      if (request?.location === "granted") {
        sendLocationPermissionStatus(true, callback);
      } else if (
        request?.location === "prompt" ||
        request?.location === "denied"
      ) {
        showIOSLocationAlert(request?.location, callback);
      }
    },
    (error) => {
      if (error?.errorMessage === "Location services are not enabled") {
        showIOSLocationAlert("prompt", callback);
      }
    }
  );
};

const enableIOSLocation = async (callback: any) => {
  logger("info", `Enable iOS location permission`);
  Geolocation.checkPermissions().then(
    (permission) => {
      if (
        permission.location === "granted" ||
        permission.location === "prompt" ||
        permission.location === "denied"
      ) {
        requestIOSLocation(callback);
      }
    },
    (error) => {
      logger(
        "info",
        `Request iOS location permission error:: ${JSON.stringify(error)}`
      );
      if (error?.errorMessage === "Location services are not enabled") {
        showIOSLocationAlert("prompt", callback);
      }
    }
  );
};

export const enableLocation = (callback: any) => {
  if (platform === "ios") {
    enableIOSLocation(callback);
  } else {
    window.bluetoothle.requestPermissionBtScan(function (res: any) {
      console.log("requestPermissionBtScan res", res);
      logger(
        "info",
        `Request Android location permission response:: ${JSON.stringify(res)}`
      );
      if (res?.requestPermission) {
        window.bluetoothle.requestPermissionBtConnect(function (res: any) {
          console.log("requestPermissionBtConnect res", res);
          if (res?.requestPermission) {
            enableAndroidLocation(true, callback);
          } else {
            showLocationAlert(callback);
          }
        });
        enableAndroidLocation(true, callback);
      } else {
        showLocationAlert(callback);
      }
    });
  }
};

const enableAndroidLocation = async (isEnable: boolean, callback: any) => {
  console.log("Enable android location", isEnable);
  if (isEnable) {
    console.log("AndroidPermissions::::", AndroidPermissions);
    AndroidPermissions.requestPermissions([
      AndroidPermissions.PERMISSION.ACCESS_COARSE_LOCATION,
      AndroidPermissions.PERMISSION.ACCESS_FINE_LOCATION,
    ]).then(
      (res) => {
        logger(
          "info",
          `Request Android location permission (requestPermissions) response:: ${JSON.stringify(
            res
          )}`
        );
        console.log("AndroidPermissions.requestPermissions", res);
        if (res?.hasPermission) {
          sendLocationPermissionStatus(true, callback);
        } else {
          sendLocationPermissionStatus(false, callback);
          showLocationAlert(callback);
        }
      },
      (error) => {
        logger(
          "error",
          `Request Android location permission (requestPermissions) response:: ${JSON.stringify(
            error
          )}`
        );
        console.log("AndroidPermissions.requestPermissions error", error);
      }
    );
  } else {
    checkLocationPermission(callback);
  }
};

export const openAndroidSettings = () => {
  NativeSettings.openAndroid({
    option: AndroidSettings.ApplicationDetails,
  });
};

const showBlePermissionAlert = (callback: any) => {
  const message = "Please enable bluetooth permission in settings.";
  sendBleStatusWithMessage(
    {
      isEnabled: false,
      redirectToSettings: true,
      message,
    },
    callback
  );
};

const checkLatestAndroidPermission = (callback: any, isErrorOccur?: boolean) => {
  window.bluetoothle.hasPermissionBtConnect(function (res: any) {
    console.log("DP:: hasPermissionBtConnect", res);
    if (res?.hasPermission) {
      sendBleStatus(!isErrorOccur, callback);
    } else {
      showBlePermissionAlert(callback);
    }
  });
};

const showBLEAlert = (callback: any, isErrorOccur?: boolean) => {
  if (platform === "android" && parseInt(localStorage?.osVersion) >= 12) {
    checkLatestAndroidPermission(callback, isErrorOccur);
  } else {
    BLE.isEnabled()
      .then((res: any) => {
        sendBleStatus(true, callback);
      })
      .catch((err: any) => {
        showBlePermissionAlert(callback);
      });
  }
};

const showLocationAlert = (callback: any) => {
  logger("info", `Shown location enable permission alert`);
  const message = "Please enable location permission in settings.";
  sendLocationStatusWithMessage(
    {
      isEnabled: false,
      redirectToSettings: true,
      message,
    },
    callback
  );
};
