import {
  BLE_TURNED_ON,
  BLE_TURNED_OFF,
  BLE_CONNECTED,
  BLE_DISCONNECTED,
  BLE_ERROR,
} from "../../constants";

const permissions = (state: any, action: any) => {
  switch (action) {
    case BLE_TURNED_ON:
      return {
        ...state,
        bleTurnedOn: true,
      };
    case BLE_TURNED_OFF:
      return {
        ...state,
        bleTurnedOn: false,
      };
    case "LOCATION_TURNED_ON":
      return {
        ...state,
        locationTurnedOn: true,
      };
    case "LOCATION_TURNED_OFF":
      return {
        ...state,
        locationTurnedOn: false,
      };

    default:
      return state;
  }
};

export default permissions;
