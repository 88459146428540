import React from "react";
import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Menu from "./components/SideMenu/Menu";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./App.css";
import AppRoute from "./routes/Route";
import { GlobalProvider } from "./context/Provider";
import AppListener from "./AppListener";
import Auth from "./Auth";
import { AppLogger } from "./Services/Logger";

delete localStorage.is_app_update_needed;

setupIonicReact();

declare global {
  interface Window {
    bluetoothle?: any;
  }
}
const App: React.FC = (props: any) => {
  return (
    <IonApp>
      <GlobalProvider>
        <IonReactRouter>
          <IonSplitPane when="(min-width: 992px)" contentId="main">
            <Auth></Auth>
            <AppListener></AppListener>
            <Menu />
            <AppRoute></AppRoute>
          </IonSplitPane>
        </IonReactRouter>
      </GlobalProvider>
    </IonApp>
  );
};

export default App;
