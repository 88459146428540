import React, { FC } from "react";
import { IonButton, IonSpinner } from "@ionic/react";

interface AppButtonProps {
  id?: string;
  className?: string;
  expand?: any;
  type?: any;
  fill?: any;
  color?: string;
  disabled?: boolean;
  isLoading?: boolean;
  onButtonClick?: any;
  buttonText: string;
  href?: any;
  size?: string;
}

const AppButton: FC<AppButtonProps> = (props: AppButtonProps) => (
  <IonButton
  {...(props?.id && { id: props?.id })}
    {...(props?.className && { className: props?.className })}
    {...(props?.size && { size: props?.size })}
    {...(props?.expand && { expand: props?.expand })}
    {...(props?.fill && { fill: props?.fill })}
    {...(props?.type && { type: props?.type })}
    {...(props?.disabled && { disabled: props?.disabled })}
    onClick={() => props?.onButtonClick()}
    {...(props?.href && { href: props?.href })}
    {...(props?.color && { color: props?.color })}
  >
    {props?.buttonText}
    {props?.isLoading && (
      <IonSpinner name="crescent" className="ion-margin-start" />
    )}
  </IonButton>
);

export default AppButton;
