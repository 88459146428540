import React, { useEffect, useContext } from "react";
import { useState } from "react";
import {
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonPage,
  IonRow,
  IonText,
  useIonToast,
  IonCheckbox,
  IonLabel,
  useIonAlert,
  IonSpinner,
} from "@ionic/react";

import Hamburger from "../../components/HamburgerMenu/Hamburger";
import "./WiFiSetup.css";
import { getAllChildFromClass } from "../../Services/Child";
import { GlobalContext } from "../../context/Provider";
import WiFiCredentials from "../../components/WiFiCredentials/WiFiCredentials";
import { readWiFiSetupStatus, writeWifiSSID } from "ble-library";
import { RouterChildContext, useHistory } from "react-router";
import { updateDevice } from "../../Services/Device";
import AppButton from "../../components/AppButton/AppButton";
import { AppLogger } from "../../Services/Logger";
import AppSpinner from "../../components/AppSpinner/AppSpinner";

type WiFiInfo = {
  name: string;
  password: string;
};

const WiFiSetup: React.FC = () => {
  const [present] = useIonToast();
  const [wifiInfo, setWiFiInfo] = useState<WiFiInfo>({
    name: localStorage.getItem("wifi_name") || "",
    password: localStorage.getItem("wifi_password") || "",
  });
  const [childDeviceList, setChildDeviceList] = useState<any>([]);
  const [selectedClassroom, setSelectedClassroom] = useState<number | null>(
    parseInt(localStorage?.classroom_id, 10)
  );
  const [selectedDevices, setSelectedDevices] = useState<any>([]);
  const [isAPILoading, setAPILoading] = useState<boolean>(false);
  const [isWiFiUpdating, setWiFiUpdating] = useState<boolean>(false);
  const [isAllSelected, setAllSelected] = useState<boolean>(false);

  const [create] = useIonAlert();
  const history: RouterChildContext["router"]["history"] = useHistory();
  const routeparam: any = history?.location?.state || [];
  const { bleConnectionState } = useContext(GlobalContext);

  console.log("Wifi setup:: connection state >>>>>", bleConnectionState);

  const readWiFiStatus = (device_reference: string, mac_address: string) => {
    readWiFiSetupStatus(device_reference, (response: number) => {
      console.log(`Read WiFi Status for::${device_reference}`, response);
      setWifiStatus(response, mac_address, childDeviceList);
    });
  };

  const checkConnectedDeviceWiFIStatus = () => {
    console.log("Check connected device wifi status");
    if (bleConnectionState && childDeviceList?.length) {
      childDeviceList?.map((device: any) => {
        if (
          bleConnectionState[device?.device_reference]?.isConnected &&
          device?.is_wifi_setup_done &&
          !device?.wifi_status
        ) {
          readWiFiStatus(device?.device_reference, device?.mac_address);
        }
      });
    }
  };

  useEffect(() => {
    console.log("Device connection state changed....", bleConnectionState);
    childDeviceList?.map((device: any) => {
      if (bleConnectionState[device?.device_reference]?.isConnected) {
        device.connectionStatus = "Connected";
      } else {
        device.connectionStatus = "Disconnected";
      }
    });
    setChildDeviceList((childDeviceList: any) => [...childDeviceList]);
    checkConnectedDeviceWiFIStatus();
  }, [bleConnectionState]);

  const checkWiFiStatus = (childDevice: any) => {
    console.log("Check wifi status >>>>>>>>", bleConnectionState, childDevice);
    if (bleConnectionState && childDevice?.length) {
      childDevice.map((device: any) => {
        if (
          bleConnectionState[device?.device_reference]?.isConnected &&
          device?.is_wifi_setup_done
        ) {
          readWiFiSetupStatus(
            bleConnectionState[device?.device_reference].device_reference,
            (response: number) => {
              console.log("Read WiFi Status::", response);
              setWifiStatus(
                response,
                bleConnectionState[device?.device_reference].mac_address,
                childDevice
              );
            }
          );
        }
      });
    }
  };

  const checkIsPreSelectedDevice = (device: any) => {
    if (
      routeparam?.length &&
      routeparam?.indexOf(device.device_reference) >= 0
    ) {
      const selected: any = selectedDevices;
      selected.push(device.device_reference);
      setSelectedDevices([...selected]);
      if (selectedDevices?.length === childDeviceList?.length) {
        setAllSelected(true);
      }
    }
  };

  const isDeviceSelected = (device: any) => {
    return routeparam?.indexOf(device.device_reference) >= 0 ? true : false;
  };

  const getAllChildren = (id: number) => {
    setAPILoading(true);
    getAllChildFromClass(id)
      .then((res: any) => {
        if (res?.data?.length) {
          let child: any = {};
          const childDevice: any = [];
          res?.data?.map((child_device: any) => {
            if (child_device?.device) {
              child = {};
              child.child_id = child_device.child.id;
              child.child = `${child_device.child.first_name} ${child_device.child.last_name}`;
              child.device_name = child_device.device.device_name;
              child.device_reference = child_device.device.device_reference;
              child.mac_address = child_device.device.device_reference;
              child.is_selected = isDeviceSelected(child_device.device);
              child.is_wifi_setup_done = child_device?.device?.wifi_access;
              if (!child_device?.device?.wifi_access) {
                child.wifi_status = "WiFi not setup";
              }
              child.connectionStatus = bleConnectionState[
                child_device?.device?.device_reference
              ]?.isConnected
                ? "Connected"
                : "Disconnected";

              childDevice.push(child);
              checkIsPreSelectedDevice(child_device.device);
            }
          });
          console.log(childDevice);

          setChildDeviceList(childDevice);
          checkWiFiStatus(childDevice);
        }
        setAPILoading(false);
      })
      .catch((err: any) => {
        console.log("all children error", err);
        setAPILoading(false);
      });
  };

  const setWifiStatus = (
    status: number,
    mac_address: string,
    deviceList: any
  ) => {
    deviceList?.map((device: any) => {
      if (device.device_reference === mac_address) {
        device.wifi_status = getWiFiStatusByKey(
          status > 2 ? "connected" : "disconnected"
        );
      }
    });
    setChildDeviceList([...deviceList]);
  };

  useEffect(() => {
    if (selectedClassroom) {
      getAllChildren(selectedClassroom);
    }
  }, [selectedClassroom]);

  useEffect(() => {
    if (childDeviceList?.length && isWiFiUpdating) {
      let wifiSetupDoneCount = 0;
      childDeviceList?.map((device: any) => {
        if (device?.wifi_status?.toLowerCase() !== "connecting") {
          wifiSetupDoneCount += 1;
        }
      });
      if (
        wifiSetupDoneCount > 0 &&
        wifiSetupDoneCount === childDeviceList?.length
      ) {
        deSelectAllDevice();
        setAllSelected(false);
        setWiFiUpdating(false);
      }
    }
  }, [childDeviceList]);

  const updateWiFiStatus = (deviceRefId: number) => {
    const device = {
      id: deviceRefId,
      wifi_access: true,
    };

    updateDevice(deviceRefId, device)
      .then((res) => {
        if (res?.status === 200) {
          console.log("Wifi status update success");
        } else {
          console.log("Wifi status update failed");
        }
      })
      .catch((err: any) => {
        console.log("WiFi status update error:", err);
      });
  };

  const updateStatus = (wifi_device: any, wifi_response: number) => {
    console.log("Calling update device");

    if (childDeviceList?.length) {
      childDeviceList?.map((device: any) => {
        if (device?.mac_address === wifi_device?.mac_address) {
          device.wifi_status = getWiFiStatusByKey(
            wifi_response >= 3 ? "connected" : "failed"
          );
        }
      });
      setChildDeviceList([...childDeviceList]);
    }
  };

  const updateWiFiNameAndPassword = () => {
    console.log("update");
    setWiFiUpdating(true);

    childDeviceList?.map((device: any) => {
      if (
        selectedDevices?.indexOf(device.device_reference) >= 0 &&
        bleConnectionState[device.device_reference]?.isConnected
      ) {
        AppLogger(
          {
            device_id: bleConnectionState[device.device_reference]?.device_id,
            mac_address:
              bleConnectionState[device.device_reference]?.mac_address,
          },
          "Wi-Fi Setup",
          "info",
          `Start:: Updating Wi-Fi SSID and Password for device::${
            bleConnectionState[device.device_reference]?.mac_address
          }`
        );
        AppLogger(
          {
            device_id: bleConnectionState[device.device_reference]?.device_id,
            mac_address:
              bleConnectionState[device.device_reference]?.mac_address,
          },
          "Wi-Fi Setup",
          "info",
          `Wi-Fi SSID for device ${
            bleConnectionState[device.device_reference]?.mac_address
          } is:: ${wifiInfo.name}`
        );

        writeWifiSSID(
          wifiInfo.name,
          wifiInfo.password,
          bleConnectionState[device.device_reference].device_reference,
          (response: any) => {
            console.log(
              `WiFi Connected response for ${device?.mac_address} is::`,
              response
            );
            AppLogger(
              {
                device_id:
                  bleConnectionState[device.device_reference]?.device_id,
                mac_address:
                  bleConnectionState[device.device_reference]?.mac_address,
              },
              "Wi-Fi Setup",
              "info",
              `Wi-Fi SSID and Password write response for ${
                bleConnectionState[device.device_reference]?.mac_address
              } ::${JSON.stringify(response)}`
            );
            if (response !== null && response !== 1) {
              console.log(
                "Updated device Id",
                bleConnectionState[device.device_reference].device_id
              );
              updateStatus(device, response);
              updateWiFiStatus(
                bleConnectionState[device.device_reference].device_id
              );
            }
          }
        );
        device.wifi_status = "connecting";
      }
    });
  };

  console.log("Device List >>>>>", childDeviceList);

  const confirmSubmit = () => {
    create({
      message: `You have given Wi-Fi Name ${wifiInfo.name} and password ${wifiInfo.password}. Do you want to continue?`,
      buttons: [
        {
          text: "Close",
          role: "close",
        },
        {
          text: "Continue",
          handler: () => {
            updateWiFiNameAndPassword();
          },
        },
      ],
    });
  };

  const getConnectedDevices = () => {
    return childDeviceList?.filter((childDevice: any) => {
      return childDevice?.connectionStatus?.toLowerCase() === 'connected';
    });
  }

  const updateSelectedStatus = (selectedDeviceMaxAddressList: any) => {
    if (
      childDeviceList?.length > 0 &&
      selectedDeviceMaxAddressList?.length === getConnectedDevices()?.length
    ) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  };

  const onChildSelectChange = (e: any, device: any) => {
    let selected: any = [...selectedDevices];
    if (e?.detail?.checked) {
      selected.push(e?.detail?.value);
    } else {
      selected = selectedDevices.filter(
        (device: any) => device !== e?.detail?.value
      );
    }
    setSelectedDevices([...selected]);
    updateSelectedStatus(selected);
  };

  const selectAllDevice = () => {
    const selected: any = [];
    if (childDeviceList?.length) {
      childDeviceList?.map((device: any) => {
        if (device?.connectionStatus?.toLowerCase() === "connected") {
          device.is_selected = true;
          selected.push(device.device_reference);
          setSelectedDevices([...selected]);
        }
      });
      setChildDeviceList(childDeviceList);
    }
  };

  const deSelectAllDevice = () => {
    const selected: any = [];
    if (childDeviceList?.length) {
      childDeviceList?.map((device: any) => {
        device.is_selected = false;
      });
      setSelectedDevices([...selected]);
      setChildDeviceList(childDeviceList);
    }
  };

  const getWiFiStatusByKey = (key: string) => {
    switch (key) {
      case "connecting":
        return "connecting";
        break;
      case "connected":
        return "Connection successful";
        break;

      case "failed":
        return "last connection failed";
        break;

      default:
        return "disconnected";
    }
  };

  const onWiFiInfoChange = () => {
    setWiFiInfo({
      name: localStorage.getItem("wifi_name") || "",
      password: localStorage.getItem("wifi_password") || "",
    });
  };

  return (
    <IonPage>
      <Hamburger></Hamburger>
      <IonContent fullscreen>
        <IonGrid className="contaSiner-lg ion-padding-top">
          <>
            <WiFiCredentials
              onWiFiInfoChange={() => onWiFiInfoChange()}
            ></WiFiCredentials>
          </>

          {isAPILoading && <AppSpinner />}

          {!childDeviceList?.length && !isAPILoading && (
            <IonRow className="ion-justify-content-center ion-margin-top ion-padding-top">
              <IonText className="ion-margin-top ion-padding-top">
                No device found!
              </IonText>
            </IonRow>
          )}
          {childDeviceList?.length > 0 && !isAPILoading && (
            <IonGrid className="ion-text-center ion-padding-top wifi-container">
              <IonItem
                button
                lines="none"
                detail={false}
                className="no-ripple header-item"
              >
                <IonCheckbox
                  slot="start"
                  aria-label="select_all"
                  value={isAllSelected}
                  checked={isAllSelected}
                  onIonChange={(e) => {
                    if (e?.detail?.checked) {
                      setAllSelected(true);
                      selectAllDevice();
                    } else {
                      setAllSelected(false);
                      deSelectAllDevice();
                    }
                  }}
                ></IonCheckbox>
                <IonCol>
                  <IonLabel className="text-bold font-18">Device Name</IonLabel>
                </IonCol>
                <IonCol className="text-bold font-18">Associated With</IonCol>
                <IonCol className="text-bold font-18">Status</IonCol>
                <IonCol className="text-bold font-18">Wi-Fi</IonCol>
              </IonItem>
              {childDeviceList?.map((device: any, index: number) => {
                return (
                  <IonItem key={index} lines="none" detail={false}>
                    <IonCheckbox
                      slot="start"
                      aria-label={`select child ${device.device_reference}`}
                      value={device.device_reference}
                      checked={device?.is_selected}
                      disabled={
                        device?.connectionStatus?.toLowerCase() !== "connected"
                      }
                      onIonChange={(e) => {
                        if (e?.detail?.checked) {
                          device.is_selected = true;
                        } else {
                          device.is_selected = false;
                        }
                        setChildDeviceList(childDeviceList);
                        onChildSelectChange(e, device);
                      }}
                    ></IonCheckbox>
                    <IonCol>{device.device_name}</IonCol>
                    <IonCol>{device.child}</IonCol>
                    <IonCol>{device?.connectionStatus}</IonCol>
                    <IonCol>
                      <IonText className="ion-text-capitalize">
                        {device.wifi_status || "N/A"}
                      </IonText>
                    </IonCol>
                  </IonItem>
                );
              })}

              <IonRow className="ion-justify-content-center ion-margin-top ion-padding-top">
                <AppButton
                  className="width-35"
                  buttonText="SEND & CONNECT"
                  isLoading={isWiFiUpdating}
                  disabled={
                    isWiFiUpdating ||
                    !selectedDevices?.length ||
                    !wifiInfo?.name?.length ||
                    !wifiInfo?.password?.length
                  }
                  onButtonClick={() => confirmSubmit()}
                />
              </IonRow>
            </IonGrid>
          )}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default WiFiSetup;
