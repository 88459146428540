import React, { FC } from "react";
import { IonBackButton, IonButtons } from "@ionic/react";
import { chevronBack } from "ionicons/icons";

const BackButton: FC<any> = () => {
  return (
    <IonButtons slot="start">
      <IonBackButton
        text="BACK"
        icon={chevronBack}
        className="pt-sm primary-dark back-btn"
        defaultHref="/"
      ></IonBackButton>
    </IonButtons>
  );
};

export default BackButton;
