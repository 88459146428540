import axiosInstance from "../helper/axios";

export type SchoolUpdate = {
  name: string;
  description: string;
  no_of_classroom: number | null;
  no_of_teacher: number | null;
  school_address: string;
  country_district: string;
  student_capacity: number | null;
};

export const createNewSchool = async (school: any) => {
  return await axiosInstance().post(`/educator/school-admin`, school);
};

export const updateSchoolInfoById = async (
  school_id: number,
  school: SchoolUpdate
) => {
  return await axiosInstance().put(`/educator/school/${school_id}`, school);
};

export const getSchoolInfoById = async (school: any) => {
  return await axiosInstance().get(`/educator/school/${school}`);
};

export const removeFromSchool = async (user_id: number, school_id: number) => {
  return await axiosInstance().delete(
    `/educator/school/remove/${user_id}/${school_id}`
  );
};

export const createAcademicYear = async (data: any) => {
  return await axiosInstance().post("/educator/academic-year", data);
};

export const updateAcademicYear = async (year_id: number, data: any) => {
  return await axiosInstance().put(`/educator/academic-year/${year_id}`, data);
};
export const getAllAcademicYears = async (school_id: number) => {
  return await axiosInstance().get(`/educator/academic-year/${school_id}`);
};
