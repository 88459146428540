import axiosInstance from "../helper/axios";

export const archiveClassroom = async (data: any) => {
  return await axiosInstance().post(`/educator/classroom/archive`, data);
};

export const getAssignedTeacherClassrooms = async (school_id: any) => {
  if (parseInt(school_id, 10) > 0) {
    return await axiosInstance().get(
      `/educator/classroom/assigned/list?school_id=${school_id}`
    );
  }
};
