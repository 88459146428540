import React from "react";

import { IonButton, IonCol, IonGrid, IonRow, IonText } from "@ionic/react";

const Info = (props: any) => {
  console.log("Props >>>>>>>>", props);

  return (
    <IonGrid className="ion-margin">
      <IonRow className="ion-justify-content-center ion-padding-bottom">
        <IonText className="primary-heading font-20 ion-padding">
          {props?.title}
        </IonText>
      </IonRow>
      <IonRow className="ion-padding-horizontal ion-margin-top">
        {props?.info?.length > 0 &&
          props?.info
            ?.sort((a: any, b: any) => a?.order - b?.order)
            ?.map((info: any, index: number) => (
              <IonCol
                size={props?.column_size || 12}
                key={index}
                className="ion-margin-top"
              >
                <IonText>
                  <b>{info.label}:</b> {info.value}
                </IonText>
              </IonCol>
            ))}
      </IonRow>

      <IonRow className="ion-justify-content-end ion-padding-top">
        <IonButton
          fill="outline"
          size="small"
          onClick={() => {
            props.onclose();
          }}
        >
          Close
        </IonButton>
      </IonRow>
    </IonGrid>
  );
};

export default Info;
