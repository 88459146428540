import React, { FC } from "react";
import { IonInput, IonItem } from "@ionic/react";

interface AppInputProps {
  type?: string;
  placeholder?: string;
  label?: string;
  value: string | number | undefined | null;
  onInputChange: any;
  className?: string;
  itemDisabled?: boolean;
  itemClassName?: string;
}

const AppInput: FC<AppInputProps> = (props: any) => (
  <IonItem
    detail={false}
    className="m-t-1"
    {...(props?.itemDisabled && { disabled: props?.itemDisabled })}
    {...(props?.itemClassName && { className: props?.itemClassName })}
  >
    <IonInput
      {...(props?.className && { className: props?.className })}
      type={props?.type || "text"}
      label={props?.label}
      labelPlacement={props?.labelPlacement || "floating"}
      placeholder={props?.placeholder || ""}
      value={props?.value}
      onIonInput={(event) => {
        props?.onInputChange(event?.detail?.value);
      }}
    ></IonInput>
  </IonItem>
);

export default AppInput;
