const common = {
  duration: 3000,
  position: "top",
};

const toastConfig: any = {
  success: {
    ...common,
    color: "primary",
  },
  error: {
    ...common,
    color: "danger",
  },
  warning: {
    ...common,
    color: "warning",
  },
  notClosableError: {
    position: common.position,
    color: "danger",
  },
};

export default toastConfig;
