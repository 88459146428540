import { Dispatch } from "react";
import axiosInstance from "../helper/axios";
import config from "../../src/conf.json";
type LoginProps = {
  email: string;
  password: React.ReactNode;
};

type VerifyEmail = {
  token: string;
  uidb64: string;
  set_password: boolean;
};

export const Login = async (email: string, password: string) => {
  return axiosInstance()
    .post("/auth/login/", {
      email,
      password,
    })
    .then((res: any) => {
      localStorage.token = res.data.access;
      localStorage.refresh = res.data.refresh;
      return res;
    })
    .catch((err: any) => {
      console.log("err", err);
    });
};

export const getUserByToken = async () => {
  try {
    return await axiosInstance().get("/educator/auth");
  } catch (err) {
    console.log("err", err);
  }
};

export const verifyEmail = (tokenInfo: VerifyEmail) => {
  return axiosInstance().post("/auth/email-verify/", tokenInfo);
};

export const requestVerifyEmail = async (email: string) => {
  return axiosInstance().post("/auth/request-verify-email/", {
    email,
  });
};

export const verifyToken = async (uidb64: string, token: string) => {
  return await axiosInstance().get(
    `/auth/password-token-verify/?uidb64=${uidb64}&token=${token}`
  );
};

export const resetPassword = async (
  password: string,
  token: string,
  uidb64: string
) => {
  return axiosInstance().post("/auth/password-reset-complete/", {
    password,
    token,
    uidb64,
  });
};

export const requestResetPassword = ({ email }: any) => {
  return axiosInstance().post("/auth/request-password-reset/", {
    email: email?.toLowerCase(),
    redirect_url: `${config.FRONTEND_URL}/auth/resetPassword/`,
  });
};
