import React from "react";

import { createContext, useReducer } from "react";
import authInitialStates from "./initialstates/authInitialStates";
import bleInitialStates from "./initialstates/bleInitialStates";
import permissionInitialStates from "./initialstates/permissionsInitialStates";
import rolesInitialStates from "./initialstates/rolesInitialStates";
import auth from "./reducers/auth";
import ble from "./reducers/ble";
import permissions from "./reducers/permissions";
import roles from "./reducers/roles";

const data: any = {};
export const GlobalContext = createContext(data);

export const GlobalProvider = ({ children }: any) => {
  const [authState, authDispatch] = useReducer(auth, authInitialStates);

  const [bleConnectionState, bleConnectionDispatch] = useReducer(
    ble,
    bleInitialStates
  );
  const [permissionState, permissionDispatch] = useReducer(
    permissions,
    permissionInitialStates
  );
  const [roleState, roleDispatch] = useReducer(roles, rolesInitialStates);

  return (
    <GlobalContext.Provider
      value={{
        authState,
        authDispatch,
        bleConnectionState,
        bleConnectionDispatch,
        permissionState,
        permissionDispatch,
        roleState,
        roleDispatch,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
