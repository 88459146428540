import React, { useContext, useEffect } from "react";
import {
  IonAccordionGroup,
  IonContent,
  IonIcon,
  IonItem,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonText,
} from "@ionic/react";

import { useLocation, useHistory } from "react-router-dom";

import "./Menu.css";
import menuList from "../../routes/Routes";
import { useState } from "react";
import MenuItem from "./MenuItem";
import { isAccountSetupComplete } from "../../common/Util";
import { GlobalContext } from "../../context/Provider";
import { disconnect } from "ble-library";
import { AppLogger } from "../../Services/Logger";

interface SubMenu {
  title: string;
  path: string;
}

interface Routes {
  id: string;
  title: string;
  path?: string;
  icon: string;
  subMenu?: SubMenu[];
}

const Menu: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const [showMainMenu, setShowMainMenu] = useState<boolean>(
    isAccountSetupComplete()
  );
  const [activeMenu, setActiveMenu] = useState();

  const { bleConnectionDispatch, bleConnectionState, roleState } =
    useContext(GlobalContext);

  const redirectTo = (path: string) => {
    history.push(path);
  };

  const checkSubMenu = (subMenuList: any, currentPath: any, cb: any) => {
    const menu = subMenuList.filter((menu: any) => {
      return menu?.path === currentPath;
    });
    cb(menu);
  };

  const getActiveMenu = (menuList: any, currentPath: any) => {
    let activeMenu;
    menuList.forEach((menu: any) => {
      if (menu.subMenu?.length) {
        checkSubMenu(menu.subMenu, currentPath, (res: any) => {
          if (res?.length && res[0].path === currentPath) {
            activeMenu = menu.id;
          }
        });
      } else {
        if (menu?.path === currentPath) {
          activeMenu = menu.id;
        }
      }
    });
    return activeMenu;
  };

  const onMenuClick = () => {
    setActiveMenu((activeMenu) => getActiveMenu(menuList, location.pathname));
  };

  useEffect(() => {
    window.addEventListener("storage", () => {
      setShowMainMenu(isAccountSetupComplete());
    });
  }, []);

  const disconnectConnectedDevices = () => {
    console.log("Disconnecting connected devices........");
    Object.keys(bleConnectionState).map((device: any) => {
      if (
        bleConnectionState[device].isConnected ||
        bleConnectionState[device].status === "connected"
      ) {
        console.log("Disconnecting starts");

        disconnect(
          bleConnectionState[device].device_reference,
          AppLogger,
          function (res: any) {
            console.log(`Disconnected >>>>>>>>>>>>>>> ${res}`);
          }
        );
      }
    });
  };

  const onLogout = () => {
    disconnectConnectedDevices();
    localStorage.clear();
    window.location.replace("/");
  };

  return (
    <IonMenu
      menuId="app-menu"
      side="start"
      type="overlay"
      contentId="main"
      swipeGesture={false}
      className="ion-hide-lg-up"
      onIonWillOpen={() => {
        onMenuClick();
      }}
    >
      <IonContent>
        <IonList lines="none" className="mt-lg ion-margin-top ion-padding-top">
          <div className="top-border" key="border"></div>
          {showMainMenu && menuList?.length > 0 && (
            <IonAccordionGroup value={activeMenu}>
              {menuList?.map((menu: Routes, index: number) => {
                return (
                  <MenuItem
                    menu={menu}
                    key={index}
                    activeMenu={activeMenu}
                  ></MenuItem>
                );
              })}
            </IonAccordionGroup>
          )}
          <IonMenuToggle>
            <IonItem
              key="/"
              detail={false}
              className="cursor-pointer"
              onClick={() => {
                onLogout();
              }}
            >
              <IonIcon
                color="primary"
                src="assets/logout.svg"
                className="menu-icon"
              ></IonIcon>
              <IonText className="primary-heading">Logout</IonText>
            </IonItem>
          </IonMenuToggle>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
