import React, { FC, useEffect } from "react";
import {
  IonRow,
  IonGrid,
  IonPage,
  IonContent,
  IonText,
  IonCol,
} from "@ionic/react";
import { App } from "@capacitor/app";

import Hamburger from "../../components/HamburgerMenu/Hamburger";
import GitInfo from "react-git-info/macro";
import config from "../../conf.json";
import { Capacitor } from "@capacitor/core";

const AppInfo: FC = () => {
  const gitInfo = GitInfo();
  const isNativePlatform = Capacitor.isNativePlatform();

  const getGitHash = () => {
    return gitInfo?.commit?.hash;
  };
  async function getAppInfo() {
    const info = await App.getInfo();
    console.log("App Info::", info);

    if (info?.version) {
      localStorage.app_version = info?.version;
    }
    if (info?.build) {
      localStorage.build_version = info?.build;
    }
  }

  useEffect(() => {
    if (isNativePlatform) {
      getAppInfo();
    }
  }, []);

  return (
    <IonPage>
      <Hamburger></Hamburger>
      <IonContent fullscreen>
        <IonRow className="ion-margin ion-padding ion-justify-content-center">
          <IonText className="primary-heading">App Info</IonText>
        </IonRow>
        <IonGrid className="ion-padding">
          {isNativePlatform && (
            <>
              <IonRow>
                <IonCol size="6">
                  <IonText className="primary-heading">App Version:</IonText>
                </IonCol>
                <IonCol size="6">
                  <IonText className="primary-dark">
                    {localStorage?.app_version || ""}
                  </IonText>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="6">
                  <IonText className="primary-heading">Build Version:</IonText>
                </IonCol>
                <IonCol size="6">
                  <IonText className="primary-dark">
                    {localStorage?.build_version || ""}
                  </IonText>
                </IonCol>
              </IonRow>
            </>
          )}
          <IonRow>
            <IonCol size="6">
              <IonText className="primary-heading">Environment:</IonText>
            </IonCol>
            <IonCol size="6">
              <IonText className="primary-dark">
                {config?.environment || ""}
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="6">
              <IonText className="primary-heading">App Backend URL:</IonText>
            </IonCol>
            <IonCol size="6">
              <IonText className="primary-dark">
                {config?.APP_BACKEND_URL || ""}
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="6">
              <IonText className="primary-heading">Git Hash:</IonText>
            </IonCol>
            <IonCol size="6">
              <IonText className="primary-dark">{getGitHash() || ""}</IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default AppInfo;
