const ble = (state: any, action: any) => {
  if (action.type === "REMOVE") {
    const { [action.payload]: _, ...newState } = state;
    return newState;
  } else {
    return {
      ...state,
      ...action,
    };
  }
};

export default ble;
