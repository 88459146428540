import React, { useEffect, useContext } from "react";
import { useState } from "react";
import {
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonPage,
  IonRow,
  useIonToast,
  IonCheckbox,
  IonButton,
  IonLabel,
  useIonAlert,
  IonPopover,
  IonIcon,
  IonList,
  IonModal,
  IonText,
} from "@ionic/react";

import Hamburger from "../../components/HamburgerMenu/Hamburger";
import "./UpdateDevice.css";
import { format } from "date-fns";
import { getAllChildFromClass } from "../../Services/Child";
import { GlobalContext } from "../../context/Provider";
import { ellipsisVerticalOutline } from "ionicons/icons";
import {
  buzzDevice,
  readDeviceVersion,
  readHardWareVersion,
  writeBuzzVolume,
  writeDeviceLock,
  writeDeviceName,
} from "ble-library";
import toastConfig from "../../common/toast-config";
import { useHistory } from "react-router";
import RenameDevice from "../../components/RenameDevice/RenameDevice";
import { updateDevice } from "../../Services/Device";
import AppButton from "../../components/AppButton/AppButton";
import { AppLogger } from "../../Services/Logger";
import { setConnectionStatus } from "../../common/BleConnectionStatus";
import AppSpinner from "../../components/AppSpinner/AppSpinner";
import Info from "../../components/Info/Info";
(window as any).format = format;

type WiFiInfo = {
  name: string;
  password: string;
};

const interval: any = {};
const UpdateDevice: React.FC = () => {
  const [childDeviceList, setChildDeviceList] = useState<any>([]);
  const [deviceInfo, setDeviceInfo] = useState<any>({});
  const [selectedClassroom] = useState<number | null>(
    parseInt(localStorage?.classroom_id, 10)
  );
  const [isApiLoading, setIsApiLoading] = useState<boolean>(true);
  const [selectedDevices, setSelectedDevices] = useState<number[]>([]);
  const [selectedDevice, setSelectedDevice] = useState<any>({});
  const [isAllSelected, setAllSelected] = useState<boolean>(false);
  const [create] = useIonAlert();
  const [present] = useIonToast();
  const history = useHistory();
  const { bleConnectionState, bleConnectionDispatch } =
    useContext(GlobalContext);
  const [showRenameModal, setShowRenameModal] = useState<boolean>(false);
  const [showDeviceInfoModal, setShowDeviceInfoModal] =
    useState<boolean>(false);

  console.log("Update Device:: Connection State::", bleConnectionState);

  useEffect(() => {
    console.log(
      "Update device:: Device connection state changed....",
      bleConnectionState
    );
    childDeviceList?.map((device: any) => {
      if (bleConnectionState[device?.device_reference]?.isConnected) {
        device.connectionStatus = "Connected";
        device.battery =
          (bleConnectionState[device?.device_reference].batteryPercent || 0) +
          "%";
        device.is_locked =
          bleConnectionState[device?.device_reference]?.is_locked;
      } else {
        device.connectionStatus = "Disconnected";
        device.battery = "N/A";
        device.is_locked = "N/A";
      }
    });
    setChildDeviceList((childDeviceList: any) => [...childDeviceList]);
  }, [bleConnectionState]);

  const updateDeviceLock = (
    deviceList: any,
    lock: number | string,
    macAddress: string
  ) => {
    deviceList?.map((device: any) => {
      if (device.device_reference === macAddress) {
        device.is_locked = lock === 1 ? true : false;
      }
    });
    setChildDeviceList([...deviceList]);
  };

  const readDeviceData = (deviceList: any) => {
    // readDeviceCharacteristics(deviceList);
  };

  const getAllChildren = (id: number) => {
    setIsApiLoading(true);
    getAllChildFromClass(id)
      .then((res: any) => {
        if (res?.data?.length) {
          let child: any = {};
          const childDevice: any = [];
          res?.data?.map((child_device: any) => {
            if (child_device?.device) {
              child = {};
              child.child_id = child_device.child.id;
              child.child = `${child_device.child.first_name} ${child_device.child.last_name}`;
              child.device_id = child_device.device.id;
              child.device_name = child_device.device.device_name;
              child.device_reference = child_device.device.device_reference;
              child.firmware_updated_on =
                child_device.device.firmware_updated_on;
              if (
                bleConnectionState[child_device.device.device_reference]
                  ?.isConnected &&
                bleConnectionState[child_device.device.device_reference]
                  .batteryPercent >= 0
              ) {
                child.battery =
                  bleConnectionState[child_device.device.device_reference]
                    .batteryPercent + "%";
                child.is_locked =
                  bleConnectionState[
                    child_device?.device?.device_reference
                  ]?.is_locked;
              } else {
                child.battery = "N/A";
                child.is_locked = "N/A";
              }
              child.connectionStatus = bleConnectionState[
                child_device?.device?.device_reference
              ]?.isConnected
                ? "Connected"
                : "Disconnected";

              childDevice.push(child);
            }
          });
          console.log(childDevice);
          setChildDeviceList(childDevice);
          // readDeviceData(childDevice);
        }
        setIsApiLoading(false);
      })
      .catch((err: any) => {
        console.log("all children error", err);
        setIsApiLoading(false);
      });
  };

  useEffect(() => {
    if (selectedClassroom) {
      getAllChildren(selectedClassroom);
    }
  }, [selectedClassroom]);

  const getConnectedDevices = () => {
    return childDeviceList?.filter((childDevice: any) => {
      return childDevice?.connectionStatus?.toLowerCase() === "connected";
    });
  };

  const updateSelectedStatus = (selectedDeviceMaxAddressList: any) => {
    if (
      childDeviceList?.length > 0 &&
      selectedDeviceMaxAddressList?.length === getConnectedDevices()?.length
    ) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  };

  const onChildSelectChange = (e: any, device: any) => {
    let selected: any = [...selectedDevices];
    if (e?.detail?.checked) {
      selected.push(e?.detail?.value);
    } else {
      selected = selectedDevices.filter(
        (device: any) => device !== e?.detail?.value
      );
    }
    setSelectedDevices(selected);
    updateSelectedStatus(selected);
  };

  const selectAllDevice = () => {
    const selected: any = [];
    if (childDeviceList?.length) {
      childDeviceList?.map((device: any) => {
        if (device?.connectionStatus?.toLowerCase() === "connected") {
          device.is_selected = true;
          selected.push(device.device_reference);
          setSelectedDevices([...selected]);
        }
      });
      setChildDeviceList(childDeviceList);
    }
  };

  const deSelectAllDevice = () => {
    const selected: any = [];
    if (childDeviceList?.length) {
      childDeviceList?.map((device: any) => {
        device.is_selected = false;
      });
      setSelectedDevices([...selected]);
      setChildDeviceList([...childDeviceList]);
    }
  };

  const enableBuzzDevice = (deviceId: string) => {
    if (bleConnectionState[deviceId] && childDeviceList?.length) {
      AppLogger(
        {
          device_id: bleConnectionState[deviceId]?.device_id,
          mac_address: bleConnectionState[deviceId]?.mac_address,
        },
        "Update_device",
        "info",
        `Start:: Enable Buzz device (1)`
      );
      writeBuzzVolume(bleConnectionState[deviceId].device_reference, 150);
      buzzDevice(bleConnectionState[deviceId].device_reference, 1);
      setTimeout(() => {
        writeBuzzVolume(bleConnectionState[deviceId].device_reference, 100);
      }, 20000);
    }
  };

  const redirectToUpodateWiFi = (devices: any) => {
    history.push("/device/wifi-setup", devices || []);
  };

  const checkLockedCount = (count: number) => {
    if (count === selectedDevices?.length) {
      deSelectAllDevice();
      setAllSelected(false);
      present({
        ...toastConfig.success,
        message: "Devices locked successfully!",
      });
    }
  };

  const updateDeviceStateInfo = (mac_address: string, response: number) => {
    console.log("Update device info state", mac_address, response);
    bleConnectionState[mac_address].is_locked = response === 1;
    setConnectionStatus(bleConnectionState, bleConnectionDispatch);
  };

  const updateFunctionLockCharacteristics = (
    device: any,
    code: number,
    callback: any
  ) => {
    console.log("Update function lock characteristics::", device);
    writeDeviceLock(device?.device_reference, code, (response: any) => {
      AppLogger(
        {
          device_id: device?.device_id,
          mac_address: device?.mac_address,
        },
        "Update_device",
        "info",
        `End::Update lock status response:: ${response}`
      );
      console.log("Lock selected response:: >>>", response);
      callback(response);
    });
  };

  const performDeviceLock = () => {
    let lockedDeviceCount = 0;
    childDeviceList?.map((device: any) => {
      if (
        selectedDevices?.indexOf(device?.device_reference) >= 0 &&
        bleConnectionState[device?.device_reference] &&
        bleConnectionState[device?.device_reference].isConnected
      ) {
        AppLogger(
          {
            device_id: bleConnectionState[device?.device_reference]?.device_id,
            mac_address:
              bleConnectionState[device?.device_reference]?.mac_address,
          },
          "Update_device",
          "info",
          `Start::Update lock status:: 1`
        );
        updateFunctionLockCharacteristics(
          bleConnectionState[device?.device_reference],
          1,
          (response: number) => {
            if (response === 1) {
              device.is_locked = true;
              lockedDeviceCount += 1;
              setChildDeviceList(() => [...childDeviceList]);
              updateDeviceStateInfo(
                bleConnectionState[device?.device_reference]?.mac_address,
                response
              );
              checkLockedCount(lockedDeviceCount);
            }

            if (!response) {
              present({
                ...toastConfig.console.error(),
                message: `Failed to lock ${
                  bleConnectionState[device?.device_reference].device_name
                }. Pleases try again!`,
              });
            }
          }
        );
      }
    });
  };

  const confirmDeviceLock = () => {
    create({
      message: `Are you sure, you want to lock the selected devices?`,
      buttons: [
        {
          text: "Cancel",
          role: "close",
        },
        {
          text: "Continue",
          handler: () => {
            performDeviceLock();
          },
        },
      ],
    });
  };

  const lockSelectedDevices = () => {
    confirmDeviceLock();
  };

  const lockDevice = (deviceId: string, mac_address: string) => {
    AppLogger(
      {
        device_id: bleConnectionState[mac_address]?.device_id,
        mac_address: bleConnectionState[mac_address]?.mac_address,
      },
      "Update_device",
      "info",
      `Start::Lock device:: ${mac_address}`
    );
    updateFunctionLockCharacteristics(
      bleConnectionState[mac_address],
      1,
      (response: any) => {
        childDeviceList?.map((device: any) => {
          if (
            response === 1 &&
            bleConnectionState[device.device_reference].mac_address ===
              mac_address
          ) {
            device.is_locked = true;
            updateDeviceStateInfo(mac_address, response);
          }
        });
        setChildDeviceList(() => [...childDeviceList]);
      }
    );
  };

  const unlockDevice = (deviceId: string, mac_address: string) => {
    AppLogger(
      {
        device_id: bleConnectionState[mac_address]?.device_id,
        mac_address: bleConnectionState[mac_address]?.mac_address,
      },
      "Update_device",
      "info",
      `Start::Unlock device:: ${mac_address}`
    );

    updateFunctionLockCharacteristics(
      bleConnectionState[mac_address],
      0,
      (response: number) => {
        childDeviceList?.map((device: any) => {
          if (
            response === 0 &&
            bleConnectionState[device.device_reference].mac_address ===
              mac_address
          ) {
            device.is_locked = false;
            updateDeviceStateInfo(mac_address, response);
          }
          setChildDeviceList(() => [...childDeviceList]);
        });
      }
    );
  };

  const renameDevice = (device: any) => {
    setShowRenameModal(true);
    setSelectedDevice(device);
  };

  const updateName = (name: string) => {
    const device = {
      id: selectedDevice.device_id,
      device_name: name,
    };

    updateDevice(selectedDevice.device_id, device)
      .then((res) => {
        if (res?.status === 200) {
          present({
            ...toastConfig.success,
            message: "Device name updated successfully!",
          });
          childDeviceList?.map((device: any) => {
            if (
              bleConnectionState[selectedDevice.device_reference]
                .mac_address === device.device_reference
            ) {
              device.device_name = name;
              setChildDeviceList(() => [...childDeviceList]);
            }
          });
        }
      })
      .catch((err: any) => {
        console.log("Rename device error:", err);
      });
  };

  const onDeviceNameChange = (name: string) => {
    console.log("device new name::", name);
    AppLogger(
      {
        device_id:
          bleConnectionState[selectedDevice.device_reference]?.device_id,
        mac_address:
          bleConnectionState[selectedDevice.device_reference]?.mac_address,
      },
      "Update_device",
      "info",
      `Start::Write device name:: New name ${name}`
    );
    setShowRenameModal(false);
    writeDeviceName(
      bleConnectionState[selectedDevice.device_reference].device_reference,
      name,
      (response: any) => {
        console.log("Write device name reponse", response);
        AppLogger(
          {
            device_id:
              bleConnectionState[selectedDevice.device_reference]?.device_id,
            mac_address:
              bleConnectionState[selectedDevice.device_reference]?.mac_address,
          },
          "Update_device",
          "info",
          `End::Write device name response:: ${response}`
        );
        updateName(name);
      }
    );
  };

  const onclose = () => {
    setShowRenameModal(false);
  };

  const getDeviceHardwareVersion = (
    deviceId: string,
    device_reference: string
  ) => {
    console.log("Read hardware version::", device_reference);
    AppLogger(
      {
        device_id: deviceId,
        mac_address: device_reference,
      },
      "Update_device",
      "info",
      `Start::Read hardware version`
    );
    readHardWareVersion(
      device_reference,
      {
        mac_address: device_reference,
        device_reference_id: deviceId,
      },
      (response: any) => {
        console.log("Read hardware::", response);
        AppLogger(
          {
            device_id: deviceId,
            mac_address: device_reference,
          },
          "Update_device",
          "info",
          `Read hardware version response::${JSON.stringify(response)}`
        );
        if (response.hardware) {
          setDeviceInfo((deviceInfo: any) => [
            ...deviceInfo,
            {
              label: "Current hardware version",
              value: response.hardware,
              order: 4,
            },
          ]);
        }
      }
    );
  };

  const readDeviceFirmwareVersion = (
    device_id: string,
    device_reference: any
  ) => {
    AppLogger(
      {
        device_id: device_id,
        mac_address: device_reference,
      },
      "Manage",
      "Update_device",
      `Start:: Read firmware version`
    );
    readDeviceVersion(
      device_reference,
      {
        mac_address: device_reference,
        device_reference_id: device_id,
      },
      (response: any) => {
        console.log(`Read firmware for :: ${device_reference}`, response);
        AppLogger(
          {
            device_id: device_id,
            mac_address: device_reference,
          },
          "Update_device",
          "info",
          `End:: Read firmware version:: ${JSON.stringify(response)}`
        );
        if (response.firmware) {
          setDeviceInfo((deviceInfo: any) => [
            ...deviceInfo,
            {
              label: "Current firmware version",
              value: response.firmware,
              order: 3,
            },
          ]);
          getDeviceHardwareVersion(device_id, device_reference);
        }
      }
    );
  };

  const getDeviceInfo = (device: any) => {
    console.log("Device Info >>>>>>>>>", device);
    setShowDeviceInfoModal(true);
    setDeviceInfo([
      {
        label: "Child",
        value: device.child,
        order: 1,
      },

      {
        label: "Device",
        value: device.device_name,
        order: 2,
      },

      {
        label: "Mac address",
        value: device.device_reference,
        order: 5,
      },

      {
        label: "Firmware last updated on",
        value: device?.firmware_updated_on?.split("T")[0] || "",
        order: 6,
      },
    ]);
    readDeviceFirmwareVersion(device.device_id, device.device_reference);
  };

  return (
    <IonPage>
      <Hamburger></Hamburger>
      <IonContent fullscreen>
        <IonGrid className="ion-padding-top">
          {isApiLoading && <AppSpinner />}

          {!childDeviceList?.length && !isApiLoading && (
            <IonRow className="ion-justify-content-center ion-margin-top ion-padding-top">
              <IonText className="ion-margin-top ion-padding-top">
                No device found!
              </IonText>
            </IonRow>
          )}

          {childDeviceList?.length > 0 && !isApiLoading && (
            <>
              <IonRow className="ion-justify-content-end ion-padding-end">
                <AppButton
                  buttonText="UPDATE WI-FI"
                  onButtonClick={() => redirectToUpodateWiFi(selectedDevices)}
                />
                <AppButton
                  buttonText="LOCK DEVICES"
                  disabled={!selectedDevices?.length}
                  onButtonClick={() => lockSelectedDevices()}
                />
              </IonRow>

              <IonGrid className="ion-text-center ion-padding-top device-container">
                <IonItem
                  button
                  lines="none"
                  detail={false}
                  className="no-ripple"
                >
                  <IonCheckbox
                    slot="start"
                    aria-label="select_all"
                    value={isAllSelected}
                    checked={isAllSelected}
                    onIonChange={(e) => {
                      if (e?.detail?.checked) {
                        setAllSelected(true);
                        selectAllDevice();
                      } else {
                        setAllSelected(false);
                        deSelectAllDevice();
                      }
                    }}
                  ></IonCheckbox>
                  <IonCol size="3">
                    <IonLabel className="primary-heading font-18">
                      Device Name
                    </IonLabel>
                  </IonCol>
                  <IonCol className="primary-heading font-18">
                    Associated With
                  </IonCol>
                  <IonCol className="text-bold font-18 ion-text-center">
                    Battery
                  </IonCol>
                  <IonCol className="primary-heading font-18">Status</IonCol>
                  <IonCol
                    className="primary-heading font-18 ion-text-end"
                    size="1"
                  >
                    Action
                  </IonCol>
                </IonItem>
                {childDeviceList?.map((device: any, index: number) => {
                  return (
                    <IonItem key={index} lines="none" detail={false}>
                      <IonCheckbox
                        slot="start"
                        aria-label={`select child ${device.device_reference}`}
                        value={device.device_reference}
                        checked={device?.is_selected}
                        disabled={
                          device?.connectionStatus?.toLowerCase() !==
                          "connected"
                        }
                        onIonChange={(e) => {
                          if (e?.detail?.checked) {
                            device.is_selected = true;
                          } else {
                            device.is_selected = false;
                          }
                          setChildDeviceList(childDeviceList);
                          onChildSelectChange(e, device);
                        }}
                      ></IonCheckbox>
                      <IonCol size="3">
                        <IonLabel>{device.device_name}</IonLabel>
                      </IonCol>
                      <IonCol>{device.child}</IonCol>
                      <IonCol className="ion-text-center">
                        {device.battery}
                      </IonCol>
                      <IonCol>{device?.connectionStatus}</IonCol>
                      <IonCol size="1" className="ion-text-end">
                        <IonButton
                          id={`click-trigger${device?.child_id}`}
                          fill="clear"
                          disabled={
                            device?.connectionStatus?.toLowerCase() !==
                            "connected"
                          }
                        >
                          <IonIcon
                            slot="icon-only"
                            icon={ellipsisVerticalOutline}
                          />
                        </IonButton>
                        <IonPopover
                          trigger={`click-trigger${device?.child_id}`}
                          triggerAction="click"
                          dismissOnSelect={true}
                        >
                          <IonContent class="ion-padding">
                            <IonList>
                              <IonItem
                                button
                                detail={false}
                                lines="none"
                                onClick={() => renameDevice(device)}
                              >
                                Rename Device
                              </IonItem>
                              <IonItem
                                button
                                detail={false}
                                lines="none"
                                onClick={() =>
                                  enableBuzzDevice(device.device_reference)
                                }
                              >
                                Buzz Device
                              </IonItem>
                              <IonItem
                                button
                                detail={false}
                                lines="none"
                                onClick={() =>
                                  redirectToUpodateWiFi([
                                    device.device_reference,
                                  ])
                                }
                              >
                                Update Wi-Fi
                              </IonItem>
                              <IonItem
                                button
                                detail={false}
                                lines="none"
                                onClick={() => {
                                  if (device.is_locked) {
                                    unlockDevice(
                                      bleConnectionState[
                                        device.device_reference
                                      ].device_reference,
                                      bleConnectionState[
                                        device.device_reference
                                      ].mac_address
                                    );
                                  } else {
                                    lockDevice(
                                      bleConnectionState[
                                        device.device_reference
                                      ].device_reference,
                                      bleConnectionState[
                                        device.device_reference
                                      ].mac_address
                                    );
                                  }
                                }}
                              >
                                {device.is_locked
                                  ? "Unlock Device"
                                  : "Lock Device"}
                              </IonItem>

                              <IonItem
                                button
                                detail={false}
                                lines="none"
                                onClick={() => getDeviceInfo(device)}
                              >
                                Device Info
                              </IonItem>
                            </IonList>
                          </IonContent>
                        </IonPopover>
                      </IonCol>
                    </IonItem>
                  );
                })}
              </IonGrid>
            </>
          )}
        </IonGrid>
        {showRenameModal && (
          <IonModal
            isOpen={showRenameModal}
            onDidDismiss={() => setShowRenameModal(false)}
            className="auto-height"
          >
            <RenameDevice
              deviceName={selectedDevice.device_name}
              onChange={(name: string) => onDeviceNameChange(name)}
              onclose={() => onclose()}
            ></RenameDevice>
          </IonModal>
        )}

        {showDeviceInfoModal && deviceInfo?.length === 6 && (
          <IonModal
            isOpen={showDeviceInfoModal}
            onDidDismiss={() => setShowDeviceInfoModal(false)}
            className="info-modal "
          >
            <Info
              title="Device Info"
              info={deviceInfo}
              column_size={6}
              onclose={() => setShowDeviceInfoModal(false)}
            ></Info>
          </IonModal>
        )}
      </IonContent>
    </IonPage>
  );
};

export default UpdateDevice;
