import {
  AUTHENTICATION_IS_CLASSROOM_FULL,
  AUTHENTICATION_IS_COACH,
  AUTHENTICATION_IS_EDUCATOR,
  AUTHENTICATION_IS_INVITE_TEACHER,
  AUTHENTICATION_IS_PARTNER_ADMIN,
  AUTHENTICATION_IS_SCHOOL_ADMIN,
  AUTHENTICATION_PRIMARY_CAREGIVER,
  AUTHENTICATION_SECONDARY_CAREGIVER,
} from "../constants/permissions";
import AddChildren from "../pages/AddChildren/AddChildren";
import Home from "../pages/Home/Home";
import Log from "../pages/Log/Log";
import Login from "../pages/Login/Login";
import ManageDevice from "../pages/ManageDevice/ManageDevice";
import MyAccount from "../pages/MyAccount/MyAccount";
import UpdateDevice from "../pages/UpdateDevice/UpdateDevice";
import VerifyEmail from "../pages/VerifyEmail/VerifyEmail";
import WiFiSetup from "../pages/WiFiSetup/WiFiSetup";
import AppInfo from "../pages/AppInfo/AppInfo";
import ResetPassword from "../pages/ResetPassword/ResetPassword";

interface SubMenu {
  title: string;
  path: string;
}

interface Routes {
  id: string;
  title: string;
  path?: string;
  component: any;
  isAuthRequired?: boolean;
  subMenu?: SubMenu[];
  isAccessToAll?: boolean;
  roles?: any;
}

const admin = [
  AUTHENTICATION_PRIMARY_CAREGIVER,
  AUTHENTICATION_SECONDARY_CAREGIVER,
  // "authentication.is_classroom_full",
  // "authentication.is_classroom_readonly",
  AUTHENTICATION_IS_SCHOOL_ADMIN,
  AUTHENTICATION_IS_INVITE_TEACHER,
  // "authentication.assigned_classroom_only",
  // "authentication.assigned_school_only",
  AUTHENTICATION_IS_EDUCATOR,
  // "authentication.is_assign_teacher",
];

const coaches = [
  // AUTHENTICATION_PRIMARY_CAREGIVER,
  // "authentication.secondary_caregiver",
  AUTHENTICATION_IS_CLASSROOM_FULL,
  // "authentication.is_classroom_readonly",
  AUTHENTICATION_IS_SCHOOL_ADMIN,
  // AUTHENTICATION_IS_INVITE_TEACHER,
  // "authentication.assigned_classroom_only",
  // "authentication.assigned_school_only",
  AUTHENTICATION_IS_EDUCATOR,

  // "authentication.is_assign_teacher",
];

const primary = [
  AUTHENTICATION_PRIMARY_CAREGIVER,
  "authentication.is_classroom_full",
  "authentication.assigned_classroom_only",
  "authentication.assigned_school_only",
  AUTHENTICATION_IS_EDUCATOR,
  "authentication.is_assign_teacher",
];

const secondary = [
  "authentication.secondary_caregiver",
  "authentication.assigned_classroom_only",
  "authentication.assigned_school_only",
  "authentication.is_educator",
];

const routeList: Routes[] = [
  {
    id: "home",
    title: "Home",
    path: "/home",
    isAuthRequired: true,
    component: Home,
    isAccessToAll: true,
    roles: [],
  },
  {
    id: "myAccount",
    title: "My Account",
    path: "/my-account",
    isAuthRequired: true,
    component: MyAccount,
    isAccessToAll: true,
    roles: [],
  },
  {
    id: "manageDevice",
    title: "Assign Luets",
    path: "/device/manage-northstar",
    isAuthRequired: true,
    component: ManageDevice,
    isAccessToAll: false,
    roles: [AUTHENTICATION_PRIMARY_CAREGIVER],
  },
  {
    id: "addChildren",
    title: "Add Children",
    path: "/account/add-children",
    isAuthRequired: true,
    component: AddChildren,
    isAccessToAll: false,
    roles: [AUTHENTICATION_PRIMARY_CAREGIVER],
  },
  {
    id: "log",
    title: "Log",
    path: "/log",
    isAuthRequired: true,
    component: Log,
    isAccessToAll: true,
    roles: [],
  },
  {
    id: "wifiSetup",
    title: "Wi-Fi Setup",
    path: "/device/wifi-setup",
    isAuthRequired: true,
    component: WiFiSetup,
    isAccessToAll: false,
    roles: [AUTHENTICATION_PRIMARY_CAREGIVER],
  },

  {
    id: "updateDevice",
    title: "Manage Luets",
    path: "/device/update",
    isAuthRequired: true,
    component: UpdateDevice,
    isAccessToAll: false,
    roles: [AUTHENTICATION_PRIMARY_CAREGIVER],
  },

  {
    id: "verifyEmail",
    title: "Verify Email",
    path: "/auth/email-verify",
    isAuthRequired: false,
    component: VerifyEmail,
    isAccessToAll: true,
    roles: [],
  },

  {
    id: "resetPassword",
    title: "Reset Password",
    path: "/auth/resetPassword",
    isAuthRequired: false,
    component: ResetPassword,
    isAccessToAll: true,
    roles: [],
  },

  {
    id: "appInfo",
    title: "App Info",
    path: "/info",
    isAuthRequired: true,
    component: AppInfo,
    isAccessToAll: true,
    roles: [],
  },

  {
    id: "login",
    title: "Login",
    path: "/",
    isAuthRequired: false,
    isAccessToAll: true,
    component: Login,
    roles: [],
  },
];

export default routeList;
