import React, { useContext } from "react";
import { useState, useEffect } from "react";
import {
  IonButton,
  IonIcon,
  IonImg,
  IonLabel,
  IonSpinner,
  IonGrid,
  IonItem,
  IonRow,
  IonText,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonToggle,
  useIonAlert,
} from "@ionic/react";
import { Capacitor } from "@capacitor/core";
import { close } from "ionicons/icons";

import { BleSearchDevice, stopBleScan } from "ble-library";
import { GlobalContext } from "../../context/Provider";
import {
  enableBluetooth,
  enableLocation,
  openAndroidSettings,
  openIOSSettings,
} from "../../common/BlePermissions";
import "./AddNorthStar.css";
import { BLE_TURNED_ON, LOCATION_TURNED_ON } from "../../constants";
import { AppLogger } from "../../Services/Logger";

const AddNorthStar: React.FC<any> = (props: any) => {
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [deviceList, setDeviceList] = useState<any>([]);
  const [isBLETurnedOn, setBLETurnedOn] = useState(false);
  const [isBLEOn, setBLEStatus] = useState(false);
  const [isLocationTurnedOn, setLocationTurnedOn] = useState(false);
  const [isLocationOn, setLocationOn] = useState(false);
  const { permissionState, permissionDispatch } = useContext(GlobalContext);
  const platform = Capacitor.getPlatform();
  const [create] = useIonAlert();

  let tempDevices: any = [];

  useEffect(() => {
    setBLETurnedOn(permissionState?.bleTurnedOn);
    setLocationTurnedOn(permissionState?.locationTurnedOn);

    if (permissionState?.bleTurnedOn && permissionState?.locationTurnedOn) {
      searchDevices();
    }
  }, [permissionState]);

  const onDeviceDiscovered = (device: any) => {
    tempDevices = [...tempDevices, device];
    setDeviceList(tempDevices);
  };

  const searchDevices = () => {
    AppLogger(
      {
        device_id: localStorage.teacher_id,
      },
      "Add_New_Device",
      "info",
      `Search new device`
    );
    stopBleScan();
    setIsSearching(true);
    setTimeout(() => {
      const isPairingMode = true;
      BleSearchDevice(isPairingMode, function (res: any) {
        if (
          !tempDevices.some(function (device: any) {
            return device.address === res.address;
          })
        ) {
          console.log("Device", res);
          AppLogger(
            {
              device_id: localStorage.teacher_id,
            },
            "Add_New_Device",
            "info",
            `Found devices:: ${JSON.stringify(res)}`
          );
          onDeviceDiscovered(res);
        }
      });
    }, 1500);
  };

  const showIOSLocationAlert = (message: string) => {
    create({
      message,
      buttons: [
        {
          text: "Close",
          role: "close",
        },
        {
          text: "Go to settings",
          handler: () => {
            if (platform === "android") {
              openAndroidSettings();
            }
            if (platform === "ios") {
              openIOSSettings();
            }
          },
        },
      ],
    });
  };

  const showBLEAlert = (message: string) => {
    create({
      message,
      buttons: [
        {
          text: "Close",
          role: "close",
        },
        {
          text: "Go to settings",
          handler: () => {
            if (platform === "android") {
              openAndroidSettings();
            }
            if (platform === "ios") {
              openIOSSettings();
            }
          },
        },
      ],
    });
  };

  const requestBLE = (e: any) => {
    console.log("Request BLE Permissionx");
    if (e?.detail?.checked) {
      setBLEStatus(true);
      enableBluetooth(true, (response: any) => {
        AppLogger(
          {
            device_id: localStorage.teacher_id,
          },
          "Add_New_Device",
          "info",
          `Request bluetooth permission response:: ${JSON.stringify(response)}`
        );
        if (response === true) {
          permissionDispatch(BLE_TURNED_ON);
        } else {
          if (
            !response?.isEnabled &&
            response?.redirectToSettings &&
            response.message
          ) {

            showBLEAlert(response.message);
          }
          setBLEStatus(false);
        }
      });
    }
  };

  const requestLocation = (e: any) => {
    console.log("Request Location Permission");

    if (e?.detail?.checked) {
      setLocationOn(true);
      enableLocation((response: any) => {
        AppLogger(
          {
            device_id: localStorage.teacher_id,
          },
          "Add_New_Device",
          "info",
          `Request location permission response:: ${JSON.stringify(response)}`
        );
        if (response === true) {
          permissionDispatch(LOCATION_TURNED_ON);
        } else {
          if (
            !response?.isEnabled &&
            response?.redirectToSettings &&
            response.message
          ) {
            showIOSLocationAlert(response.message);

          }
          setLocationOn(false);
        }
      });
    }
  };

  return (
    <>
      {(!isBLETurnedOn || !isLocationTurnedOn) && (
        <IonGrid>
          <IonCard className="ion-text-center ion-padding">
            <IonCardContent>
              <IonCardSubtitle>
                <IonRow className="ion-justify-content-center ion-padding-vertical">
                  <IonText className="primary-dark">
                    Luet needs access to Bluetooth and location to pair your
                    device.
                  </IonText>
                </IonRow>
              </IonCardSubtitle>
              <IonRow className="ion-justify-content-center ion-padding-bottom">
                <IonText className="primary-dark">
                  Please turn on access below.
                </IonText>
              </IonRow>

              {!isBLETurnedOn && (
                <IonRow className="ion-padding">
                  <IonToggle
                    className="width-100"
                    labelPlacement="start"
                    justify="space-between"
                    aria-label="ble-permission"
                    checked={isBLEOn}
                    disabled={isBLETurnedOn}
                    onIonChange={(e) => requestBLE(e)}
                  >
                    Access to Bluetooth
                  </IonToggle>
                </IonRow>
              )}
              {!isLocationTurnedOn && (
                <IonRow className="ion-padding">
                  <IonToggle
                    className="width-100"
                    labelPlacement="start"
                    justify="space-between"
                    aria-label="location-permission"
                    disabled={isLocationTurnedOn}
                    checked={isLocationOn}
                    onIonChange={(e) => requestLocation(e)}
                  >
                    Access to Location
                  </IonToggle>
                </IonRow>
              )}
            </IonCardContent>
          </IonCard>
        </IonGrid>
      )}

      {isBLETurnedOn && isLocationTurnedOn && (
        <IonGrid className="ion-padding">
          <IonRow className="ion-justify-content-end">
            <IonIcon
              icon={close}
              className="close-icon"
              onClick={() => props?.closeModal()}
            ></IonIcon>
          </IonRow>
          <IonRow className="ion-padding">
            <IonText className="ion-text-center">
              Please make sure your Bluetooth is turned on and your Luet device
              is in the vicinity of your phone to connect with your Luet.
            </IonText>
          </IonRow>

          <IonGrid className="device-list">
            {deviceList?.map((device: any) => {
              return (
                <IonItem
                  key={device.address}
                  className="cursor-pointer"
                  onClick={() => props?.onDeviceSelect(device)}
                >
                  <IonImg
                    src="assets/device_icon.png"
                    className="device-icon ion-margin-horizontal"
                  ></IonImg>
                  <IonLabel>{device?.name}</IonLabel>
                </IonItem>
              );
            })}
          </IonGrid>

          <IonRow className="ion-padding ion-justify-content-center">
            <IonButton
              onClick={() => {
                setIsSearching(true);
                searchDevices();
              }}
            >
              {!isSearching ? "Search Luet" : "Searching"}
              {isSearching && (
                <IonSpinner name="crescent" className="ion-margin-start" />
              )}
            </IonButton>
          </IonRow>
        </IonGrid>
      )}
    </>
  );
};

export default AddNorthStar;
