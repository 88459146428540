import { Device, DeviceId, DeviceInfo } from "@capacitor/device";
import { Capacitor } from "@capacitor/core";
import { App } from "@capacitor/app";
import GitInfo from "react-git-info/macro";
import { format } from "date-fns";
import axiosInstance from "../helper/axios";

interface LogTypes {
  info: number;
  error: number;
  debug: number;
}
const loggerTypes: LogTypes = {
  info: 0,
  error: 1,
  debug: 2,
};

const logger: any = {};
let gitShortHash: null | string = null;
let deviceInfo: DeviceInfo = {} as DeviceInfo;
let uniqueIdInfo: DeviceId = {} as DeviceId;

const gitInfo = GitInfo();
const isNativePlatform = Capacitor.isNativePlatform();

const getGitHash = () => {
  console.log(gitInfo);
  gitShortHash = gitInfo?.commit?.shortHash;
};

if (!gitShortHash) {
  getGitHash();
}

async function getAppInfo() {
  const info = await App.getInfo();
  console.log("Logger::App Info >>>>>>>>>>>>>>>>>>::", info);

  if (info?.version) {
    localStorage.app_version = info?.version;
  }
  if (info?.build) {
    localStorage.build_version = info?.build;
  }
}

if (isNativePlatform) {
  getAppInfo();
}

getGitHash();
const logDeviceInfo = async () => {
  const info = await Device.getInfo();
  console.log("Device Info::", info);
  deviceInfo = info;
};

const getUniqueDeviceId = async () => {
  uniqueIdInfo = await Device.getId();
};

if (!deviceInfo?.platform) {
  logDeviceInfo();
  getUniqueDeviceId();
}

const saveLogger = (deviceId: string, logs: any) => {
  const logType = deviceId === localStorage.teacher_id ? "app" : "device";
  axiosInstance()
    .post(`/debug/upload/job/${logType}/${deviceId}`, logs)
    .then((res) => {
      logger[deviceId] = [];
      console.log("Log save response::", res.data);
    })
    .catch((err) => {
      console.log("Log save error", err);
    });
};

export const AppLogger = (
  device: any,
  screen: string,
  ltype: string,
  logText: string
) => {
  if (deviceInfo?.manufacturer && deviceInfo?.model && deviceInfo?.osVersion) {
    const message: any = {};
    message.time = format(new Date(), "yyyy-MM-dd HH:mm:ss");
    message.name = deviceInfo?.name || "";
    message.manufacturer = deviceInfo?.manufacturer || "";
    message.model = deviceInfo?.model || "";
    message.platform = deviceInfo?.platform || "";
    message.os = deviceInfo?.operatingSystem || "";
    message.osVersion = deviceInfo?.osVersion || "";
    message.uniqueIdentifier = uniqueIdInfo?.identifier || "";
    message.appVersion = localStorage?.app_version || "";
    message.buildVersion = localStorage?.build_version
      ? `V${localStorage?.build_version}`
      : "";
    message.git = gitShortHash || "";
    message.webVersion =
      deviceInfo?.manufacturer || " " + deviceInfo?.webViewVersion || "";
    message.mac = device?.mac_address || "N/A";
    if (logText) {
      message.log = `${screen} | ${ltype} | ${logText}`;
    } else {
      message.log = "";
    }

    if (!logger[device.device_id]) {
      logger[device.device_id] = [];
    }
    logger[device.device_id || localStorage.teacher_id].push(message);
  }
};

const convertLogsIntoBytes = (deviceId: string, string_value: string) => {
  if (deviceId !== undefined) {
    const size = 100;
    const numChunks = Math.ceil(string_value.length / size);
    for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
      const splittedLogs = string_value.slice(o, size);
      if (splittedLogs.length > 0) {
        const blob: any = new Blob([JSON.stringify(splittedLogs, null, 2)]);
        saveLogger(deviceId, blob);
      }
    }
  }
};

const checkLogger = () => {
  Object.keys(logger).map((device: any) => {
    if (logger[device]) {
      convertLogsIntoBytes(device || localStorage.teacher_id, logger[device]);
    }
  });
};

setInterval(function () {
  checkLogger();
}, 10000);
