import React, { useContext, useEffect } from "react";
import { useState } from "react";
import {
  IonCol,
  IonGrid,
  IonItem,
  IonRow,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";

import "./Classroom.css";
import { isRoleExist, sortList } from "../../common/Util";
import { getAssignedTeacherClassrooms } from "../../Services/Classroom";
import { GlobalContext } from "../../context/Provider";
import {
  AUTHENTICATION_IS_COACH,
  AUTHENTICATION_IS_PARTNER_ADMIN,
  AUTHENTICATION_IS_SCHOOL_ADMIN,
} from "../../constants/permissions";
import { getAllClassrooms, getAllSchools } from "../../Services/Teacher";

const Classroom: React.FC<any> = (props: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [classroomList, setClassroomList] = useState<any>();
  const [schoolList, setSchoolList] = useState<any>();
  const { roleState } = useContext(GlobalContext);
  const isPartner = isRoleExist(roleState, [AUTHENTICATION_IS_PARTNER_ADMIN]);

  const isAdmin = isRoleExist(roleState, [AUTHENTICATION_IS_SCHOOL_ADMIN]);
  const isCoach = isRoleExist(roleState, [AUTHENTICATION_IS_COACH]);

  const setData = (res: any) => {
    if (res?.data?.length) {
      setClassroomList(res?.data || []);
      if (!localStorage.classroom_id) {
        localStorage.classroom_id = res?.data[0].key;
        props?.onClassroomChange(res?.data[0].key);
      }
    } else {
      setClassroomList([]);
    }
  };
  const getTeacherAndCoachClassrooms = () => {
    setIsLoading(true);
    getAssignedTeacherClassrooms(parseInt(localStorage.school_id))
      .then((res: any) => {
        console.log("Classroom list", res);

        setData(res);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const getAdminClassrooms = () => {
    setIsLoading(true);
    getAllClassrooms(parseInt(localStorage.school_id))
      .then((res: any) => {
        setData(res);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const getSchools = () => {
    getAllSchools()
      .then((res: any) => {
        setSchoolList(res?.data);
        if (!localStorage.school_id) {
          localStorage.school_id = res?.data[0].key;
          props?.onSchoolChange(res?.data[0].key);
        }
        if (isPartner) {
          getAdminClassrooms();
        }
        if (isCoach) {
          getTeacherAndCoachClassrooms();
        }
      })
      .catch((err: any) => {
        console.log("error", err);
      });
  };

  useEffect(() => {
    if (isPartner || isCoach) {
      getSchools();
    } else if (isAdmin) {
      getAdminClassrooms();
    } else {
      getTeacherAndCoachClassrooms();
    }
  }, []);

  const getClassroomsByRole = () => {
    if (isPartner || isAdmin) {
      getAdminClassrooms();
    } else {
      getTeacherAndCoachClassrooms();
    }
  };

  return (
    <IonGrid className="ion-no-padding">
      <IonRow>
        {(isPartner || isCoach) && (
          <IonCol size="4">
            <IonItem detail={false} className="m-t-1 width-">
              <IonSelect
                label="Current School"
                labelPlacement="floating"
                interface="popover"
                value={parseInt(localStorage.school_id)}
                onIonChange={(event: any) => {
                  localStorage.school_id = event.detail?.value;
                  delete localStorage.classroom_id;
                  props?.onSchoolChange(event.detail?.value);
                  getClassroomsByRole();
                }}
              >
                {sortList(schoolList, "value")?.map((school: any) => {
                  return (
                    <IonSelectOption value={school.key} key={school.key}>
                      {school?.value}
                    </IonSelectOption>
                  );
                })}
              </IonSelect>
            </IonItem>
          </IonCol>
        )}
        <IonCol size="4">
          <IonItem detail={false} className="m-t-1 width-">
            <IonSelect
              label="Current classroom"
              labelPlacement="floating"
              interface="popover"
              value={parseInt(localStorage.classroom_id)}
              onIonChange={(event: any) => {
                localStorage.classroom_id = event.detail?.value;
                props?.onClassroomChange(event.detail?.value);
              }}
            >
              {sortList(classroomList, "value")?.map((classroom: any) => {
                return (
                  <IonSelectOption value={classroom.key} key={classroom.key}>
                    {classroom?.value}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
          </IonItem>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default Classroom;
