import { AppLogger } from "../Services/Logger";
import { BleConnection } from "ble-library";

export const setConnectionStatus = (data: any, dispatch: any) => {
  dispatch(data);
};

export const updateDeviceState = (data: any, dispatch: any) => {
  const mac_address: any = data.mac_address;
  const newdata: any = {};
  newdata[mac_address] = {
    ...data,
  };
  dispatch(newdata);
};

export const removeDeviceInState = (mac_address: string, dispatch: any) => {
  console.log("Remove device in state >>>>>>>", mac_address);
  dispatch({ type: "REMOVE", payload: mac_address });
};

export const connectDevice = (device: any, callback: any) => {
  console.log("Connect device >>>>>>>", device);
  AppLogger(device, "BLE", "info", "Device connection start");
  BleConnection(device, "reconnect", AppLogger, function (response: any) {
    console.log("Connect response:::", response);
    callback({
      ...response,
    });
  });
};
