export const AUTHENTICATION_IS_EDUCATOR = "authentication.is_educator";
export const AUTHENTICATION_IS_PARTNER_ADMIN = "authentication.is_partner_ui";
export const AUTHENTICATION_IS_SCHOOL_ADMIN = "authentication.is_school_admin";
export const AUTHENTICATION_IS_COACH = "authentication.is_coach_ui";
export const AUTHENTICATION_PRIMARY_CAREGIVER =
  "authentication.primary_caregiver";
export const AUTHENTICATION_IS_CLASSROOM_FULL =
  "authentication.is_classroom_full";
export const AUTHENTICATION_SECONDARY_CAREGIVER =
  "authentication.is_classroom_full";
export const AUTHENTICATION_IS_CLASSROOM_READONLY =
  "authentication.is_classroom_readonly";
export const AUTHENTICATION_IS_SCHOOL_FULL = "authentication.is_school_full";
export const AUTHENTICATION_IS_INVITE_TEACHER =
  "authentication.is_invite_teacher";
export const AUTHENTICATION_ASSIGNED_CLASSROOM_ONLY =
  "authentication.assigned_classroom_only";
export const AUTHENTICATION_ASSIGNED_SCHOOL_ONLY =
  "authentication.assigned_school_only";
export const AUTHENTICATION_IS_ASSIGN_TEACHER =
  "authentication.is_assign_teacher";

export const EDUCATOR_PARTNER_ADMIN = "educator_partner";
export const EDUCATOR_SCHOOL_ADMIN = "educator_admin";
export const EDUCATOR_MEMBER = "member";
export const EDUCATOR_TEACHER = "Teacher";
