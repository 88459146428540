import axiosInstance from "../helper/axios";

export const getAccessToken = async (uidb64: string, token: string) => {
  return await axiosInstance().get(
    `/auth/generate-access-token/?uidb64=${uidb64}&token=${token}`
  );
};

export const updatePassword = async (password: any) => {
  return await axiosInstance().put("/educator/account/update", password);
};

export const verifyToken = async (uidb64: string, token: string) => {
  return await axiosInstance().get(
    `/auth/password-token-verify/?uidb64=${uidb64}&token=${token}`
  );
};

export const verifyRefreshToken = async (refresh: string) => {
  return await axiosInstance().post("/auth/login/refresh/", {
    refresh,
  });
};

export const checkAppHealth = async () => {
  return await axiosInstance().get("/auth/health/check/");
};
