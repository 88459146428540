import React from "react";
import { useState } from "react";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonTitle,
  IonSearchbar,
  IonToolbar,
  IonText,
  IonIcon,
  IonRow,
  IonFooter,
  IonButton,
} from "@ionic/react";
import { checkmarkOutline, close } from "ionicons/icons";
import { sortList } from "../../common/Util";

interface Item {
  text: string;
  value: string;
  order?: number;
}

interface TypeaheadProps {
  items: Item[];
  selectedItem: string;
  title?: string;
  notFoundText?: string;
  isMultiSelect?: boolean;
  onSelectionCancel?: () => void;
  onSelectionChange?: (items: string) => void;
}

function AutoComplete(props: TypeaheadProps) {
  const [filteredItems, setFilteredItems] = useState<Item[]>([
    ...(props.items || []),
  ]);
  const [workingSelectedValues, setWorkingSelectedValues] = useState<any>(
    props.selectedItem || []
  );

  const cancelChanges = () => {
    const { onSelectionCancel } = props;
    if (onSelectionCancel !== undefined) {
      onSelectionCancel();
    }
  };

  const confirmChanges = () => {
    const { onSelectionChange } = props;
    if (onSelectionChange !== undefined) {
      onSelectionChange(workingSelectedValues);
    }
  };

  const searchbarInput = (ev: any) => {
    filterList(ev.target.value);
  };

  /**
   * Update the rendered view with
   * the provided search query. If no
   * query is provided, all data
   * will be rendered.
   */
  const filterList = (searchQuery: string | null | undefined) => {
    /**
     * If no search query is defined,
     * return all options.
     */
    if (searchQuery === undefined || searchQuery === null) {
      setFilteredItems([...props.items]);
    } else {
      /**
       * Otherwise, normalize the search
       * query and check to see which items
       * contain the search query as a substring.
       */
      const normalizedQuery = searchQuery.toLowerCase();
      setFilteredItems(
        props.items.filter((item) => {
          return item.text.toLowerCase().includes(normalizedQuery);
        })
      );
    }
  };

  const onItemChange = (item: any) => {
    if (item?.value) {
      if (props?.isMultiSelect) {
        const itemIdx = workingSelectedValues?.indexOf(item);
        if (itemIdx >= 0) {
          workingSelectedValues?.splice(itemIdx, 1);
          setWorkingSelectedValues((workingSelectedValues: any) => [
            ...workingSelectedValues,
          ]);
        } else {
          setWorkingSelectedValues([...workingSelectedValues, item]);
        }
      }
      const { onSelectionChange } = props;
      if (onSelectionChange !== undefined && !props?.isMultiSelect) {
        onSelectionChange(item);
      }
    }
  };

  const confirmSelection = () => {
    const { onSelectionChange } = props;
    if (onSelectionChange !== undefined && props?.isMultiSelect) {
      onSelectionChange(workingSelectedValues);
    }
  };

  return (
    <>
      <IonHeader collapse="fade" className="ion-no-border">
        <IonToolbar>
          <IonTitle className="ion-text-center">{props.title}</IonTitle>
          <IonButtons slot="end" onClick={cancelChanges}>
            <IonIcon
              slot="end"
              icon={close}
              className="cursor-pointer"
            ></IonIcon>
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar onIonInput={searchbarInput}></IonSearchbar>
        </IonToolbar>
      </IonHeader>

      <IonContent class="ion-no-padding">
        {filteredItems?.length === 0 && (
          <IonRow className="ion-justify-content-center ion-margin">
            <IonText>{props?.notFoundText || "Not found!"}</IonText>
          </IonRow>
        )}
        <IonList class="ion-padding ion-margin-top">
          {sortList(filteredItems, "text")?.map((item: any) => (
            <IonItem
              detail={false}
              key={`${item?.value}`}
              button
              onClick={() => onItemChange(item)}
              className={`${
                workingSelectedValues === item?.value ? "hamburger-active" : ""
              }`}
            >
              <IonText>{item?.text}</IonText>

              {props?.isMultiSelect &&
                workingSelectedValues?.indexOf(item) !== -1 && (
                  <IonIcon
                    icon={checkmarkOutline}
                    slot="end"
                    color="primary"
                  ></IonIcon>
                )}
            </IonItem>
          ))}
        </IonList>
      </IonContent>
      {props?.isMultiSelect && (
        <IonFooter>
          <IonRow className="ion-justify-content-end ion-padding-horizontal">
            <IonButton onClick={() => confirmSelection()}>Confirm</IonButton>
          </IonRow>
        </IonFooter>
      )}
    </>
  );
}
export default AutoComplete;
