import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonPage,
  IonGrid,
  IonText,
  IonImg,
  IonRow,
  IonInput,
  useIonToast,
  IonHeader,
  IonButton,
  IonSpinner,
  IonModal,
  IonItem,
  IonLabel,
  IonBackButton,
} from "@ionic/react";
import queryString from "query-string";

import "./VerifyEmail.css";
import { useHistory } from "react-router";
import { requestVerifyEmail, verifyEmail } from "../../Services/User";
import toastConfig from "../../common/toast-config";
import BackButton from "../../components/BackButton/BackButton";
import AppInput from "../../components/AppInput/AppInput";
import AppButton from "../../components/AppButton/AppButton";
import AppSpinner from "../../components/AppSpinner/AppSpinner";

const VerifyEmail: React.FC = () => {
  const params = queryString.parse(location.search);
  const [verifyStatus, setVerifyStatus] = useState<any>({});
  const [isTokenVerified, setTokenVerified] = useState(false);
  const [resetPwdToken, setResetPwdToken] = useState();
  const [present] = useIonToast();
  const history = useHistory();
  const callbackType = params?.callback;
  const [isShowModal, setModalVisibility] = useState(false);
  const [email, setEmail] = useState<any>();
  const [isFormValid, setFormValid] = useState(false);
  const [newToken, setNewToken] = useState<string>("");

  const checkTokenValidStatus = (tokenObj: any) => {
    verifyEmail(tokenObj)
      .then((res: any) => {
        if (res?.status === 201) {
          if (res?.data?.message?.toLowerCase() === "successfully activated") {
            setVerifyStatus({
              isVerified: true,
              message:
                "Thank you for verifying your email. Your account is created.",
            });
            setNewToken(res?.data?.token);
          } else if (
            res?.data?.message?.toLowerCase() === "email already verified"
          ) {
            setVerifyStatus({
              isVerified: true,
              message: "Your email was successfully verified.",
            });
          }
          setTokenVerified(true);
        }
      })
      .catch((err: any) => {
        setTokenVerified(true);
        // if (err?.response?.data?.message[0]) {
        setVerifyStatus({
          isVerified: false,
          message:
            "Email verification unsuccessful. The link is either invalid or has expired. Click the button below to generate a new link",
        });
        // }
      });
  };
  useEffect(() => {
    const tokenObj: any = {
      token: params?.token,
      uidb64: params?.uidb64,
      set_password: params?.callback === "password" ? true : false,
    };
    checkTokenValidStatus(tokenObj);
  }, [params?.token]);

  const generateNewLink = (email: string) => {
    requestVerifyEmail(email)
      .then((res: any) => {
        if (res?.status === 201) {
          present({
            ...toastConfig.success,
            message: res.data,
          });
          setModalVisibility(false);
        }
      })
      .catch((err: any) => {
        if (err?.response?.data?.message?.detail)
          present({
            ...toastConfig.success,
            message:
              "Email is already verified. Please login using your existing credentials.",
          });
      });
  };

  const redirectToResetPassword = () => {
    history.push(
      `/auth/setNewPassword?uidb64=${params?.uidb64}&token=${newToken}`
    );
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <div>
          <BackButton></BackButton>
          <IonHeader className="ion-no-border ion-padding-horizontal">
            <div className="logo-section">
              <IonImg className="logo" alt="app-logo" src="/assets/logo.png" />
            </div>
          </IonHeader>
          <div className="helper">
            <div className="content">
              <IonGrid className="message-container ion-text-center ion-padding-horizontal">
                {verifyStatus.isVerified && verifyStatus.message && (
                  <div className="ion-text-center">
                    <IonText className="message primary-dark">
                      {verifyStatus?.message}
                    </IonText>

                    {callbackType === "password" ? (
                      <div className="ion-margin-top ion-padding-top">
                        <AppButton
                          className="mt-2"
                          buttonText="Click here to Reset Password"
                          onButtonClick={() => redirectToResetPassword()}
                        />
                      </div>
                    ) : (
                      <div className="ion-margin-top ion-padding-top">
                        <AppButton
                          className="mt-2"
                          href="/"
                          buttonText="Click here to login"
                          onButtonClick={() => redirectToResetPassword()}
                        />
                      </div>
                    )}
                  </div>
                )}

                {!verifyStatus.isVerified && verifyStatus.message && (
                  <div className="ion-text-center">
                    <IonText className="message primary-dark">
                      {verifyStatus?.message}
                    </IonText>
                    <div className="ion-margin-top ion-padding-top">
                      <AppButton
                        className="mt-2"
                        buttonText="Generate new link"
                        onButtonClick={() => {
                          setEmail(undefined);
                          setModalVisibility(true);
                        }}
                      />
                    </div>
                  </div>
                )}

                {!isTokenVerified && (
                  <IonGrid className="message-container ion-text-center ion-padding-horizontal mt-lg">
                    <AppSpinner />
                  </IonGrid>
                )}
              </IonGrid>
            </div>
          </div>
        </div>
      </IonContent>

      <IonModal
        isOpen={isShowModal}
        className="auto-height"
        onDidDismiss={() => {
          setModalVisibility(false);
        }}
      >
        <IonGrid className="ion-padding ota-modal ion-no-margin">
          <IonRow className="ion-padding ion-justify-content-center">
            <IonText className="primary-dark">Generate New Link</IonText>
          </IonRow>

          <AppInput
            label=" Enter Email"
            value={email}
            onInputChange={(value: any) => {
              setEmail(value);
            }}
          ></AppInput>

          <IonRow className="ion-justify-content-end">
            <AppButton
              fill="outline"
              buttonText="Close"
              onButtonClick={() => {
                setModalVisibility(false);
              }}
            />

            <AppButton
              buttonText="Generate"
              onButtonClick={() => {
                generateNewLink(email);
              }}
            />
          </IonRow>
        </IonGrid>
      </IonModal>
    </IonPage>
  );
};

export default VerifyEmail;
