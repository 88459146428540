import axios, { AxiosInstance } from "axios";
import config from "../conf.json";
import versionConfig from "../version.json";

type Header = {
  [key: string]: string;
};

type RequestHeader = {
  Authorization?: string;
  baseURL: string;
  url: string;
  method: string;
  mode: string;
  cache: string;
  headers: Header;
  credentials: string;
  redirect: string;
  referrer: string;
  data: {
    refresh: string;
  };
};

export const axiosInstance = (): AxiosInstance => {
  const backEndVersion = versionConfig?.version || 1;

  const baseURL = (config as any)?.APP_BACKEND_URL;
  const headers: Header = {
    Accept: `application/json; version=${backEndVersion}`,
  };
  if (localStorage.access) {
    headers.Authorization = `Bearer ${localStorage.access}`;
    
  }
  const axiosIns = axios.create({
    baseURL,
    headers,
  });

  axiosIns.interceptors.response.use(
    (response) => {
      if (localStorage.is_app_update_needed === "true") {
        delete localStorage.is_app_update_needed;
        window.dispatchEvent(new Event("storage"));
      }
      return response;
    },

    (error) => {
      const originalRequest = error?.config;

      if (
        error?.response?.status === 401 &&
        error?.config &&
        !error?.config?.__isRetryRequest &&
        !!localStorage.access
      ) {
        originalRequest._retry = true;

        return axios({
          baseURL: baseURL,
          url: "/auth/login/refresh/",
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "same-origin",
          redirect: "follow",
          referrer: "no-referrer",
          data: {
            refresh: localStorage.getItem("refresh"),
          },
        } as RequestHeader)
          .then((res) => {
            if (res.status === 200) {
              // 1) put token to LocalStorage
              localStorage.access = res.data.access;

              // 2) Change Authorization header
              originalRequest.headers.Authorization = `Bearer ${localStorage.access}`;

              // 3) return originalRequest object with Axios.
              return axios(originalRequest);
            } else {
              // throw Error if refresh token request fails
              throw Error(res.statusText);
            }
          })
          .catch((err) => {
            console.log("refreshToken Error: ", err);
            localStorage.clear();

            // redirect to login if refresh token is invalid or expired
            window.location.replace(`${process.env.PUBLIC_URL}/`);
          });
      }

      if (error?.response?.status === 406) {
        localStorage.is_app_update_needed = true;
        window.dispatchEvent(new Event("storage"));
      }

      // return Error object with Promise
      return Promise.reject(error);
    }
  );

  return axiosIns;
};
export default axiosInstance;
