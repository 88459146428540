import axiosInstance from "../helper/axios";

export const getCTCByData = async (
  classroom_id: number,
  date: string,
  queryParams: any
) => {
  return await axiosInstance().get(
    `/educator/home/${classroom_id}/${date}?draw=0&length=${queryParams?.length}&start=${queryParams?.start}`
  );
};

export const getDeviceIssuesByClassroom = async (
  classroom_id: number,
  queryParams: any
) => {
  return await axiosInstance().get(
    `/educator/device/issues/${classroom_id}?draw=0&length=${queryParams?.length}&start=${queryParams?.start}`
  );
};

export const createCTC = async (child_id: number, data: any) => {
  return await axiosInstance()
    .post(`/ctc/create/${child_id}`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      if (err.response) {
        console.log(err.response.data);
      } else {
        console.log("COULD NOT CONNECT");
      }
    });
};
