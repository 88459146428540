import React from 'react'
import {
  IonButton,
  IonCol,
  IonContent,
  IonIcon,
  IonPage,
  IonRow,
  IonText,
  IonGrid,
} from "@ionic/react";
import { camera } from "ionicons/icons";
import { useRef, useState } from "react";
import { useParams } from "react-router";

import Hamburger from "../../components/HamburgerMenu/Hamburger";
import ViewLog from "../../components/ViewLog/ViewLog";
import "./Log.css";

const Log: React.FC = () => {
  const { name } = useParams<{ name: string }>();
  const [jsonFile, setJSONFile] = useState<any>({ logs: {} });
  const [uploadedFile, setUploadedFile] = useState<any>();
  const fileUploadRef = useRef<any>(null);

  const uploadFile = (event: any) => {
    const fileReader = new FileReader();
    fileReader.readAsText(event.target.files[0], "UTF-8");
    setUploadedFile(event.target.files[0]);
    fileReader.onload = (e: any) => {
      console.log("e.target.result", e.target.result);
      setJSONFile(JSON.parse(e.target.result));
    };
  };

  const openFileUploadWindow = () => {
    fileUploadRef?.current?.click();
  };

  return (
    <IonPage>
      <Hamburger></Hamburger>
      <IonContent fullscreen>
        <IonGrid className="ion-margin">
          <IonRow className="ion-justify-content-center ion-align-items-center m-t-1">
            <IonButton fill="outline" onClick={openFileUploadWindow}>
              {" "}
              <IonIcon slot="icon-only" icon={camera}></IonIcon>
              &nbsp;&nbsp;Upload File
            </IonButton>
            <input
              ref={fileUploadRef}
              accept="application/json"
              className="ion-hide"
              type="file"
              name="myFile"
              onChange={uploadFile}
            />
            &nbsp;&nbsp;{uploadedFile?.name}
          </IonRow>

          {Object.entries(jsonFile?.logs).length > 0 && <ViewLog logFile={jsonFile}></ViewLog> }
          {/* {Object.entries(jsonFile?.logs).length > 0 && (
            <>
              {" "}
              <IonRow className="text-bold border-bottom table-head">
                <IonCol size="3">
                  <IonText> Module</IonText>
                </IonCol>

                <IonCol size="9" className="p-l-10">
                  <IonText> Log </IonText>
                </IonCol>
              </IonRow>
              <IonRow className="border-bottom">
                <IonCol size="3">
                  <IonText className="ion-text-capitalize"> Platform </IonText>
                </IonCol>
                <IonCol size="9" className="p-l-10">
                  {jsonFile?.platform}
                </IonCol>
              </IonRow>
              {Object.entries(jsonFile?.logs).map(
                ([key, value]: any, index) => (
                  <IonRow className="border-bottom">
                    <IonCol size="3">
                      <IonText className="ion-text-capitalize"> {key} </IonText>
                    </IonCol>

                    <IonCol size="9">
                      {value?.map((val: any) => (
                        <IonRow>
                          {val?.time && (
                            <>
                              <IonCol size="auto"> {val?.time}</IonCol>
                              <IonCol className="p-l-20">
                                {val?.type}:&nbsp;&nbsp;{val?.log}
                              </IonCol>
                            </>
                          )}

                          {!val?.time && <IonText>{val}</IonText>}
                        </IonRow>
                      ))}
                    </IonCol>
                  </IonRow>
                )
              )}
            </>
          )} */}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Log;
