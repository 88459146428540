import React, { useState } from "react";
import { IonRow, IonCol } from "@ionic/react";
import CustomInput from "./CustomInput";

type WiFiInfo = {
  name: string;
  password: string;
};

const WiFiCredentials = (props: any) => {
  const [wifiInfo, setWiFiInfo] = useState<WiFiInfo>({
    name: localStorage.getItem("wifi_name") || "",
    password: localStorage.getItem("wifi_password") || "",
  });

  return (
    <IonRow className="ion-justify-content-center ion-margin-vertical">
      <IonCol size="3" className="ion-no-padding">
        <CustomInput
          label="Enter Wi-Fi Name"
          maxlength={30}
          value={wifiInfo?.name}
          onChange={(val: string) => {
            localStorage.setItem("wifi_name", val);
            setWiFiInfo(() => ({
              ...wifiInfo,
              name: val,
            }));
            props?.onWiFiInfoChange();
          }}
        ></CustomInput>
      </IonCol>

      <IonCol size="3" className="ion-no-padding ion-padding-start">
        <CustomInput
          label="Enter Password"
          maxlength={60}
          value={wifiInfo?.password}
          onChange={(val: string) => {
            localStorage.setItem("wifi_password", val);
            setWiFiInfo(() => ({
              ...wifiInfo,
              password: val,
            }));
            props?.onWiFiInfoChange();
          }}
        ></CustomInput>
      </IonCol>
    </IonRow>
  );
};
export default WiFiCredentials;
