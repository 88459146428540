import React from "react";
import { IonGrid, IonText, IonRow, IonCol } from "@ionic/react";

function ViewLog(props: any) {
  return (
    <IonGrid className="ion-margin">
      {Object.entries(props?.logFile?.logs).length > 0 && (
        <>
          <IonRow className="text-bold border-bottom table-head">
            <IonCol size="3">
              <IonText> Module</IonText>
            </IonCol>

            <IonCol size="9">
              <IonText> Log </IonText>
            </IonCol>
          </IonRow>
          <IonRow className="border-bottom">
            <IonCol size="3">
              <IonText className="ion-text-capitalize"> Platform </IonText>
            </IonCol>
            <IonCol size="9">{props?.logFile?.platform}</IonCol>
          </IonRow>
          {Object.entries(props?.logFile?.logs).map(
            ([key, value]: any, index) => (
              <IonRow className="border-bottom" key={index}>
                <IonCol size="3">
                  <IonText className="ion-text-capitalize"> {key} </IonText>
                </IonCol>

                <IonCol size="9">
                  {value?.map((val: any, index: number) => (
                    <IonRow className="logs-text" key={index}>
                      <IonText>
                        {val?.time}&nbsp;|&nbsp;{val?.type}&nbsp;|&nbsp;
                        {val?.log}
                      </IonText>
                      {!val?.time && <IonText>{val}</IonText>}
                    </IonRow>
                  ))}
                </IonCol>
              </IonRow>
            )
          )}
        </>
      )}
    </IonGrid>
  );
}
export default ViewLog;
