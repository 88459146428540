import React, { useContext, useEffect, useState } from "react";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonText,
} from "@ionic/react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

import Hamburger from "../../components/HamburgerMenu/Hamburger";
import "./MyAccount.css";
import { GlobalContext } from "../../context/Provider";
import {
  AUTHENTICATION_IS_COACH,
  AUTHENTICATION_IS_INVITE_TEACHER,
  AUTHENTICATION_IS_PARTNER_ADMIN,
  AUTHENTICATION_IS_SCHOOL_ADMIN,
  AUTHENTICATION_IS_SCHOOL_FULL,
  AUTHENTICATION_PRIMARY_CAREGIVER,
} from "../../constants/permissions";
import { isRoleExist } from "../../common/Util";
import routeList from "../../routes/RouteList";
import AppButton from "../../components/AppButton/AppButton";

type Menu = {
  left: string[];
  right: string[];
};

const MyAccount: React.FC = () => {
  const [menuList, setMenuList] = useState<Menu>({
    left: [],
    right: [],
  });
  const { bleConnectionDispatch, bleConnectionState, roleState } =
    useContext(GlobalContext);
  const history = useHistory();

  console.log("Connection state::My Account::", bleConnectionState);

  const redirectTo = (url: string) => {
    history.push(url);
  };

  const partnerAdminLeftMenu = ["createSchool"];
  const partnerAdminRightMenu = ["partnerProfile", "faq", "contactEmail"];

  const adminLeftMenu = [
    "createAcademicYear",
    "inviteTeacher",
    "addClassroom",
    "assignCoaches",
    "archiveClassroom",
    "manageTeacherAssignment",
    "manageCoachAssignment",
    "schoolProfile",
  ];
  const adminRightMenu = ["profile", "faq", "contactEmail"];

  const coachLeftMenu = ["coachSession", "coachNotesHistory", "childAtGlance"];
  const coachRightMenu = ["profile", "faq", "contactEmail"];

  const primaryCareGiverLeftMenu = [
    "addChildren",
    "removeChildren",
    "childGoal",
    "coachingNotes",
  ];
  const primaryCareGiverRightMenu = [
    "updateDevice",
    "manageDevice",
    "wifiSetup",
  ];

  useEffect(() => {
    if (isRoleExist(roleState, [AUTHENTICATION_IS_PARTNER_ADMIN])) {
      setMenuList({
        left: partnerAdminLeftMenu,
        right: partnerAdminRightMenu,
      });
    } else if (isRoleExist(roleState, [AUTHENTICATION_IS_SCHOOL_ADMIN])) {
      setMenuList({
        left: adminLeftMenu,
        right: adminRightMenu,
      });
    } else if (isRoleExist(roleState, [AUTHENTICATION_PRIMARY_CAREGIVER])) {
      setMenuList({
        left: primaryCareGiverLeftMenu,
        right: primaryCareGiverRightMenu,
      });
    } else if (isRoleExist(roleState, [AUTHENTICATION_IS_COACH])) {
      setMenuList({
        left: coachLeftMenu,
        right: coachRightMenu,
      });
    }
  }, [roleState]);

  return (
    <IonPage>
      <Hamburger></Hamburger>
      <IonContent fullscreen>
        <IonGrid className="my-account-container">
          <IonRow className="ion-padding">
            <IonCol className="section">
              {isRoleExist(roleState, [AUTHENTICATION_IS_PARTNER_ADMIN]) && (
                <IonGrid>
                  <IonText className="primary-heading card-title">
                    Manage Schools
                  </IonText>
                </IonGrid>
              )}

              {isRoleExist(roleState, [AUTHENTICATION_PRIMARY_CAREGIVER]) && (
                <IonGrid>
                  <IonText className="primary-heading card-title">
                    Manage Classroom
                  </IonText>
                </IonGrid>
              )}

              {isRoleExist(roleState, [AUTHENTICATION_IS_SCHOOL_ADMIN]) && (
                <IonGrid>
                  <IonText className="primary-heading card-title">
                    Manage Schools
                  </IonText>
                </IonGrid>
              )}

              {isRoleExist(roleState, [AUTHENTICATION_IS_COACH]) && (
                <IonGrid>
                  <IonText className="primary-heading card-title">
                    Coaching
                  </IonText>
                </IonGrid>
              )}

              <IonGrid className="ion-padding-top ion-padding-start">
                {routeList?.map((route: any) => (
                  <>
                    {menuList.left?.indexOf(route.id) !== -1 && (
                      <IonRow>
                        <AppButton
                          fill="clear"
                          className="ion-text-capitalize label-dark"
                          buttonText={route.title}
                          onButtonClick={() => redirectTo(route.path)}
                        />
                      </IonRow>
                    )}
                  </>
                ))}
              </IonGrid>
            </IonCol>
            <IonCol className="ion-padding">
              {isRoleExist(roleState, [AUTHENTICATION_IS_PARTNER_ADMIN]) && (
                <>
                  <IonGrid>
                    <IonText className="primary-heading card-title">
                      Partner Resources
                    </IonText>
                  </IonGrid>
                  <IonGrid className="ion-padding-top ion-padding-start">
                    {routeList?.map((route: any) => (
                      <>
                        {menuList.right?.indexOf(route.id) !== -1 && (
                          <IonRow>
                            <AppButton
                              fill="clear"
                              className="ion-text-capitalize label-dark"
                              buttonText={route.title}
                              onButtonClick={() => redirectTo(route.path)}
                            />
                          </IonRow>
                        )}
                      </>
                    ))}
                  </IonGrid>
                </>
              )}

              {isRoleExist(roleState, [AUTHENTICATION_PRIMARY_CAREGIVER]) && (
                <>
                  <IonGrid>
                    <IonText className="primary-heading card-title">
                      Manage Devices
                    </IonText>
                  </IonGrid>
                  <IonGrid className="ion-padding-top ion-padding-start">
                    {routeList?.map((route: any) => (
                      <>
                        {menuList.right?.indexOf(route.id) !== -1 && (
                          <IonRow>
                            <AppButton
                              fill="clear"
                              className="ion-text-capitalize label-dark"
                              buttonText={route.title}
                              onButtonClick={() => redirectTo(route.path)}
                            />
                          </IonRow>
                        )}
                      </>
                    ))}
                  </IonGrid>
                </>
              )}

              {isRoleExist(roleState, [AUTHENTICATION_IS_SCHOOL_ADMIN]) && (
                <>
                  <IonGrid>
                    <IonText className="primary-heading card-title">
                      Admin Resources
                    </IonText>
                  </IonGrid>
                  <IonGrid className="ion-padding-top ion-padding-start">
                    {routeList?.map((route: any) => (
                      <>
                        {menuList.right?.indexOf(route.id) !== -1 && (
                          <IonRow>
                            <AppButton
                              fill="clear"
                              className="ion-text-capitalize label-dark"
                              buttonText={route.title}
                              onButtonClick={() => redirectTo(route.path)}
                            />
                          </IonRow>
                        )}
                      </>
                    ))}
                  </IonGrid>
                </>
              )}

              {isRoleExist(roleState, [AUTHENTICATION_IS_COACH]) && (
                <>
                  <IonGrid>
                    <IonText className="primary-heading card-title">
                      Resource For Coaches
                    </IonText>
                  </IonGrid>
                  <IonGrid className="ion-padding-top ion-padding-start">
                    {routeList?.map((route: any) => (
                      <>
                        {menuList.right?.indexOf(route.id) !== -1 && (
                          <IonRow>
                            <AppButton
                              fill="clear"
                              className="ion-text-capitalize label-dark"
                              buttonText={route.title}
                              onButtonClick={() => redirectTo(route.path)}
                            />
                          </IonRow>
                        )}
                      </>
                    ))}
                  </IonGrid>
                </>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default MyAccount;
