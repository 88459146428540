import React, { useContext, useEffect } from "react";
import {
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonRow,
  IonText,
  useIonAlert,
  useIonLoading,
  useIonToast,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { Device } from "@capacitor/device";

import AutoComplete from "../../components/AutoComplete/AutoComplete";

import Hamburger from "../../components/HamburgerMenu/Hamburger";
import "./ManageDevice.css";
import AddNorthStar from "../../components/AddNorthStar/AddNorthStar";
import { getClassroomById } from "../../Services/Teacher";
import { getAllChildFromClass, getChildDevice } from "../../Services/Child";
import {
  addChildDevice,
  DeviceInfo,
  removeDeviceByChild,
  updateAudioRecordingStatus,
  updateDevice,
} from "../../Services/Device";
import toastConfig from "../../common/toast-config";
import {
  removeDeviceInState,
  updateDeviceState,
} from "../../common/BleConnectionStatus";
import { GlobalContext } from "../../context/Provider";
import {
  BleConnection,
  buzzDevice,
  writeBuzzVolume,
  writeChildId,
  writeCTCGoal,
  writeDeviceName,
  checkPairedState,
  disconnect,
  writeAudioRecording,
  resetAllWiFI,
  writeIntValues,
  readDevice,
} from "ble-library";
import AppInput from "../../components/AppInput/AppInput";
import AppButton from "../../components/AppButton/AppButton";
import { AppLogger } from "../../Services/Logger";
import { pencilOutline } from "ionicons/icons";
import AppSpinner from "../../components/AppSpinner/AppSpinner";
import deviceServices from "ble-library";

const ManageDevice: React.FC = () => {
  const [present, dismiss] = useIonLoading();
  const [selectedClassroom, setSelectedClassroom] = useState<number | null>(
    parseInt(localStorage?.classroom_id, 10)
  );
  const [isChildApiLoading, setChildApiLoading] = useState<boolean>(true);
  const [isDeviceApiLoading, setDeviceApiLoading] = useState<boolean>(false);
  const [childList, setChildList] = useState<any>([]);
  const [selectedChild, setSelectedChild] = useState<any>({});
  const [selectedDevice, setSelectedDevice] = useState<any>({});
  const [isChildVisible, setChildModalVisibility] = useState(false);
  const [deviceInfo, setDeviceInfo] = useState<any>({});
  const [deviceNewName, setDeviceNewName] = useState<string>("");
  const [allowNameEdit, setAllowNameEdit] = useState<boolean>(false);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState<boolean>(false);
  const [isNameUpdating, setIsNameUpdating] = useState<boolean>(false);
  const [classroomGoal, setClassroomGoal] = useState<number>(0);
  const [showToastMessage] = useIonToast();
  const history = useHistory();
  const [create] = useIonAlert();

  const { bleConnectionDispatch, bleConnectionState } =
    useContext(GlobalContext);

  const getDeviceByChild = (childId: number) => {
    setDeviceApiLoading(true);
    getChildDevice(childId)
      .then((res: any) => {
        setDeviceApiLoading(false);
        setDeviceInfo(res?.data);
        if (res?.data?.device_name) {
          setDeviceNewName(res?.data?.device_name);
        }
      })
      .catch(() => {
        setDeviceApiLoading(false);
        setDeviceInfo({});
      });
  };

  const getAllChildren = (id: number) => {
    setChildApiLoading(true);
    getAllChildFromClass(id)
      .then((res: any) => {
        const childList: any = [];
        let childObj: any = {};
        res?.data?.map((child: any) => {
          childObj = {};
          childObj.value = child?.child?.id;
          childObj.text = `${child?.child?.first_name} ${child?.child?.last_name}`;
          childList.push(childObj);
        });
        setChildList(childList);
        setChildApiLoading(false);
      })
      .catch((err: any) => {
        setChildApiLoading(false);
      });
  };

  const getOSVersion = async () => {
    const info = await Device.getInfo();
    console.log("Device Info::", info);

    if (info?.osVersion) {
      localStorage.osVersion = info.osVersion;
    }
  };

  const getClassroomDetailById = (id: number) => {
    getClassroomById(id).then((res: any) => {
      if (res?.data?.classroom?.target_goal) {
        setClassroomGoal(res?.data?.classroom?.target_goal);
      }
    });
  };

  useEffect(() => {
    getOSVersion();
    if (selectedClassroom) {
      getAllChildren(selectedClassroom);
      getClassroomDetailById(selectedClassroom);
    }
  }, [bleConnectionState]);

  const updateConnectionStatus = (status: boolean, device_mac: string) => {
    console.log("Update connection status >>>", device_mac, status);
    bleConnectionState[device_mac].isConnected = status;
    updateDeviceState(bleConnectionState[device_mac], bleConnectionDispatch);
  };

  const removeDeviceInLocalState = (deviceInfo: any) => {
    console.log("Remove device in state >>>>>>>", deviceInfo);
    AppLogger(
      {
        device_id: localStorage.teacher_id,
        mac_address: deviceInfo?.device_reference,
      },
      "Manage_device",
      "info",
      `Start::Remove device ${deviceInfo.device_reference} in local device state`
    );
    if (bleConnectionState[deviceInfo?.device_reference]) {
      removeDeviceInState(deviceInfo?.device_reference, bleConnectionDispatch);
      AppLogger(
        {
          device_id: localStorage.teacher_id,
          mac_address: deviceInfo?.device_reference,
        },
        "Manage_device",
        "info",
        `End::Remove device ${deviceInfo?.device_reference} in local device state`
      );
    }
  };

  // BlePermissions.
  const onDeviceSelect = (device: any) => {
    console.log("Selected device ", device);
    const macAddress = device.mac_address || device.address;
    AppLogger(
      {
        device_id: localStorage.teacher_id,
        mac_address: macAddress,
      },
      "Manage_device",
      "info",
      `On device select:: ${macAddress}`
    );
    present({
      message: `Connecting ${device?.name}`,
    });

    setSelectedDevice(device);
    startBleConnection(device);
  };

  const startBleConnection = (device: any) => {
    BleConnection(
      { address: device.address },
      "connect",
      AppLogger,
      function (response: any) {
        console.log("DP:: Call back response", response);
        AppLogger(
          {
            device_id: localStorage.teacher_id,
            mac_address: device.mac_address || device.address,
          },
          "Manage_device",
          "info",
          `On device connect callback response:: ${JSON.stringify(response)}`
        );
        dismiss();
        if (response?.isConnected) {
          response.mac_address = device.mac_address || device.address;
          checkDevicePairedState(response);
        } else {
          showToastMessage({
            ...toastConfig.error,
            message: `${response?.name || device?.name} disconnected!`,
          } as any);
          updateConnectionStatus(
            false,
            response.mac_address || response.address
          );
        }
      }
    );
  };

  const removePreviousDevice = (child_id: number, device: any) => {
    AppLogger(
      {
        device_id: device?.id,
        mac_address: device.device_reference,
      },
      "Manage_device",
      "info",
      `Removing previous device for new device pairing:: ${JSON.stringify(
        device.device_reference
      )}`
    );
    removeDeviceByChild(child_id).then(
      (response) => {
        if (response?.status === 200) {
          console.log("Remove previous device response::", response);
          AppLogger(
            {
              device_id: localStorage.teacher_id,
              mac_address: "",
            },
            "Manage_device",
            "info",
            `On remove child device when pairing new device:: ${JSON.stringify(
              response
            )}`
          );
          resetAllWiFI(device.device_reference);
          createChildDevice(device);
        }
      },
      (err) => {
        console.log("Child device remove error", err);
        createChildDevice(device);
      }
    );
  };

  const disconnectPreviousDevice = (deviceInfo: any) => {
    console.log(
      "Disconnecting previously connected device::",
      bleConnectionState[deviceInfo?.device_reference].device_reference
    );
    AppLogger(
      {
        device_id: localStorage.teacher_id,
        mac_address: deviceInfo?.device_reference,
      },
      "Manage_device",
      "info",
      `Disconnecting previously connected device::${
        bleConnectionState[deviceInfo?.device_reference].device_reference
      }`
    );
    disconnect(
      bleConnectionState[deviceInfo?.device_reference]?.device_reference,
      AppLogger,
      function (res: any) {
        console.log(
          `Disconnect previously connected device response::${JSON.stringify(
            res
          )}`
        );

        AppLogger(
          {
            device_id: localStorage.teacher_id,
            mac_address: deviceInfo.device_reference,
          },
          "Manage_device",
          "info",
          `Disconnect previously connected device response::${JSON.stringify(
            res
          )}`
        );
        updateConnectionStatus(false, deviceInfo?.device_reference);
      }
    );
  };

  const updateDeviceInfo = (status: boolean, device_id: number) => {
    const device = {
      status: status,
    };
    updateAudioRecordingStatus(device_id, device)
      .then((response) => {
        console.log(
          "Device Pairing:: Audio recording update in back-end response::",
          response
        );

        AppLogger(
          {
            device_id: localStorage.teacher_id,
            mac_address: deviceInfo.device_reference,
          },
          "Manage_device",
          "info",
          `Audio recording status update in back-end response::${JSON.stringify(
            response
          )}`
        );
      })
      .catch((err: any) => {
        console.log("Audio update back-end error::", err);
        AppLogger(
          {
            device_id: localStorage.teacher_id,
            mac_address: deviceInfo.device_reference,
          },
          "Manage_device",
          "info",
          `Audio recording status update in back-end error::${JSON.stringify(
            err
          )}`
        );
      });
  };

  const enableAudioRecording = (deviceInfo: any) => {
    console.log("Write audio recording on device pairing::", 3);
    AppLogger(
      {
        device_id: deviceInfo.id,
        mac_address: deviceInfo.device_reference,
      },
      "Manage_device",
      "info",
      `Start::Update Audio recording in ${deviceInfo?.device_reference}:: ${3}}`
    );
    writeAudioRecording(
      deviceInfo.device_reference,
      3,
      (res: null | number) => {
        AppLogger(
          {
            device_id: deviceInfo.id,
            mac_address: deviceInfo.device_reference,
          },
          "Manage_device",
          "info",
          `End::Update Audio recording response ${
            deviceInfo.device_reference
          }:: ${JSON.stringify(res)}`
        );
        updateDeviceInfo(res === 3, deviceInfo.id);
      }
    );
  };

  const addChildId = (device: any, child: any) => {
    console.log(
      `Write child id (${child}) in device ${device?.device_reference}`
    );

    AppLogger(
      {
        device_id: device?.id,
        mac_address: device?.device_reference,
      },
      "Manage_device",
      "info",
      `Start:: Write child id in device for ${
        device?.device_reference
      }:: ${JSON.stringify(child)}`
    );
    writeChildId(
      device?.device_reference,
      JSON.stringify(child),
      (response: any) => {
        console.log(
          `Write child Id response callback for ${device?.device_reference} is::`,
          response
        );
        AppLogger(
          {
            device_id: device?.id,
            mac_address: device?.device_reference,
          },
          "Manage_device",
          "info",
          `End::Write child id in device response for ${
            device?.device_reference
          }:: ${JSON.stringify(response)}`
        );
      }
    );
  };
  console.log("Device info in manage devices >>>>>>>", deviceInfo);

  const updateBuzzVolume = (device: any) => {
    console.log("Update buzz volume::", device);
    AppLogger(
      {
        device_id: device?.id,
        mac_address: device.device_reference,
      },
      "Manage_device",
      "info",
      `Start::Write buzz volume after pairing ${JSON.stringify(
        device.device_reference
      )}:: ${JSON.stringify(150)}`
    );
    writeBuzzVolume(device.device_reference, 150, (response: any) => {
      AppLogger(
        {
          device_id: device?.id,
          mac_address: device.device_reference,
        },
        "Manage_device",
        "info",
        `End:: Write buzz volume after pairing:: ${JSON.stringify(response)}`
      );
      console.log("Buzz volume write response::", response);

      AppLogger(
        {
          device_id: device?.id,
          mac_address: device.device_reference,
        },
        "Manage_device",
        "info",
        `Buzz paired device`
      );
      buzzConnectedDevice(device.device_reference);
    });
  };

  const checkPreviousConnection = (device: any) => {
    console.log("checkPreviousConnection >>>>>>>>", device);
    console.log("deviceInfo::", deviceInfo);
    // On existing connection
    if (deviceInfo?.id) {
      console.log("Inside device info check >>>>>>>>>>>>");
      if (deviceInfo?.device_reference !== device?.mac_address) {
        const newDevice = {
          mac_address: device.mac_address,
          device_reference: device.address,
          device_name: device.name,
          app_version: localStorage.app_version || "1",
          bluetooth_access: true,
          wifi_access: false,
        };
        if (bleConnectionState[deviceInfo?.device_reference]?.isConnected) {
          AppLogger(
            {
              device_id: deviceInfo?.id,
              mac_address: deviceInfo.device_reference,
            },
            "Manage_device",
            "info",
            `Disconnecting previously paired device for new device pairing:: ${JSON.stringify(
              deviceInfo?.device_reference
            )}`
          );
          disconnectPreviousDevice(deviceInfo);
        }
        setDeviceInfo(newDevice);
        handlePreviousStorage(device, () => {
          removeDeviceInLocalState(deviceInfo);
          removePreviousDevice(selectedChild?.value, newDevice);
        });
      } else {
        console.log("Same device repaired/reconnected >>>>>>>>>>");
        AppLogger(
          {
            device_id: deviceInfo?.id,
            mac_address: deviceInfo.device_reference,
          },
          "Manage_device",
          "info",
          `Same device re-paired (Just connected by selecting):: ${JSON.stringify(
            deviceInfo?.device_reference
          )}`
        );
        updateConnectionStatus(true, device.mac_address || device.address);
      }
    } else {
      if (
        bleConnectionState[device.mac_address || device.address]
          ?.mac_address === device?.mac_address
      ) {
        console.log("Same device repaired/reconnected in state>>>>>>>>>>");
        updateConnectionStatus(true, device.mac_address || device.address);
      }
    }
  };

  const onNewPairing = (device: any) => {
    console.log("onNewPairing >>>>>>>", device);
    AppLogger(
      {
        device_id: localStorage.teacher_id,
        mac_address: device.mac_address || device.address,
      },
      "Manage_device",
      "info",
      `New device pairing:: ${JSON.stringify(
        device.mac_address || device.address
      )}`
    );
    //On new pairing
    showToastMessage({
      ...toastConfig.success,
      message: `Successfully connected to ${device?.name}.`,
    } as any);

    const newDevice = {
      mac_address: device.mac_address,
      device_reference: device.address,
      device_name: device.name,
      app_version: localStorage.app_version || "1",
      bluetooth_access: true,
      wifi_access: false,
    };
    handlePreviousStorage(device, () => {
      createChildDevice(newDevice);
    });
  };

  const showFunctionTriggerAlert = (macAddress: string, callback: any) => {
    create({
      message:
        "There is existing data on this device that will be erased. To upload and save the data, plug your Luet to charge.",
      buttons: [
        {
          text: "Discard",
          role: "close",
          handler: () => {
            disconnect(macAddress, AppLogger, function (res: any) {
              console.log(`Disconnected >>>>>>>>>>>>>>> ${res}`);
            });
            history.push("/home");
          },
        },
        {
          text: "Continue",
          handler: () => {
            writeFunctionTrigger(macAddress, callback);
          },
        },
      ],
    });
  };

  const onDevicePaired = (device: any) => {
    console.log("onDevicePaired >>>>>>", device);
    console.log(
      "bleConnectionState[device?.mac_address] >>>>>>",
      bleConnectionState[device?.mac_address]
    );

    console.log("Selected child ID >>>>>>>>", selectedChild?.value);
    console.log("Device Info >>>>>>>", deviceInfo);

    // To check if the device is already paired with the child
    if (!deviceInfo.id) {
      onNewPairing(device);
    } else {
      checkPreviousConnection(device);
    }
  };

  const handlePreviousStorage = (device: any, callback: any) => {
    readSDCardState(
      device?.mac_address || device.address,
      (usedStorage: number) => {
        if (
          usedStorage !== undefined &&
          usedStorage !== null &&
          usedStorage <= 64
        ) {
          callback && callback();
        } else if (
          usedStorage !== undefined &&
          usedStorage !== null &&
          usedStorage > 64
        ) {
          showFunctionTriggerAlert(
            device?.mac_address || device.address,
            () => {
              callback && callback();
            }
          );
        } else {
          disconnect(
            device?.mac_address || device.address,
            AppLogger,
            function (res: any) {
              console.log(`Disconnected >>>>>>>>>>>>>>> ${res}`);
            }
          );
          history.push("/home");
        }
      }
    );
  };

  const readSDCardState = (mac_address: string, callback: any) => {
    console.log("readSDcardState", mac_address);
    AppLogger(
      { device_id: mac_address },
      "MyDevice",
      "info",
      `Start:: Read SD card used storage`
    );
    readDevice(
      mac_address,
      deviceServices.luetInfo.service,
      deviceServices.luetInfo.sdCardLevel,
      (res: number, err: any) => {
        if (res !== null && res !== undefined) {
          console.log("SD Card total storage response::", res);
          AppLogger(
            { device_id: mac_address },
            "MyDevice",
            "info",
            `End:: Read SD card used storage response:: ${JSON.stringify(res)} `
          );
          readSDCardFreeStorage(res, mac_address, callback);
        } else if (err) {
          console.log("SD Card total response error::", err);
          AppLogger(
            { device_id: mac_address },
            "MyDevice",
            "error",
            `End:: Read SD card used storage error:: ${JSON.stringify(
              err?.message
            )} `
          );
        }
      }
    );
  };

  const readSDCardFreeStorage = (
    totalStorage: number,
    mac_address: string,
    callback: any
  ) => {
    console.log("readSDCardFreeStorage");
    AppLogger(
      { device_id: mac_address },
      "MyDevice",
      "info",
      `Start:: Read SD card free storage`
    );
    readDevice(
      mac_address,
      deviceServices.luetInfo.service,
      deviceServices.luetInfo.sdCardFree,
      (res: number, err: any) => {
        if (res !== null && res !== undefined) {
          console.log("SD Card free storage::", res);
          AppLogger(
            { device_id: mac_address },
            "MyDevice",
            "info",
            `End:: Read SD card free storage response (parsed):: ${JSON.stringify(
              res
            )} `
          );
          callback && callback(convertKbToMb(totalStorage - res));
        } else if (err) {
          console.log("SD Card free response error::", err);
          AppLogger(
            {},
            "MyDevice",
            "error",
            `End:: Read SD card free storage error:: ${JSON.stringify(
              err?.message
            )} `
          );
        }
      }
    );
  };

  const convertKbToMb = (value: number) => {
    console.log("used memory in kb", value);
    return Math.round(value / 1000);
  };

  const writeFunctionTrigger = (mac_address: string, callback: any) => {
    console.log("readSDCardFreeStorage");
    AppLogger(
      { device_id: mac_address },
      "MyDevice",
      "info",
      `Start:: Write function trigger erase child data`
    );
    writeIntValues(
      mac_address,
      deviceServices.luetInfo.service,
      deviceServices.luetInfo.func_trig,
      6,
      (res: any, err: any) => {
        console.log("Write device function trigger response::", res);
        console.log("Write device function trigger error::", err);
        AppLogger(
          { device_id: mac_address },
          "MyDevice",
          "info",
          `End:: Read function trigger for erase child data response:: ${JSON.stringify(
            res || err
          )}`
        );
        if (res?.status === "written") {
          callback && callback();
        }

        if (err?.message === "Characteristic not found") {
          console.log(
            "Device does not have erase child data characteristic. Skipping"
          );
          AppLogger(
            { device_id: mac_address },
            "MyDevice",
            "info",
            `End:: Device does not have erase child data characteristic. Skipping`
          );
          callback && callback();
        }
      }
    );
  };

  const checkDevicePairedState = (device: any) => {
    checkPairedState(device?.address, AppLogger, function (res: any) {
      console.log("Paired State::", res);
      AppLogger(
        {
          device_id: localStorage.teacher_id,
          mac_address: device.mac_address || device.address,
        },
        "Manage_device",
        "info",
        `Check device paired state response:: ${JSON.stringify(res)}`
      );
      if (res?.isPaired) {
        console.log("Device paired");
        dismiss();
        setIsSearchModalOpen(false);
        onDevicePaired(device);
      } else {
        disconnect(device.address, AppLogger, function (res: any) {
          console.log("Disconnected");
          AppLogger(
            {
              device_id: localStorage.teacher_id,
              mac_address: device.mac_address || device.address,
            },
            "Manage_device",
            "error",
            `Device not paired. Disconnected:: ${JSON.stringify(res)}`
          );
          updateConnectionStatus(false, device.mac_address || device.address);
          dismiss();
        });
      }
    });
  };

  const buzzConnectedDevice = (address: string) => {
    console.log("Buzz connected device::", address);
    buzzDevice(address, 1);
    setTimeout(() => {
      // Turn off buzz device after 3 seconds
      buzzDevice(address, 0);
    }, 3000);
  };

  const setDeviceInState = (deviceInfo: any) => {
    console.log("Create device in state::", deviceInfo);
    if (!bleConnectionState[deviceInfo.device_reference]) {
      bleConnectionState[deviceInfo.device_reference] = {
        child_id: selectedChild?.value,
        device_id: deviceInfo?.id,
        device_reference: deviceInfo.device_reference,
        device_name: deviceInfo.device_name,
        status: "connected",
        mac_address: deviceInfo.device_reference,
        isConnected: true,
      };
      console.log(
        "On set device status::",
        bleConnectionState[deviceInfo.device_reference]
      );
      updateDeviceState(
        bleConnectionState[deviceInfo.device_reference],
        bleConnectionDispatch
      );
    }
  };

  const onDeviceSave = () => {
    create({
      subHeader: `You’ve successfully assigned a device to ${selectedChild.text}. `,
      message:
        "Do you want to assign Luet devices to the other child/children you recently added?",
      buttons: [
        {
          text: "Not Now",
          role: "close",
          handler: () => {
            history.push("/home");
          },
        },
        {
          text: "Assign for others",
          handler: () => {
            setSelectedChild({});
            setDeviceInfo({});
          },
        },
      ],
    });
  };

  const writeGoal = (device: any) => {
    AppLogger(
      {
        device_id: device?.id,
        mac_address: device.device_reference,
      },
      "Manage_device",
      "error",
      `Start:: Write CTC goal after pairing`
    );
    if (classroomGoal) {
      writeCTCGoal(device?.device_reference, classroomGoal, (response: any) => {
        console.log("Goal update response::", response);
        AppLogger(
          {
            device_id: device?.id,
            mac_address: device.device_reference,
          },
          "Manage_device",
          "error",
          `End:: Write CTC goal after pairing response:: ${JSON.stringify(
            response
          )}`
        );
      });
    }
  };

  const resetWiFi = (device: DeviceInfo) => {
    AppLogger(
      {
        device_id: device?.id,
        mac_address: device.device_reference,
      },
      "Manage_device",
      "info",
      `Resetting WiFi slots`
    );
    resetAllWiFI(device, AppLogger);
  };

  const createChildDevice = (device: any) => {
    console.log("Create new device for the child::", device);
    AppLogger(
      {
        device_id: localStorage.teacher_id,
        mac_address: device.mac_address || device.address,
      },
      "Manage_device",
      "error",
      `Start:: Creating new device for ${JSON.stringify(
        device.mac_address || device.address
      )}`
    );
    device.device_reference = device.mac_address;
    addChildDevice(selectedChild.value, device)
      .then((res: any) => {
        console.log("Device saved response", res);
        AppLogger(
          {
            device_id: localStorage.teacher_id,
            mac_address: device.mac_address || device.address,
          },
          "Manage_device",
          "error",
          `End:: Creating new device for ${JSON.stringify(
            device.mac_address || device.address
          )} response code:: ${JSON.stringify(res?.status)}`
        );
        if (res?.status === 201) {
          setDeviceInfo(res?.data);
          setDeviceInState(res?.data);
          enableAudioRecording(res?.data);
          if (selectedChild?.value) {
            addChildId(res?.data, selectedChild?.value);
          }
          writeGoal(res?.data);
          updateBuzzVolume(res?.data);
          resetWiFi(res?.data);
          onDeviceSave();
        }
      })
      .catch((err: any) => {
        console.log("Device save error::", err);
      });
  };

  const updateNameInDevice = (name: string) => {
    AppLogger(
      {
        device_id: deviceInfo.id,
        mac_address: deviceInfo.device_reference,
      },
      "Manage_device",
      "info",
      `Start::Write device name response:: ${name}`
    );
    writeDeviceName(deviceInfo?.device_reference, name, (response: any) => {
      console.log("Write device name reponse::", response);
      AppLogger(
        {
          device_id: deviceInfo?.id,
          mac_address: deviceInfo?.device_reference,
        },
        "Manage_device",
        "info",
        `End::Write device name response:: ${response}`
      );
    });
  };

  const renameDevice = () => {
    console.log("Rename device", deviceNewName);
    updateNameInDevice(deviceNewName);
    deviceInfo.device_name = deviceNewName;
    updateDevice(deviceInfo?.id, deviceInfo)
      .then((res: any) => {
        console.log("Device saved", res);
        if (res?.status === 200) {
          setDeviceInfo(res?.data);
          showToastMessage({
            ...toastConfig.success,
            message: "Device name updated successfully!",
          } as any);
          setAllowNameEdit(false);
          setIsNameUpdating(false);
        }
      })
      .catch((err: any) => {
        console.log("Name update error::", err);
        setIsNameUpdating(false);
      });
  };
  console.log(
    "Ble connection state in manage devices >>>>>>>>>>>>>",
    bleConnectionState
  );

  return (
    <IonPage>
      <Hamburger></Hamburger>
      <IonContent fullscreen>
        <div className="helpera">
          <div className="contenta">
            <IonGrid className="content-container m-t-lg">
              <IonRow className="ion-justify-content-center">
                <IonText className="primary-heading">
                  Manage Luet Assignments
                </IonText>
              </IonRow>

              <IonList>
                {!isChildApiLoading && (
                  <IonItem className="m-t-1">
                    <IonInput
                      type="text"
                      label="Child name"
                      labelPlacement="floating"
                      value={selectedChild?.text}
                      onClick={() => {
                        setChildModalVisibility(true);
                      }}
                    ></IonInput>
                  </IonItem>
                )}

                {isChildApiLoading && <AppSpinner />}
                {!isDeviceApiLoading && (
                  <>
                    {selectedChild?.value && deviceInfo?.device_name && (
                      <IonItem detail={false} className="m-t-1">
                        <IonInput
                          type="text"
                          label="Current Luet"
                          labelPlacement="floating"
                          readonly={true}
                          value={deviceInfo?.device_name}
                        ></IonInput>
                      </IonItem>
                    )}

                    {selectedChild?.value && !deviceInfo?.device_name && (
                      <IonItem detail={false} className="m-t-1">
                        <IonLabel>Current Luet </IonLabel>
                        <IonLabel className="ion-text-end">
                          No Luet assigned
                        </IonLabel>
                      </IonItem>
                    )}

                    {selectedChild?.value && (
                      <IonRow className="ion-justify-content-center  ion-align-items-center ion-padding-start ion-padding-top">
                        <IonCol className="ion-no-padding">
                          <IonLabel>New Luet </IonLabel>
                        </IonCol>
                        <IonCol className="ion-no-padding ion-text-end">
                          <AppButton
                            fill="outline"
                            buttonText="Search nearby Luet DEVICES"
                            onButtonClick={() => {
                              setSelectedDevice({});
                              setIsSearchModalOpen(true);
                            }}
                          />
                        </IonCol>
                      </IonRow>
                    )}

                    {selectedChild?.value &&
                      deviceInfo?.id &&
                      deviceInfo.device_name && (
                        <>
                          {allowNameEdit && (
                            <AppInput
                              label="Rename Luet Device"
                              value={deviceNewName}
                              onInputChange={(value: any) => {
                                setDeviceNewName(value);
                              }}
                            ></AppInput>
                          )}

                          {!allowNameEdit && (
                            <IonItem
                              detail={false}
                              lines="none"
                              className="ion-paddisng-top no-inner-padding m-t-1"
                            >
                              <IonCol className="ion-no-padding">
                                <IonText>Luet Device</IonText>
                              </IonCol>
                              <IonCol className="ion-text-end ion-no-padding">
                                <IonText>{deviceInfo.device_name}</IonText>
                                <IonIcon
                                  className="ion-padding-start"
                                  icon={pencilOutline}
                                  onClick={() => setAllowNameEdit(true)}
                                ></IonIcon>
                              </IonCol>
                            </IonItem>
                          )}
                        </>
                      )}

                    {allowNameEdit && deviceNewName?.length > 0 && (
                      <IonRow className="ion-justify-content-center ion-margin-top">
                        <AppButton
                          className="width-35 ion-margin-top"
                          buttonText="Rename Luet"
                          isLoading={isNameUpdating}
                          disabled={
                            !deviceInfo?.device_name ||
                            isNameUpdating ||
                            deviceInfo.device_name === deviceNewName
                          }
                          onButtonClick={() => renameDevice()}
                        />
                      </IonRow>
                    )}
                  </>
                )}
                {isDeviceApiLoading && <AppSpinner />}
              </IonList>
            </IonGrid>
          </div>
        </div>
      </IonContent>
      <IonModal
        isOpen={isChildVisible}
        onDidDismiss={() => setChildModalVisibility(false)}
      >
        <AutoComplete
          title="Enter Child"
          notFoundText="No child found!"
          items={childList}
          selectedItem={selectedChild}
          onSelectionCancel={() => {
            setChildModalVisibility(false);
          }}
          onSelectionChange={(e: any) => {
            setSelectedChild(e);
            setChildModalVisibility(false);
            getDeviceByChild(e?.value);
          }}
        />
      </IonModal>

      <IonModal
        className="auto-height"
        isOpen={isSearchModalOpen}
        onDidDismiss={() => setIsSearchModalOpen(false)}
      >
        <AddNorthStar
          onDeviceSelect={onDeviceSelect}
          closeModal={() => setIsSearchModalOpen(false)}
        ></AddNorthStar>
      </IonModal>
    </IonPage>
  );
};

export default ManageDevice;
