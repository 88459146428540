import { add, format, startOfMonth, subMonths } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

const getTimeZone = () => {
  if (localStorage.time_zone !== "null" && localStorage.time_zone) {
    return localStorage.time_zone;
  }
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const getCurrentHour = () => {
  return utcToZonedTime(new Date(), getTimeZone()).getHours();
};

export const getUTCCurrentHour = () => {
  return utcToZonedTime(new Date(), getTimeZone()).getHours();
};

export const getUTCDateTime = (date: string) => {
  let formattedDate = "";
  const parseddate = zonedTimeToUtc(date, getTimeZone()).toISOString();
  if (parseddate) {
    const date = parseddate?.split("T");
    formattedDate = date[0];
    const parsedHours = date[1].split(":");
    formattedDate = `${formattedDate} ${parsedHours[0]}:${parsedHours[1]}:00`;
    return formattedDate;
  }
  return;
};

export const getCurrentDate = () => {
  return utcToZonedTime(new Date(), getTimeZone());
};

export const getCurrentFullDate = () => {
  return format(
    utcToZonedTime(new Date(), getTimeZone()),
    "yyyy-MM-dd HH:mm:ss"
  );
};

export const getCurrentHourFullDate = () => {
  return `${format(
    utcToZonedTime(new Date(), getTimeZone()),
    "yyyy-MM-dd HH"
  )}:00:00`;
};

export const getCurrentDateInFormat = () => {
  return format(utcToZonedTime(new Date(), getTimeZone()), "yyyy-MM-dd");
};

export const getDateByTimeZone = (date: any) => {
  return zonedTimeToUtc(new Date(`${date}T00:00:00`), getTimeZone());
};

export const getCurrentTimeInMinutes = () => {
  const hour = utcToZonedTime(new Date(), getTimeZone()).getHours();
  const minutes = utcToZonedTime(new Date(), getTimeZone()).getMinutes();
  return hour * 60 + (minutes === 0 ? 0 : minutes - 1);
};

export const getSubtractedMonthStartDate = (subCount: number) => {
  return format(startOfMonth(subMonths(new Date(), subCount)), "yyyy-MM-dd");
};

export const getYearByCount = (addCount: number) => {
  return format(add(new Date(), {
    years: addCount,
  }), "yyyy-MM-dd")
};

