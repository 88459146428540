import React, { FC } from "react";
import { IonRow, IonSpinner } from "@ionic/react";

const AppSpinner = () => (
  <IonRow className="ion-justify-content-center mt-lg">
    <IonSpinner name="crescent" className="ion-margin" />
  </IonRow>
);

export default AppSpinner;
