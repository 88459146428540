import axiosInstance from "../helper/axios";
export type DeviceInfo = {
  id: number;
  device_reference: string;
  device_name: string;
  bluetooth_access: boolean;
  wifi_access: boolean;
  last_synced_at: string;
  app_version: string;
  firmware_updated_on: string;
  tf_version: string;
  audio_record: boolean;
  audio_record_time: string;
  hw_version: number;
  firmware: number;
};

export const addChildDevice = async (child_id: number, device: any) => {
  return await axiosInstance().post(`/device/create/${child_id}`, device);
};

export const updateDevice = async (device_id: number, device: any) => {
  return await axiosInstance().put(`/device/${device_id}`, device);
};

export const updateDeviceAudioRecStatus = async (
  device_id: number,
  device: any
) => {
  return await axiosInstance().put(
    `/device/record/status/${device_id}`,
    device
  );
};

export const getWiFiStatus = async (classroom: number) => {
  return await axiosInstance().get(`/educator/device/wifi_status/${classroom}`);
};

export const getAllFirmwareVersionByHardware = async (hardware: number) => {
  return await axiosInstance().get(`/device/firmware/all/${hardware}`);
};

export const getFirmwareDetail = async (firmware: number, hardware: number) => {
  return await axiosInstance().get(
    `/device/firmware/version/${firmware}/${hardware}`
  );
};

export const getFirmwareVersionById = async (
  firmware: number,
  hardware: number
) => {
  return await axiosInstance().get(`/device/firmware/${firmware}/${hardware}`);
};

export const updateIssueAsNotified = async (issue_id: number) => {
  return await axiosInstance().put(`/device/issue/notified/${issue_id}`);
};

export const removeDeviceByChild = async (childId: number) => {
  return await axiosInstance().delete(`child/device/${childId}`);
};

export const updateAudioRecordingStatus = async (
  device_id: number,
  data: any
) => {
  return await axiosInstance().put(`/device/record/status/${device_id}`, data);
};
