interface SubMenu {
  id?: string;
  title: string;
  path: string;
  is_modal_route?: boolean;
}

interface Routes {
  id: string;
  title: string;
  path?: string;
  icon: string;
  subMenu?: SubMenu[];
}

const menuList: Routes[] = [
  {
    id: "home",
    title: "Home",
    path: "/home",
    icon: "assets/home.svg",
  },
  {
    id: "myAccount",
    title: "My Account",
    path: "/my-account",
    icon: "assets/my_account.svg",
    subMenu: [
      {
        title: "Assign Luets",
        path: "/device/manage-northstar",
      },
      {
        title: "Wi-Fi Setup",
        path: "/device/wifi-setup",
      },
      {
        title: "Manage Luets",
        path: "/device/update",
      },
      {
        title: "Manage Children",
        path: "/account/add-children",
      },
      {
        id: "appInfo",
        title: "App Info",
        path: "#",
        is_modal_route: true,
      },
    ],
  },
];

export default menuList;
