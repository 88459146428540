import React, { useContext } from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonMenuButton,
  IonModal,
  IonPopover,
  IonToolbar,
  useIonToast,
} from "@ionic/react";
import {
  chevronDownOutline,
  lockClosedSharp,
  lockOpenSharp,
  notifications,
} from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import { useLocation, useHistory } from "react-router";
import { Capacitor } from "@capacitor/core";

import menuList from "../../routes/Routes";
import "./Hamburger.css";
import Notifications from "../Notifications/Notifications";
import {
  checkBLEPermission,
  checkLocationPermission,
} from "../../common/BlePermissions";
import { GlobalContext } from "../../context/Provider";
import {
  BLE_TURNED_OFF,
  BLE_TURNED_ON,
  LOCATION_TURNED_OFF,
  LOCATION_TURNED_ON,
} from "../../constants";
import {
  getAppInfo,
  isAccountSetupComplete,
  isRoleExist,
} from "../../common/Util";
import {
  AUTHENTICATION_IS_COACH,
  AUTHENTICATION_PRIMARY_CAREGIVER,
} from "../../constants/permissions";
import {
  setConnectionStatus,
  updateDeviceState,
} from "../../common/BleConnectionStatus";
import { AppLogger } from "../../Services/Logger";
import { writeDeviceLock, disconnect } from "ble-library";
import toastConfig from "../../common/toast-config";
import Info from "../Info/Info";

interface SubMenu {
  title: string;
  path: string;
}

interface Routes {
  id: string;
  title: string;
  path?: string;
  icon: string;
  subMenu?: SubMenu[];
}
const Hamburger: React.FC = () => {
  const [showMainMenu, setShowMainMenu] = useState<boolean>(
    isAccountSetupComplete()
  );
  const [activeMenu, setActiveMenu] = useState();
  const [showNotificationModal, setShowNotificationsModal] =
    useState<boolean>(false);
  const [isAudioRecEnabled, setAudioRecEnabled] = useState<any>(false);
  const [isDeviceLocked, setDeviceLocked] = useState<any>(false);
  const [isDeviceConnected, setDeviceConnectedStatus] =
    useState<boolean>(false);
  const [showAppInfoModal, setShowAppInfoModal] = useState<boolean>(false);
  const [appInfo, setAppInfo] = useState<any>([]);
  const popoverRef = useRef<any>();
  const location = useLocation();
  const history = useHistory();
  const [present] = useIonToast();
  const {
    bleConnectionState,
    bleConnectionDispatch,
    permissionState,
    permissionDispatch,
    roleState,
  } = useContext(GlobalContext);
  const isNativePlatform = Capacitor.isNativePlatform();
  let connectedCount = 0;
  let lockedDeviceCount = 0;

  const onMouseLeave = () => {
    popoverRef?.current.dismiss();
  };

  const checkAudioRecordingStatus = () => {
    console.log("Checking audio and locked status in hamburger");
    connectedCount = 0;
    lockedDeviceCount = 0;
    if (Object.keys(bleConnectionState)?.length) {
      setDeviceConnectedStatus(false);
      setAudioRecEnabled(false);
      setDeviceLocked(false);
      Object.keys(bleConnectionState).map((device: any, index: number) => {
        if (bleConnectionState[device].isConnected) {
          connectedCount += 1;
          setDeviceConnectedStatus(true);
        }

        if (bleConnectionState[device].isConnected) {
          if (bleConnectionState[device].is_locked) {
            lockedDeviceCount += 1;
          }
        }

        if (connectedCount === lockedDeviceCount) {
          setDeviceLocked(true);
        } else if (
          Object.keys(bleConnectionState)?.length - 1 === index &&
          connectedCount !== lockedDeviceCount
        ) {
          setDeviceLocked(false);
        }
      });
      console.log("Connected devices count::", connectedCount);
      console.log("Locked devices count::", lockedDeviceCount);
    } else {
      setAudioRecEnabled(false);
      setDeviceLocked(false);
    }
  };

  useEffect(() => {
    checkAudioRecordingStatus();
  }, [bleConnectionState]);

  const redirectTo = (path: string) => {
    history.push(path);
  };

  const checkSubMenu = (subMenuList: any, currentPath: any, cb: any) => {
    const menu = subMenuList.filter((menu: any) => {
      return menu?.path === currentPath;
    });
    cb(menu);
  };

  const getActiveMenu = (menuList: any, currentPath: any) => {
    let activeMenu;
    menuList.forEach((menu: any) => {
      if (menu.subMenu?.length) {
        checkSubMenu(menu.subMenu, currentPath, (res: any) => {
          if (res?.length && res[0].path === currentPath) {
            activeMenu = menu.id;
          }
        });
      } else {
        if (menu?.path === currentPath) {
          activeMenu = menu.id;
        }
      }
    });
    return activeMenu;
  };

  const getAppInfoOnInit = async () => {
    const appInfo = await getAppInfo();
    console.log("====App infooo", appInfo);
    if (appInfo?.length) {
      setAppInfo(appInfo);
    }
  };

  useEffect(() => {
    setActiveMenu(getActiveMenu(menuList, location.pathname));
    window.addEventListener("storage", () => {
      setShowMainMenu(isAccountSetupComplete());
    });
  }, [menuList]);

  useEffect(() => {
    if (isNativePlatform) {
      checkLocationPermission((isEnabled: any) => {
        if (isEnabled) {
          permissionDispatch(LOCATION_TURNED_ON);
        } else {
          permissionDispatch(LOCATION_TURNED_OFF);
        }
      });

      checkBLEPermission((isEnabled: any) => {
        if (isEnabled) {
          permissionDispatch(BLE_TURNED_ON);
        } else {
          permissionDispatch(BLE_TURNED_OFF);
        }
      });
    }
    getAppInfoOnInit();
  }, []);

  const presentSuccessMessage = (message: string) => {
    present({
      ...toastConfig.success,
      message,
    });
  };

  const showDeviceLockAlert = (status: number) => {
    const message =
      status === 0
        ? "Luet devices are unlocked. It can be turned off using the touch button."
        : "Luet devices are locked. It cannot be turned off using the touch button.";

    presentSuccessMessage(message);
  };

  const performDeviceLock = (lockState: number) => {
    Object.keys(bleConnectionState).map((device: any) => {
      if (
        bleConnectionState[device] &&
        bleConnectionState[device]?.isConnected
      ) {
        AppLogger(
          {
            device_id: bleConnectionState[device]?.device_id,
            mac_address: bleConnectionState[device]?.mac_address,
          },
          "Hamburger",
          "info",
          `Start::Update lock status:: ${lockState}`
        );

        writeDeviceLock(
          bleConnectionState[device]?.device_reference,
          lockState,
          (response: any) => {
            AppLogger(
              {
                device_id: bleConnectionState[device]?.device_id,
                mac_address: bleConnectionState[device]?.mac_address,
              },
              "Hamburger",
              "info",
              `End::Update lock status response:: ${JSON.stringify(response)}`
            );
            console.log("Lock selected response:: >>>", response);
            bleConnectionState[device].is_locked = response === 1;
            setConnectionStatus(bleConnectionState, bleConnectionDispatch);
            setDeviceLocked(response === 1);
            showDeviceLockAlert(response);
          }
        );
      }
    });
  };

  const handleUserLogout = () => {
    Object.keys(bleConnectionState).map((device: any) => {
      if (bleConnectionState[device]?.isConnected) {
        disconnect(
          bleConnectionState[device]?.device_reference,
          AppLogger,
          function (res: any) {
            bleConnectionState[device].isConnected = false;
            updateDeviceState(
              bleConnectionState[device],
              bleConnectionDispatch
            );
          }
        );
      }
    });
    localStorage.clear();
    window.location.replace('/');
  };

  const openModal = (menu: any) => {
    if (menu?.id === "appInfo") {
      setShowAppInfoModal(true);
    }
  };

  return (
    <>
      <IonHeader mode="md">
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton menu="app-menu" color="primary">
              <IonIcon color="primary" src="assets/menu.svg"></IonIcon>
            </IonMenuButton>
          </IonButtons>
          <IonButtons className="ion-hide-lg-down hamburger-btn">
            {showMainMenu &&
              menuList?.map((menu: any, index: number) => {
                return menu?.subMenu?.length > 0 ? (
                  <div key={index}>
                    <IonButton
                      id={menu?.id}
                      className={`ion-text-capitalize hamburger-btn primary-heading ${
                        activeMenu === menu.id ? "hamburger-active" : ""
                      }`}
                      key={menu.id}
                    >
                      {menu.title}
                      <IonIcon
                        icon={chevronDownOutline}
                        className="p-5"
                      ></IonIcon>
                    </IonButton>
                    <IonPopover
                      ref={popoverRef}
                      trigger={menu.id}
                      dismissOnSelect={true}
                      showBackdrop={false}
                      className="hamburger-popover"
                      triggerAction="click"
                    >
                      <IonContent>
                        <IonList
                          className="ion-no-padding"
                          onMouseLeave={() => onMouseLeave()}
                        >
                          {menu?.subMenu?.map((subMenu: any, index: number) => {
                            return (
                              <IonItem
                                key={index}
                                detail={false}
                                lines="none"
                                className={`popover-item primary font-heading cursor-pointer ${
                                  location.pathname === subMenu.path
                                    ? "hamburger-active"
                                    : ""
                                }`}
                                onClick={() => {
                                  if (subMenu?.is_modal_route) {
                                    openModal(subMenu);
                                  } else {
                                    redirectTo(subMenu.path);
                                  }
                                }}
                              >
                                {subMenu?.title}
                              </IonItem>
                            );
                          })}
                        </IonList>
                      </IonContent>
                    </IonPopover>
                  </div>
                ) : (
                  <IonButton
                    className={`font-heading  ion-text-capitalize hamburger-btn primary-heading ${
                      activeMenu === menu.id ? "hamburger-active" : ""
                    }`}
                    key={menu.path}
                    onClick={() => {
                      redirectTo(menu.path);
                    }}
                  >
                    {menu.title}
                  </IonButton>
                );
              })}
            <IonButton
              className="ion-text-capitalize hamburger-btn primary-heading"
              key="logout"
              onClick={() => {
                handleUserLogout();
              }}
            >
              Logout
            </IonButton>
          </IonButtons>
          {isRoleExist(roleState, [
            AUTHENTICATION_PRIMARY_CAREGIVER,
            AUTHENTICATION_IS_COACH,
          ]) && (
            <IonButtons slot="end" className="ion-align-items-center">
              {isDeviceConnected && isDeviceLocked && (
                <IonIcon
                  className="icon-power"
                  color="primary"
                  icon={lockClosedSharp}
                  onClick={() => performDeviceLock(0)}
                />
              )}
              {isDeviceConnected && !isDeviceLocked && (
                <IonIcon
                  className="icon-power"
                  color="medium"
                  icon={lockOpenSharp}
                  onClick={() => performDeviceLock(1)}
                />
              )}
              <IonIcon
                className="icon-font cursor-pointer font-22 ion-padding-end"
                color={
                  !permissionState.bleTurnedOn ||
                  !permissionState.locationTurnedOn
                    ? "primary"
                    : "medium"
                }
                icon={notifications}
                onClick={() => {
                  setShowNotificationsModal(true);
                }}
              />
            </IonButtons>
          )}
        </IonToolbar>
        {showNotificationModal && (
          <Notifications
            open={showNotificationModal}
            onClose={() => setShowNotificationsModal(false)}
          ></Notifications>
        )}
      </IonHeader>
      {showAppInfoModal && (
        <IonModal
          isOpen={true}
          onDidDismiss={() => setShowAppInfoModal(false)}
          className="info-modal "
        >
          <Info
            title="App Info"
            info={appInfo}
            column_size={6}
            onclose={() => setShowAppInfoModal(false)}
          ></Info>
        </IonModal>
      )}
    </>
  );
};

export default Hamburger;
