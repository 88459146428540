import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonModal,
  IonPage,
  IonRow,
  IonText,
  useIonToast,
} from "@ionic/react";
import { useHistory, useLocation } from "react-router";
import "./ResetPassword.css";
import {
  requestResetPassword,
  resetPassword,
  verifyToken,
} from "../../Services/User";
import toastConfig from "../../common/toast-config";
import { eyeOffOutline, eyeOutline } from "ionicons/icons";
import BackButton from "../../components/BackButton/BackButton";
import AppButton from "../../components/AppButton/AppButton";
import { validateEmailFormat } from "../../common/Util";
import AppInput from "../../components/AppInput/AppInput";

type PasswordForm = {
  password: string;
  confirmPassword: string;
};

const ResetPassword: React.FC = () => {
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [form, setForm] = useState<PasswordForm>({
    password: "",
    confirmPassword: "",
  });
  const [showToast] = useIonToast();
  const [isTokenVerified, setTokenVerified] = useState(false);
  const [isTokenValid, setTokenValid] = useState(false);
  const [showPassword1, setPassword1Visibility] = useState(false);
  const [showPassword2, setPassword2Visibility] = useState(false);
  const [isShowModal, setModalVisibility] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
  const history = useHistory();

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const queryString = useQuery();
  const uidb64 = queryString.get("uidb64") || "";
  const token = queryString.get("token") || "";

  useEffect(() => {
    setTokenVerified(false);
    verifyToken(uidb64, token)
      .then((res) => {
        if (res?.data === 0) {
          setTokenValid(true);
        } else {
          setTokenValid(false);
        }
        setTokenVerified(true);
      })
      .catch((err) => {
        setTokenValid(false);
      });
  }, [token]);

  const onSubmit = () => {
    setIsloading(true);
    resetPassword(form.password, token, uidb64)
      .then((res: any) => {
        if (res?.status === 201) {
          showToast({
            ...toastConfig.success,
            message: res.data,
          });
          localStorage.clear();
          history.push("/");
        }
        setIsloading(false);
      })
      .catch((error: any) => {
        console.log("error", error);
        if (error?.response?.data?.message?.password?.length) {
          showToast({
            ...toastConfig.error,
            message: error?.response?.data?.message?.password[0],
          });
        } else {
          showToast({
            ...toastConfig.error,
            message: "Failed to set password. Please try again later!",
          });
        }
        setIsloading(false);
      });
  };

  const validateForm = () => {
    return (
      form.password?.length < 6 ||
      form.password?.length > 30 ||
      form.confirmPassword?.length < 1 ||
      form.confirmPassword?.length > 30 ||
      form.password !== form.confirmPassword
    );
  };

  const generateNewLink = (email: string) => {
    requestResetPassword({ email })
      .then(
        (response: any) => {
          if (response?.status === 201) {
            showToast({
              ...toastConfig.success,
              message: response.data,
            });
          }
        },
        (error: any) => {
          if (error?.response?.data?.message) {
            showToast({
              ...toastConfig.error,
              message: error?.response?.data?.message?.email[0],
            });
          }
        }
      )
      .catch((err: any) => console.log(err));
  };

  const validateEmail = (email: string) => {
    if (validateEmailFormat(email)) {
      setIsEmailValid(true);
    } else {
      setIsEmailValid(false);
    }
  };

  return (
    <IonPage>
      <BackButton></BackButton>
      <IonContent force-overscroll="false">
        <div className="helper">
          <div className="content reset-pwd-container">
            <IonGrid className="content-contdainer ion-margin-vertical ion-padding-vertical">
              <div className="logo-section  m-b-5e">
                <IonImg
                  className="logo"
                  alt="app-logo"
                  src="/assets/logo.png"
                />
              </div>
              {isTokenVerified && isTokenValid && (
                <IonGrid className="ion-margin-vertical ion-padding-vertical">
                  <IonItem className="ion-no-padding">
                    <IonInput
                      type={showPassword1 ? "text" : "password"}
                      label="Create New Password"
                      labelPlacement="floating"
                      min={6}
                      maxlength={30}
                      value={form.password}
                      onIonInput={(event) => {
                        setForm((form: PasswordForm) => ({
                          ...form,
                          password: event.detail.value || "",
                        }));
                      }}
                    />
                    {form?.password?.length > 0 && (
                      <IonIcon
                        className="cursor-pointer pwd-icon"
                        color="primary"
                        icon={showPassword1 ? eyeOutline : eyeOffOutline}
                        onClick={() => {
                          setPassword1Visibility(!showPassword1);
                        }}
                      />
                    )}
                  </IonItem>

                  <IonItem className="ion-no-padding ion-margin-vertical">
                    <IonInput
                      type={showPassword2 ? "text" : "password"}
                      label="Confirm New Password"
                      labelPlacement="floating"
                      min={6}
                      maxlength={30}
                      value={form.confirmPassword}
                      onIonInput={(event) => {
                        setForm((form: PasswordForm) => ({
                          ...form,
                          confirmPassword: event.detail.value || "",
                        }));
                      }}
                    />
                    {form?.confirmPassword?.length > 0 && (
                      <IonIcon
                        className="cursor-pointer pwd-icon"
                        color="primary"
                        icon={showPassword2 ? eyeOutline : eyeOffOutline}
                        onClick={() => {
                          setPassword2Visibility(!showPassword2);
                        }}
                      />
                    )}
                  </IonItem>

                  <AppButton
                    className="ion-no-padding ion-no-margin ion-margin-vertical"
                    expand="full"
                    color="secondary"
                    type="submit"
                    buttonText="Update Password"
                    isLoading={isLoading}
                    disabled={validateForm() || isLoading}
                    onButtonClick={() => onSubmit()}
                  />
                </IonGrid>
              )}

              {isTokenVerified && !isTokenValid && (
                <IonGrid className="message-contsainer ion-text-center ion-padding-horizontal mt-lg">
                  <IonRow className="ion-padding-horizontal ion-text-center ion-justify-content-center mt-lg">
                    <IonText className="ion-text-center primary-dark font-18">
                      The link is either invalid or has expired. Click the
                      button below to generate a new link
                    </IonText>
                  </IonRow>
                  <IonRow className="ion-margin-top ion-padding-top ion-justify-content-center">
                    <IonButton
                      className="mt-2"
                      onClick={() => {
                        setEmail("");
                        setModalVisibility(true);
                      }}
                    >
                      Generate new link
                    </IonButton>
                  </IonRow>
                </IonGrid>
              )}
            </IonGrid>
          </div>
        </div>
      </IonContent>

      <IonModal
        isOpen={isShowModal}
        className="height-auto"
        onDidDismiss={() => {
          setModalVisibility(false);
        }}
      >
        <IonGrid className="content-container ion-padding full-width overflow-auto">
          <IonRow className="ion-padding ion-justify-content-center">
            <IonText className="primary-dark">Generate New Link</IonText>
          </IonRow>
          <AppInput
            itemClassName="ion-no-padding"
            label="Enter Email"
            value={email}
            onInputChange={(value: any) => {
              validateEmail(value);
              setEmail(value);
            }}
          ></AppInput>
          {email?.length > 0 && !isEmailValid && (
            <IonText color="danger">Please enter a valid email</IonText>
          )}

          <IonRow className="ion-justify-content-end">
            <AppButton
              className="ion-margin-top"
              fill="outline"
              buttonText="Close"
              onButtonClick={() => {
                setModalVisibility(false);
              }}
            />

            <AppButton
              className="ion-margin-top"
              buttonText="Generate"
              disabled={!email?.length || !isEmailValid}
              onButtonClick={() => {
                setModalVisibility(false);
                generateNewLink(email);
              }}
            />
          </IonRow>
        </IonGrid>
      </IonModal>
    </IonPage>
  );
};

export default ResetPassword;
