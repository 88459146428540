const roles = (state: any, data: any) => {
  return [
    ...data
  ];
  //   return [
  //     "authentication.is_assign_teacher",
  //     "authentication.is_educator",
  //     "authentication.is_school_full",
  //     "authentication.secondary_caregiver",
  //     "authentication.is_school_admin",
  //     "authentication.is_classroom_full",
  //     "authentication.is_invite_teacher"
  // ]
};

export default roles;
