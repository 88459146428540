import { IonInput, IonItem } from "@ionic/react";
import React from "react";

function CustomInput(props: any) {
  return (
    <IonItem detail={false} className="m-t-1 ion-no-padding">
      <IonInput
        {...props}
        labelPlacement="floating"
        onIonInput={(e: any) => {
          props?.onChange(e?.detail?.value);
        }}
      ></IonInput>
    </IonItem>
  );
}

export default CustomInput;
