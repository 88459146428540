import axiosInstance from "../helper/axios";

export interface GoalInfo {
  child: number;
  daily_ctc_goal: number;
}

type Notes = {
  notes: string;
  child: number;
};

export const addNewChildren = async (classroom: any, classroom_id: number) => {
  return await axiosInstance().post(
    `/educator/children/add/${classroom_id}`,
    classroom
  );
};

export const getAllChildFromClass = async (classroom_id: number) => {
  return await axiosInstance().get(`/educator/children/list/${classroom_id}`);
};

export const removeChildrenFromClassroom = async (
  classroom_id: number,
  children: any
) => {
  return await axiosInstance().put(
    `/educator/classroom/children/remove/${classroom_id}`,
    children
  );
};

export const getChildDevice = async (child_id: number) => {
  return await axiosInstance().get(`/child/device/${child_id}`);
};

export const verifyChild = async (classroom_id: number, child: any) => {
  return await axiosInstance().post(
    `/educator/children/valid/${classroom_id}`,
    child
  );
};

export const getChildGoalById = async (child_id: number, date: any) => {
  return await axiosInstance().get(
    `/goal/get-daily-ctc-goal/${child_id}/${date}`,
    date
  );
};

export const updateWeeklyGoal = async (goal: any) => {
  return await axiosInstance().put(`/goal/update-weekly-goal/${goal.child}`, goal);
};

export const updateChildGoal = async (goal: GoalInfo) => {
  return await axiosInstance().post(`/goal/create-daily-goal/`, goal);
};

export const getNotesByChild = async (
  child: number,
  start_date: string,
  end_date: string,
  queryParam: any
) => {
  return await axiosInstance().get(
    `/educator/children/notes/all/${child}/${start_date}/${end_date}?draw=0&length=${queryParam.length}&start=${queryParam.start}`
  );
};
export const saveNotes = async (child: number, note: Notes) => {
  return await axiosInstance().post(`/educator/children/notes/${child}`, note);
};
