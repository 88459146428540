const deviceTable = [
  {
    id: "child",
    label: "Child Name",
    align: "left",
    defaultText: "N/A",
  },
  {
    id: "device_name",
    label: "Device Name",
    align: "left",
    defaultText: "N/A",
  },

  {
    id: "device_live_minutes",
    label: "Time Luet was on",
    align: "center",
    defaultText: "N/A",
  },
  {
    id: "battery",
    label: "Battery",
    align: "center",
  },
];
export const tableHeader = {
  connected: [
    ...deviceTable,
    {
      id: "device_status",
      label: "Device Status",
      align: "center",
      defaultText: "Running Successfully",
    },
  ],
  disconnected: [
    ...deviceTable,
    {
      id: "device_issue",
      label: "Issue",
      align: "center",
      defaultText: "Disconnected / Off",
    },
  ],
  svgIcons: {
    "100": {
      fill: ["#4AB8D0", "#4AB8D0", "#4AB8D0", "#4AB8D0"],
      stroke: "#AEE3EF",
    },
    "75": {
      fill: ["#4AB8D0", "#4AB8D0", "#4AB8D0", ""],
      stroke: "#AEE3EF",
    },
    "50": {
      fill: ["#4AB8D0", "#4AB8D0", "", ""],
      stroke: "#AEE3EF",
    },
    "25": {
      fill: ["#4AB8D0", "", "", ""],
      stroke: "#AEE3EF",
    },
    "0": {
      fill: ["", "", "", ""],
      stroke: "#AEE3EF",
    },
  },
};
