import axiosInstance from "../helper/axios";

export const getAllSchools = async () => {
  return await axiosInstance().get("/educator/school/list");
};

export const getAllClassrooms = async (schoolId: number) => {
  return await axiosInstance().get(`/educator/classroom/list/${schoolId}`);
};

export const getUnAssignedClassroomByTeacherId = async (
  schoolId: number,
  user_id?: number
) => {
  return await axiosInstance().get(
    `/educator/classroom/list/${schoolId}?user_id=${user_id}`
  );
};

export const addClassrooms = async (classroom: any, school_id: number) => {
  return await axiosInstance().post(
    `/educator/classroom/create/${school_id}`,
    classroom
  );
};

export const getAllTeachers = async (schoolId: number) => {
  return await axiosInstance().get(`/educator/teacher/list/${schoolId}`);
};

export const getAllTeachersByClassroom = async (
  schoolId: number,
  classroom_id: number
) => {
  return await axiosInstance().get(
    `/educator/teacher/list/${schoolId}?classroom_id=${classroom_id}`
  );
};

export const inivteTeacher = async (teacher: any) => {
  return await axiosInstance().post(
    `/educator/invite_teacher/${teacher?.school_id}`,
    teacher
  );
};

export const updateTeacherProfile = async (teacher: any) => {
  return await axiosInstance().put("/educator/profile/update", teacher);
};

export const getClassroomById = async (classroomId: any) => {
  return await axiosInstance().get(`/educator/classroom/${classroomId}`);
};

export const getRecommendedCTCGoal = async (classroomId: any) => {
  return await axiosInstance().get(
    `/educator/classroom/recomended/${classroomId}`
  );
};

export const updateClassroom = async (classroomId: any, goal: any) => {
  return await axiosInstance().put(
    `/educator/classroom/update/${classroomId}`,
    goal
  );
};

export const updateTeacherClassroom = async (info: any) => {
  return await axiosInstance().post(`/educator/teacher/classroom/update`, info);
};

export const getClassroomsByTeacher = async (teacher: any) => {
  return await axiosInstance().get(
    `/educator/admin/classroom/user/list/${teacher}`
  );
};

export const getTeacherNotification = async () => {
  return await axiosInstance().get("/notification/all");
};


export const clearNotificationById = async (notify_id: number) => {
  return await axiosInstance().delete(`/notification/clear/${notify_id}`);
};


