import React from "react";
import { Capacitor } from "@capacitor/core";
import { useContext, useEffect } from "react";
import { App } from "@capacitor/app";
import { SplashScreen } from "@capacitor/splash-screen";
import {
  BLE_TURNED_OFF,
  BLE_TURNED_ON,
  LOCATION_TURNED_OFF,
  LOCATION_TURNED_ON,
} from "./constants";
import { GlobalContext } from "./context/Provider";
import {
  checkBLEPermission,
  checkLocationPermission,
} from "./common/BlePermissions";
import { updateTeacherProfile } from "./Services/Teacher";
import { getUserByToken } from "./Services/User";
import { checkAndUpdateTimeZone, disconnect } from "ble-library";
import { updateDeviceState } from "./common/BleConnectionStatus";
import { AppLogger } from "./Services/Logger";
import { useIonAlert, useIonToast } from "@ionic/react";
import { Network } from "@capacitor/network";
import toastConfig from "./common/toast-config";
import { setAppVersionInfo } from "./common/Util";

type DeviceLog = {
  mac_address: string;
  status: string;
};

const AppListener = () => {
  const { permissionDispatch, bleConnectionState, bleConnectionDispatch } =
    useContext(GlobalContext);
  const [present, dismiss] = useIonToast();
  const isNativePlatform = Capacitor.isNativePlatform();
  const [create] = useIonAlert();

  const isAuthenticated = () => {
    return !!localStorage.token && !!localStorage.refresh;
  };

  App.addListener("appUrlOpen", (event) => {
    const slug = event.url.split("tmw-northstar.org/").pop();
    if (slug) {
      localStorage.clear();
      if (isAuthenticated()) {
        SplashScreen.show({
          autoHide: false,
        });
        if (slug?.indexOf("auth") >= 0) {
          localStorage.clear();
        }
        window.location.replace(slug);
      } else {
        window.location.replace(slug);
      }
    }
  });

  App.addListener("backButton", () => {
    if (window.location.pathname === "/") {
      App.exitApp();
    }
  });

  const updateTimeZoneInDevice = (tz: string) => {
    Object.keys(bleConnectionState).map((device: any) => {
      if (bleConnectionState[device].isConnected) {
        checkAndUpdateTimeZone(
          bleConnectionState[device].device_reference,
          tz,
          (response: any) => {
            console.log(
              "AppListner:: TimeZone update device response::",
              response
            );
          }
        );
      }
    });
  };

  const logInfo = (type: string, msg: any) => {
    if (localStorage.teacher_id) {
      AppLogger(
        {
          device_id: localStorage.teacher_id,
        },
        "Listner",
        type,
        msg
      );
    }
  };

  const listenAppActiveState = () => {
    App.addListener("appStateChange", ({ isActive }: any) => {
      console.log("AppListener:: State changed. Is active?", isActive);

      if (isNativePlatform && isActive) {
        logInfo("info", `App state change:: Is Active ${isActive}`);
        checkLocationPermission((isEnabled: any) => {
          console.log("Location response::", isEnabled);
          logInfo(
            "info",
            `Location permission response::Is Enalbed ::${isEnabled}`
          );
          if (isEnabled) {
            permissionDispatch(LOCATION_TURNED_ON);
          } else {
            permissionDispatch(LOCATION_TURNED_OFF);
          }
        });

        checkBLEPermission((isEnabled: any) => {
          console.log("Ble response::", isEnabled);
          logInfo(
            "info",
            `Bluetooth permission response::Is Enalbed ::${isEnabled}`
          );
          if (isEnabled) {
            permissionDispatch(BLE_TURNED_ON);
          } else {
            permissionDispatch(BLE_TURNED_OFF);
          }
        });

        const currentTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
        if (localStorage.time_zone !== currentTZ && localStorage.classroom_id) {
          console.log("TimeZone changed!");

          getUserByToken().then((res: any) => {
            const teacherProfile = {
              educator: { education_level: "", years_experience: 0 },
              user: {
                first_name: "",
                last_name: "",
                time_zone: "",
              },
              classroom_id: parseInt(localStorage.classroom_id),
            };
            if (res?.data?.profile?.first_name) {
              teacherProfile.user.first_name = res?.data?.profile?.first_name;
              teacherProfile.user.last_name = res?.data?.profile?.last_name;
              teacherProfile.user.time_zone = currentTZ;
            }

            if (res?.data?.profile?.educator) {
              teacherProfile.educator.years_experience = parseInt(
                res?.data?.profile?.educator?.years_experience
              );
              teacherProfile.educator.education_level =
                res?.data?.profile?.educator?.education_level;
            }
            updateTeacherProfile(teacherProfile).then((res: any) => {
              console.log("Update teacher profile res::", res);
              if (res.status === 201) {
                localStorage.time_zone = currentTZ;
              }
            });
            updateTimeZoneInDevice(currentTZ);
          });
        } else {
          console.log("TimeZone not changed!");
        }
      }
    });
  };

  const showAppUpdateAlert = () => {
    create({
      subHeader: "Luet Classroom Update",
      message: "Please update your Luet Classroom app to latest version.",
      cssClass: ["app-update-alert ion-text-center"],
      backdropDismiss: false,
      keyboardClose: false,
    });
  };

  const showNetworkConnectionAlert = () => {
    present({
      ...toastConfig.notClosableError,
      message:
        "You are currently offline, several features may not be available. Please connect to Wi-Fi or your mobile data.",
    });
  };
  const checkNetworkConnection = async () => {
    Network.addListener("networkStatusChange", (status) => {
      console.log("Network status changed >>>>>>>>>>>>>>>>>>>>>", status);
      if (!status?.connected) {
        showNetworkConnectionAlert();
      } else {
        dismiss();
      }
    });
    const status = await Network.getStatus();
    console.log("Network connection status >>>", status);

    if (!status?.connected) {
      showNetworkConnectionAlert();
    } else {
      dismiss();
    }
  };

  const logDeviceConnectionStatus = () => {
    let device_log: DeviceLog;
    let device_list: DeviceLog[] = [];
    logInfo("info", "Checking device connection status");
    Object.keys(bleConnectionState).map((device: any) => {
      if (bleConnectionState[device].device_reference) {
        device_log = {
          mac_address: bleConnectionState[device].device_reference,
          status: bleConnectionState[device].isConnected
            ? "Connected"
            : "Disconnected",
        };
        device_list.push(device_log);
      }
      if (device_list?.length === Object.keys(bleConnectionState)?.length) {
        logInfo(
          "info",
          `Device connection status ::${JSON.stringify(device_list)}`
        );
        device_list = [];
      }
    });
  };

  useEffect(() => {
    window.addEventListener("storage", () => {
      if (localStorage.is_app_update_needed === "true") {
        showAppUpdateAlert();
      }
    });

    if (isNativePlatform) {
      logInfo("info", "App opened... Checking Permissions");

      setAppVersionInfo();

      window.bluetoothle.initialize(
        function (res: any) {
          console.log("App:: BLE initialized...", res);
          logInfo(
            "info",
            `Bluetooth Initialize response::${JSON.stringify(res)}`
          );
          if (res?.status === "disabled") {
            logInfo("info", `Bluetooth disabled. Disconnecting devices....`);
            permissionDispatch(BLE_TURNED_OFF);
            Object.keys(bleConnectionState).map((device: any) => {
              disconnect(
                bleConnectionState[device].device_reference,
                AppLogger,
                function (res: any) {
                  console.log(`Disconnected ${res}`);
                  logInfo(
                    "info",
                    `Disconnected response for ${bleConnectionState[device].mac_address
                    }:: ${JSON.stringify(res)}`
                  );
                }
              );
              bleConnectionState[device].isConnected = false;
              bleConnectionState[device].status = "disconnected";
              updateDeviceState(
                bleConnectionState[device],
                bleConnectionDispatch
              );
            });
          }

          if (res?.status === "enabled") {
            permissionDispatch(BLE_TURNED_ON);
            localStorage.reconnect_devices = true;
            window.dispatchEvent(new Event("storage"));
          }
        },
        {
          request: true,
          statusReceiver: true,
          restoreKey: "educator",
        }
      );
      listenAppActiveState();
    }
    SplashScreen.hide();
    if (localStorage.is_app_update_needed === "true") {
      showAppUpdateAlert();
    }
    checkNetworkConnection();

    setInterval(function () {
      logDeviceConnectionStatus();
    }, 120000);
  }, []);

  return null;
};

export default AppListener;
