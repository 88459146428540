import React, { useContext, useEffect } from "react";

import { useState } from "react";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
  IonSpinner,
  IonText,
  IonToggle,
  useIonAlert,
  useIonToast,
} from "@ionic/react";
import { GlobalContext } from "../../context/Provider";
import {
  enableBluetooth,
  enableLocation,
  openAndroidSettings,
  openIOSSettings,
} from "../../common/BlePermissions";
import { Capacitor } from "@capacitor/core";
import { BLE_TURNED_ON, LOCATION_TURNED_ON } from "../../constants";
import { close, closeOutline } from "ionicons/icons";
import "./Notifications.css";
import {
  clearNotificationById,
  getTeacherNotification,
} from "../../Services/Teacher";
import toastConfig from "../../common/toast-config";
import { format } from "date-fns";
import { AUTHENTICATION_PRIMARY_CAREGIVER } from "../../constants/permissions";
import { isRoleExist } from "../../common/Util";
import AppSpinner from "../AppSpinner/AppSpinner";

const Notifications = (props: any) => {
  console.log(props);

  const [isOpen, setIsOpen] = useState<boolean>(props.open);
  const [isBLETurningOn, setBLETurningOn] = useState<boolean>(false);
  const [isLocationTurningOn, setLocationTurningOn] = useState<boolean>(false);
  const { permissionState, permissionDispatch, roleState } =
    useContext(GlobalContext);
  const [notificationList, setNotificationList] = useState<any>(
    props?.notification
  );
  const [isNotificationAPILoading, setNotificationAPILoading] =
    useState<boolean>(true);

  const platform = Capacitor.getPlatform();
  const [present] = useIonToast();
  const [create] = useIonAlert();
  const isTeacher = isRoleExist(roleState, [AUTHENTICATION_PRIMARY_CAREGIVER]);
  const showIOSLocationAlert = (message: string) => {
    create({
      message,
      buttons: [
        {
          text: "Close",
          role: "close",
        },
        {
          text: "Go to settings",
          handler: () => {
            if (platform === "android") {
              openAndroidSettings();
            }
            if (platform === "ios") {
              openIOSSettings();
            }
          },
        },
      ],
    });
  };

  const showBLEAlert = (message: string) => {
    create({
      message,
      buttons: [
        {
          text: "Close",
          role: "close",
        },
        {
          text: "Go to settings",
          handler: () => {
            if (platform === "android") {
              openAndroidSettings();
            }
            if (platform === "ios") {
              openIOSSettings();
            }
          },
        },
      ],
    });
  };

  const requestBLE = (e: any) => {
    if (e?.detail?.checked) {
      setBLETurningOn(true);
      enableBluetooth(true, (response: any) => {
        if (response === true) {
          permissionDispatch(BLE_TURNED_ON);
        } else {
          if (
            !response?.isEnabled &&
            response?.redirectToSettings &&
            response.message
          ) {
            showBLEAlert(response.message);
          }
          setBLETurningOn(false);
        }
      });
    }
  };

  const requestLocation = (e: any) => {
    if (e?.detail?.checked) {
      setLocationTurningOn(true);
      enableLocation((response: any) => {
        if (response === true) {
          permissionDispatch(LOCATION_TURNED_ON);
        } else {
          if (
            !response?.isEnabled &&
            response?.redirectToSettings &&
            response.message
          ) {
            showIOSLocationAlert(response.message);
          }
          setLocationTurningOn(false);
        }
      });
    }
  };

  const removeNotificationFromList = (removed_notification: any) => {
    const newList = notificationList?.filter((notification: any) => {
      return notification.id !== removed_notification?.id;
    });
    setNotificationList(newList);
  };

  const onNotificationClick = (notification: any) => {
    clearNotificationById(notification?.id)
      .then((res: any) => {
        if (res.status === 201) {
          present({
            ...toastConfig.success,
            message: `Notification removed successfully!`,
          });
          removeNotificationFromList(notification);
        }
      })
      .catch((err: any) => {
        console.log(err);
        present({
          ...toastConfig.error,
          message: `Failed to remove notification. Please try again later!`,
        });
      });
  };

  const getNotification = () => {
    setNotificationAPILoading(true);
    getTeacherNotification()
      .then((res: any) => {
        if (res.status === 200) {
          setNotificationList(res?.data);
        }
        setNotificationAPILoading(false);
      })
      .catch((err: any) => console.log(err));
  };

  useEffect(() => {
    getNotification();
  }, []);

  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={() => {
        props?.onClose();
        setIsOpen(false);
      }}
      className="mt-lg"
    >
      <IonContent>
        <IonGrid>
          <IonRow className="ion-padding ion-align-items-center ion-justify-content-center ion-text-center">
            <IonText className="primary-heading">Notifications</IonText>

            <IonIcon
              className="icon-font icon-padding close-icon cursor-pointer primary-dark"
              icon={closeOutline}
              onClick={() => setIsOpen(false)}
            />
          </IonRow>

          {isTeacher && !permissionState?.bleTurnedOn && (
            <IonRow className="ion-padding">
              <IonToggle
                className="width-100"
                labelPlacement="start"
                justify="space-between"
                aria-label="ble-toggle"
                checked={isBLETurningOn}
                onIonChange={(e) => requestBLE(e)}
              >
                Please turn on Bluetooth permission
              </IonToggle>
            </IonRow>
          )}

          {isTeacher && !permissionState?.locationTurnedOn && (
            <IonRow className="ion-padding">
              <IonToggle
                className="width-100"
                labelPlacement="start"
                justify="space-between"
                aria-label="location-toggle"
                checked={isLocationTurningOn}
                onIonChange={(e) => requestLocation(e)}
              >
                Please turn on Location permission
              </IonToggle>
            </IonRow>
          )}

          {permissionState.bleTurnedOn &&
            permissionState?.locationTurnedOn &&
            !notificationList?.length &&
            !isNotificationAPILoading && (
              <IonRow className="ion-justify-content-center">
                <IonText className="ion-padding-top ion-margin-top">
                  No notifications found!
                </IonText>
              </IonRow>
            )}

          {notificationList?.length > 0 && (
            <IonGrid className="ion-padding">
              {notificationList.map((notification: any, index: number) => {
                return (
                  <IonGrid
                    key={index}
                    className="teacher-notification ion-padding ion-margin-top"
                  >
                    <IonRow className="teacher-notification">
                      <IonCol size="8" className=" ion-no-padding">
                        <IonText>
                          {" "}
                          {format(
                            new Date(notification.created_at),
                            "yyyy-MM-dd"
                          )}
                        </IonText>
                      </IonCol>
                      <IonCol
                        size="4"
                        className="ion-text-end cursor-pointer ion-no-padding"
                      >
                        <IonIcon
                          icon={close}
                          className="font-20"
                          onClick={() => onNotificationClick(notification)}
                        ></IonIcon>
                      </IonCol>
                    </IonRow>
                    <IonRow>{notification.message}</IonRow>
                  </IonGrid>
                );
              })}
            </IonGrid>
          )}

          {isNotificationAPILoading && (
            <AppSpinner/>
          )}
        </IonGrid>
      </IonContent>
    </IonModal>
  );
};
export default Notifications;
