import React, { useContext, useEffect, useState } from "react";
import {
  IonChip,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonText,
  useIonAlert,
  useIonToast,
} from "@ionic/react";
import { useHistory } from "react-router";
import "./Login.css";
import axiosInstance from "../../helper/axios";
import { getUserByToken } from "../../Services/User";
import toastConfig from "../../common/toast-config";
import {
  isRoleExist,
  setAppVersionInfo,
  validateEmailFormat,
} from "../../common/Util";
import { AndroidAutoFillPassword } from "capacitor-android-autofill-save-password";
import { Capacitor } from "@capacitor/core";
import { SavePassword } from "capacitor-ios-autofill-save-password";
import { Preferences } from "@capacitor/preferences";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/navigation";
import "swiper/css";
import { closeCircle, eyeOffOutline, eyeOutline } from "ionicons/icons";
import {
  AUTHENTICATION_IS_EDUCATOR,
  AUTHENTICATION_IS_PARTNER_ADMIN,
  AUTHENTICATION_PRIMARY_CAREGIVER,
  EDUCATOR_MEMBER,
  EDUCATOR_PARTNER_ADMIN,
  EDUCATOR_SCHOOL_ADMIN,
  EDUCATOR_TEACHER,
} from "../../constants/permissions";
import { GlobalContext } from "../../context/Provider";
import { getAllAcademicYears, getSchoolInfoById } from "../../Services/School";
import { getAllClassrooms } from "../../Services/Teacher";
import AppButton from "../../components/AppButton/AppButton";

type Form = {
  email: string;
  password: string;
};

const Login: React.FC = () => {
  const [form, setForm] = useState<Form>({
    email: "tmwnorthstar.testing4+teach21@gmail.com",
    password: "123456",
  });
  const [autoFillEmails, setAutoFillEmails] = useState<string[]>([]);
  const [isInValidCredentials, setInValidCredentials] =
    useState<boolean>(false);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [showPassword, setPasswordVisibility] = useState(false);
  const [present] = useIonToast();
  const platform = Capacitor.getPlatform();
  const { roleDispatch, authDispatch } = useContext(GlobalContext);
  const history = useHistory();
  const [create] = useIonAlert();
  const isNativePlatform = Capacitor.isNativePlatform();

  const checkAutoFillEmails = async () => {
    const { value } = await Preferences.get({ key: "emails" });
    console.log("Stored emails::", value);
    if (value) {
      const parsedEmails: any = value?.split(",");
      console.log("Parsed emails::", parsedEmails);

      if (parsedEmails?.length) {
        setForm((form: any) => ({
          ...form,
          email: parsedEmails[0],
        }));
        validateEmail(parsedEmails[0]);
        setAutoFillEmails([...parsedEmails]);
      } else {
        setAutoFillEmails([]);
      }
    }
  };

  const isAutoFillEmailPresent = async (email: string) => {
    const { value } = await Preferences.get({ key: "emails" });
    const parsedEmails = value?.length ? value?.split(",") : [];
    const autoFillInfo: any = {
      isPresent: false,
      emailList: [],
    };
    console.log("Existing emails::", parsedEmails);
    autoFillInfo.emailList = parsedEmails;
    if (parsedEmails && parsedEmails.includes(email)) {
      autoFillInfo.isPresent = true;
    }
    return autoFillInfo;
  };

  const saveAutoFillInAndroid = async (
    email: string,
    password: string,
    redirect_path: string
  ) => {
    const saveResponse = await AndroidAutoFillPassword.save({
      username: email,
      password: password,
    });
    console.log("Android keystore response >>>>>>>>>>>>", saveResponse);
    history.push(redirect_path);
  };

  const saveAutoFillEmailInApp = async (
    emailList: any,
    email: string,
    password: string,
    redirect_path: string
  ) => {
    const emails = emailList || [];
    emails.unshift(email);
    console.log("New email:: >>>>>>", emails);
    await Preferences.set({
      key: "emails",
      value: emails.toString(),
    });
    const savedEmails = await Preferences.get({ key: "emails" });
    console.log("Updated list::", savedEmails);
    saveAutoFillInAndroid(email, password, redirect_path);
  };

  const alertAutoFillSave = (
    emailList: any,
    email: string,
    password: string,
    redirect_path: string
  ) => {
    const message = "Would you like to save this email and password?";
    create({
      message,
      buttons: [
        {
          text: "Not now",
          role: "close",
          handler: () => history.push(redirect_path),
        },
        {
          text: "Save",
          handler: () => {
            saveAutoFillEmailInApp(emailList, email, password, redirect_path);
          },
        },
      ],
    });
  };

  const alertAutoFillUpdatePassword = (
    email: string,
    password: string,
    redirect_path: string
  ) => {
    const message = "Would you like to save your updated password?";
    create({
      message,
      buttons: [
        {
          text: "Not now",
          role: "close",
          handler: () => history.push(redirect_path),
        },
        {
          text: "Update",
          handler: () => saveAutoFillInAndroid(email, password, redirect_path),
        },
      ],
    });
  };

  const checkStoredPassword = async (
    email: string,
    password: string,
    redirect_path: string
  ) => {
    const storedPassword: any = await AndroidAutoFillPassword.get({
      username: email,
    });
    if (storedPassword?.password && storedPassword?.password !== password) {
      console.log("Password changed...");
      alertAutoFillUpdatePassword(email, password, redirect_path);
    } else {
      console.log("Passoword not changed...");
      console.log("Redirecting to logged-in case");
      history.push(redirect_path);
    }
  };

  const setAutoFillEmail = async (
    email: string,
    password: string,
    redirect_path: string
  ) => {
    const autoFillInfo = await isAutoFillEmailPresent(email);
    if (!autoFillInfo?.isPresent) {
      alertAutoFillSave(autoFillInfo.emailList, email, password, redirect_path);
    } else {
      console.log("Email already present in storage...  Checking password...");
      checkStoredPassword(email, password, redirect_path);
    }
  };

  const checkAutoFill = (email: string, password: string) => {
    if (Capacitor.getPlatform() === "ios") {
      SavePassword.promptDialog({
        username: email,
        password: password,
      })
        .then(() => console.log("AutoFill dialog shown"))
        .catch((err) => console.error("AutoFill dialog failure", err));
    }
  };

  const alertAutoFill = (redirect_path: string) => {
    if (platform === "ios") {
      checkAutoFill(form.email?.toLowerCase(), form.password);
      history.push(redirect_path, { replace: true });
    } else if (platform === "android") {
      setAutoFillEmail(form.email?.toLowerCase(), form.password, redirect_path);
    } else {
      history.push(redirect_path, { replace: true });
    }
  };

  const setData = (res: any) => {
    if (res?.data?.length) {
      if (!localStorage.classroom_id) {
        localStorage.classroom_id = res?.data[0].key;
      }
    }
    alertAutoFill(`${process.env.PUBLIC_URL}/home`);
  };

  const getAdminClassrooms = (school: number) => {
    getAllClassrooms(school)
      .then((res: any) => {
        setData(res);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const checkPartnerAccountSetup = (user: any) => {
    console.log("checkPartnerAccountSetup user", user);
    if (!user?.school?.length) {
      if (!user?.profile?.educator?.org_role || !user?.profile?.phone) {
        alertAutoFill(`${process.env.PUBLIC_URL}/account/user-profile`);
      } else {
        console.log("else");
        alertAutoFill(`${process.env.PUBLIC_URL}/school/create`);
      }
    } else {
      localStorage.school_id = user?.school[0]?.id;
      getAdminClassrooms(user?.school[0]?.id);
    }
  };

  const checkAcademicYear = (school_id: number) => {
    if (localStorage.school_id) {
      getAllAcademicYears(school_id)
        .then((res: any) => {
          if (res.status === 200 && res?.data?.length) {
            getAdminClassrooms(school_id);
          } else {
            alertAutoFill(`${process.env.PUBLIC_URL}/academic/create`);
          }
        })
        .catch((err: any) => console.log(err));
    }
  };

  const getSchoolInfo = (school_id: number) => {
    if (school_id) {
      getSchoolInfoById(school_id)
        .then((res: any) => {
          if (res.status === 200) {
            if (res?.data?.no_of_classroom && res?.data?.no_of_teacher) {
              checkAcademicYear(school_id);
            } else {
              localStorage.is_profile_created === "false";
              alertAutoFill(`${process.env.PUBLIC_URL}/school/profile`);
            }
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  };

  const checkSchoolAdminAccountSetup = (user: any) => {
    console.log("checkSchoolAdminAccountSetup user", user);
    if (user?.school?.length) {
      localStorage.school_id = user?.school[0]?.id;
      if (!user?.profile?.educator?.org_role || !user?.profile?.phone) {
        localStorage.is_profile_created === "false";
        alertAutoFill(`${process.env.PUBLIC_URL}/account/user-profile`);
      } else {
        getSchoolInfo(user?.school[0]?.id);
      }
    } else {
      console.log("No schools assigned.");
    }
  };

  const showProfileInCompleteAlert = () => {
    create({
      message:
        "Your account still needs to be completed. Please inform your contact at the TMW Center for Early Learning + Public Health, and you may have to provide some additional demographic information to finish creating your account. We will work to finish setting up your account quickly. Thank you for understanding.",
      cssClass: ["app-update-alert ion-text-center"],
      backdropDismiss: false,
      keyboardClose: false,
    });
  };

  const checkMemberAccount = (user: any) => {
    console.log("checkMemberAccount user", user);
    setIsloading(false);
    user.profile.phone = null;
    if (user?.school?.length) {
      localStorage.school_id = user?.school[0]?.id;
      if (user?.school[0]?.classroom?.length) {
        localStorage.classroom_id = user?.school[0]?.classroom[0]?.classroom_id;
      }
      if (!user?.profile?.phone) {
        localStorage.is_profile_created === "false";
        showProfileInCompleteAlert();
      } else {
        if (
          user?.profile?.educator?.education_level &&
          user?.profile?.educator?.years_experience &&
          user?.profile?.time_zone
        ) {
          alertAutoFill(`${process.env.PUBLIC_URL}/home`);
        } else {
          localStorage.is_profile_created === "false";
          showProfileInCompleteAlert();
        }
      }
    }
  };

  const checkRoles = (user: any) => {
    if (user.school?.length) {
      const userRole = user?.school[0]?.role;
      switch (userRole) {
        case EDUCATOR_PARTNER_ADMIN:
          checkPartnerAccountSetup(user);
          break;
        case EDUCATOR_SCHOOL_ADMIN:
          checkSchoolAdminAccountSetup(user);
          break;
        case EDUCATOR_MEMBER:
          checkMemberAccount(user);
          break;
      }
    } else {
      if (
        isRoleExist(user?.auth_permissions, [AUTHENTICATION_IS_PARTNER_ADMIN])
      ) {
        checkPartnerAccountSetup(user);
      }
    }
  };

  const getUserInfoByToken = () => {
    getUserByToken()
      .then((res: any) => {
        if (
          res?.data?.id &&
          res?.data?.auth_groups?.length &&
          isRoleExist(res?.data?.auth_groups, [EDUCATOR_TEACHER])
        ) {
          if (
            res?.data?.profile?.educator?.education_level &&
            res?.data?.profile?.educator?.years_experience
          ) {
            if (res?.data?.school?.length) {
              localStorage.school_id = res?.data?.school[0]?.id;
              if (res?.data?.school[0]?.classroom?.length) {
                localStorage.classroom_id =
                  res?.data?.school[0]?.classroom[0]?.classroom_id;
              }
            }

            localStorage.teacher_id = res?.data.id;
            localStorage.email = res?.data?.email;
            authDispatch(res?.data?.profile);
            localStorage.time_zone = res?.data?.profile?.time_zone;
            roleDispatch(res?.data?.auth_permissions);
            localStorage.permissions = res?.data?.auth_permissions;
            alertAutoFill(`${process.env.PUBLIC_URL}/home`);
          } else {
            setIsloading(false);
            localStorage.clear();
            showProfileInCompleteAlert();
          }
        } else {
          localStorage.clear();
          setIsloading(false);
          console.log("You don't have access");
          present({
            ...toastConfig.error,
            message: "You are not authorized to login here.",
          });
        }
      })
      .catch(() => {
        setIsloading(false);
      });
  };

  const handleSubmit = () => {
    setIsloading(true);
    axiosInstance()
      .post("/auth/login/", {
        email: form.email,
        password: form.password,
      })
      .then((res) => {
        setInValidCredentials(false);
        if (res?.data?.access && res?.data?.refresh) {
          localStorage.access = res?.data?.access;
          localStorage.refresh = res?.data?.refresh;
          getUserInfoByToken();
        }
      })
      .catch((err) => {
        setInValidCredentials(true);
        setIsloading(false);
      });
  };

  const validateEmail = (email: string) => {
    if (validateEmailFormat(email)) {
      setIsEmailValid(true);
    } else {
      setIsEmailValid(false);
    }
  };

  const onAutoFillSelect = async (email: string) => {
    console.log("On email select::", email);
    if (email) {
      try {
        const password: any = await AndroidAutoFillPassword.get({
          username: email,
        });
        if (password?.password) {
          setForm((form: Form) => ({
            ...form,
            email,
            password: password?.password,
          }));
          validateEmail(email);
        }
        console.log("Password from Android keystore >>>>>>>>>>>>", password);
      } catch (e) {
        console.log("Error::", e);
      }
    }
  };

  const removePasswordFromAndroid = async (email: string) => {
    console.log("Delete password for ", form?.email);
    const password = await AndroidAutoFillPassword.delete({
      username: email,
    });
    console.log("Delete >>>", password);
  };

  const removeAutoFillInApp = async (email: string) => {
    const { value } = await Preferences.get({ key: "emails" });
    if (value) {
      const splittedEmails = value?.split(",");
      if (splittedEmails?.length) {
        const index = splittedEmails.indexOf(email);
        if (index > -1) {
          splittedEmails.splice(index, 1);
          await Preferences.set({
            key: "emails",
            value: splittedEmails.toString(),
          });
          setAutoFillEmails([...splittedEmails]);
          removePasswordFromAndroid(email);
        }
      }
    }
  };

  const onAutoFillRemove = async (email: string) => {
    console.log("On email remove::", email);
    if (email) {
      const message = `Are you sure you want to remove ${email}?`;
      create({
        message,
        buttons: [
          {
            text: "No",
            role: "close",
          },
          {
            text: "Delete",
            handler: () => {
              removeAutoFillInApp(email);
            },
          },
        ],
      });
    }
  };

  useEffect(() => {
    setAppVersionInfo();
    checkAutoFillEmails();
  }, []);

  if (Capacitor.getPlatform() === "ios") {
    document
      ?.getElementById("email")
      ?.children[0]?.addEventListener("change", (e: any) => {
        setTimeout(() => {
          setForm((form: any) => ({
            ...form,
            email: e.target.value,
          }));
        }, 100);
      });
    document
      ?.getElementById("pwd")
      ?.children[0]?.addEventListener("change", (e: any) => {
        setForm((form: any) => ({
          ...form,
          password: e.target.value,
        }));
      });
  }

  return (
    <IonPage>
      <IonContent force-overscroll="false">
        <div className="helper">
          <div className="login-container">
            <IonGrid className="content-container m-t-4">
              <div className="logo-section  m-b-50">
                <IonImg alt="app-logo" src="/assets/logo.png" />
              </div>
              <IonGrid className="ion-margin-top">
                <IonItem lines="none" detail={false} className="ion-no-padding">
                  <IonInput
                    id="email"
                    type="email"
                    label="Email Address"
                    autocomplete="email"
                    labelPlacement="floating"
                    errorText="Enter a valid email"
                    value={form.email}
                    onIonInput={(event: any) => {
                      validateEmail(event.detail.value);
                      setInValidCredentials(false);
                      setForm((form: any) => ({
                        ...form,
                        email: event.detail.value,
                      }));
                    }}
                  ></IonInput>
                </IonItem>

                <IonItem lines="none" detail={false} className="ion-no-padding">
                  <IonInput
                    id="pwd"
                    type={showPassword ? "text" : "password"}
                    label="Password"
                    autocomplete="current-password"
                    value={form.password}
                    labelPlacement="floating"
                    errorText="Invalid email"
                    onIonInput={(event) => {
                      setInValidCredentials(false);
                      setForm((form: any) => ({
                        ...form,
                        password: event.detail.value,
                      }));
                    }}
                  ></IonInput>
                  {form?.password?.length > 0 && (
                    <IonIcon
                      className="cursor-pointer pwd-icon"
                      color="primary"
                      icon={showPassword ? eyeOutline : eyeOffOutline}
                      onClick={() => {
                        setPasswordVisibility(!showPassword);
                      }}
                    />
                  )}
                </IonItem>

                {isInValidCredentials && (
                  <IonRow className="ion-justify-content-center ion-padding">
                    <IonText color="danger">
                      Invalid credentials, try again
                    </IonText>
                  </IonRow>
                )}
                <IonRow className="ion-justify-content-center ion-padding">
                  <AppButton
                    className="ion-margin-top width-50"
                    expand="full"
                    type="submit"
                    buttonText="Login"
                    isLoading={isLoading}
                    // disabled={
                    //   isLoading ||
                    //   !isEmailValid ||
                    //   !form.email?.length ||
                    //   form?.password?.length < 6
                    // }
                    onButtonClick={handleSubmit}
                  />
                </IonRow>
              </IonGrid>
            </IonGrid>
          </div>
        </div>

        {autoFillEmails?.length > 0 && (
          <IonRow className="slide-custom ion-align-items-centerd slide-row">
            <Swiper
              slidesPerView={4}
              onSwiper={(swiper: any) => console.log(swiper)}
              onSlideChange={() => console.log("slide change")}
            >
              {autoFillEmails.map((email, index) => (
                <SwiperSlide
                  key={email}
                  virtualIndex={index}
                  className="slide-2"
                >
                  <IonChip className="custom-chip">
                    <IonLabel
                      onClick={() => onAutoFillSelect(email)}
                      className="primary-dark"
                    >
                      {email}
                    </IonLabel>
                    <IonIcon
                      icon={closeCircle}
                      onClick={() => onAutoFillRemove(email)}
                    />
                  </IonChip>
                </SwiperSlide>
              ))}
            </Swiper>
          </IonRow>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Login;
