import React, { useContext, useEffect, useState } from "react";
import { IonContent, IonGrid, IonPage, useIonToast } from "@ionic/react";
import { Capacitor } from "@capacitor/core";

import AppDataTable from "../../components/AppDataTable/AppDataTable";
import { format, subHours } from "date-fns";
import { Device } from "@capacitor/device";
import Hamburger from "../../components/HamburgerMenu/Hamburger";
import "./Home.css";
import {
  createCTC,
  getCTCByData,
  getDeviceIssuesByClassroom,
} from "../../Services/Home";
import {
  getFirmwareDetail,
  getFirmwareVersionById,
  updateAudioRecordingStatus,
  updateDevice,
} from "../../Services/Device";
import Classroom from "../../components/Classroom/Classroom";
import { GlobalContext } from "../../context/Provider";
import {
  connectDevice,
  setConnectionStatus,
  updateDeviceState,
} from "../../common/BleConnectionStatus";
import {
  checkAndUpdateTimeZone,
  clearDeviceError,
  readBatteryPercent,
  readCTC,
  readDeviceError,
  readDeviceLockStatus,
  readDeviceVersion,
  readHardWareVersion,
  readRecordingCountInMinutes,
  readUploadURL,
  subscribeCTCHoury,
  writeCTCGoal,
  writeOTAUpdateURL,
  disconnect,
  scanAvailableDevices,
  stopBleScan,
  writeAudioRecording,
} from "ble-library";
import {
  getCurrentDate,
  getCurrentHour,
  getCurrentHourFullDate,
  getUTCDateTime,
} from "../../common/DateUtil";
import { checkBLEPermission } from "../../common/BlePermissions";
import { BLE_TURNED_OFF, BLE_TURNED_ON } from "../../constants";
import { BLE } from "@ionic-native/ble";
import { App } from "@capacitor/app";
import { getMinutesToHours, isRoleExist } from "../../common/Util";
import { AUTHENTICATION_PRIMARY_CAREGIVER } from "../../constants/permissions";
import toastConfig from "../../common/toast-config";
import deviceServices from "ble-library";
import { AppLogger } from "../../Services/Logger";
import { tableHeader } from "./TableData";
import config from "../../conf.json";
import { unsubscribeCharacteristic } from "ble-library";
import { writeStringValues } from "ble-library";

const Home: React.FC = () => {
  console.log('deviceServices', deviceServices);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedClassroom, setSelectedClassroom] = useState<number | null>(
    parseInt(localStorage?.classroom_id) || 0
  );
  const [ctcInfo, setCTCInfo] = useState<any>([]);
  const [connectedDevices, setConnectedDevices] = useState<any>([]);
  const [disconnectedDevices, setDisconnectedDevices] = useState<any>([]);
  const currentDate = format(new Date(), "yyyy-MM-dd");
  const [isReadCTCInProgress, setReadCTCInProgress] = useState<any>(false);
  let start = 0;
  const length = 10,
    draw = 0;
  const ctcList: any = [];

  const platform = Capacitor.getPlatform();
  const isNativePlatform = Capacitor.isNativePlatform();
  const [present] = useIonToast();

  const {
    permissionState,
    permissionDispatch,
    bleConnectionDispatch,
    bleConnectionState,
    roleState,
  } = useContext(GlobalContext);

  console.log("Connection state::", bleConnectionState);
  const isTeacher = isRoleExist(roleState, [AUTHENTICATION_PRIMARY_CAREGIVER]);

  const writeUploadURL = (
    deviceId: string,
    child_id: number,
    callback: any
  ) => {
    const parsed_address = deviceId?.replaceAll(":", "");
    const url = `${config.APP_BACKEND_URL}audio/upload/${child_id}/${parsed_address}/default`;
    console.log("Update Upload URL", url);
    writeStringValues(
      deviceId,
      deviceServices.luetInfo.service,
      deviceServices.luetInfo.uploadURL,
      url,
      (response: any, error: any) => {
        if (response.status === "written") {
          readUploadURL(deviceId, callback);
        } else {
          callback(null, error);
        }
      }
    );
  };

  useEffect(() => {
    const connectedDevices: any = [];
    const disconnectedDevices: any = [];
    ctcInfo?.map((ctc: any) => {
      if (bleConnectionState[ctc?.device_reference]?.isConnected) {
        if (ctc?.wifi_access) {
          ctc.device_status = "Running Successfully";
        } else {
          ctc.device_status = "WiFi not setup";
        }
        connectedDevices.push(ctc);
      } else {
        if (ctc.wifi_access !== null) {
          ctc.device_issue = "Disconnected / Off";
        } else {
          ctc.device_issue = "N/A";
        }
        disconnectedDevices.push(ctc);
      }
    });
    setConnectedDevices(connectedDevices);
    setDisconnectedDevices(disconnectedDevices);
  }, [ctcInfo]);

  useEffect(() => {
    console.log("Permission state changed.....", permissionState);
    window.addEventListener("storage", () => {
      if (localStorage.reconnect_devices === "true") {
        connectAndroidDevices(bleConnectionState, ctcList);
        delete localStorage.reconnect_devices;
      }
    });
  }, [permissionState]);

  const onDeviceDisConnect = (disconnectedDevice: any) => {
    console.log("On device disconnect", disconnectedDevice);
    if (bleConnectionState) {
      Object.keys(bleConnectionState).map((device: any) => {
        if (
          bleConnectionState[device].mac_address ===
          disconnectedDevice.mac_address
        ) {
          bleConnectionState[device] = {
            ...bleConnectionState[device],
            status: "disconnected",
            batteryPercent: "N/A",
            isConnected: false,
            is_locked: "N/A",
          };

          AppLogger(
            {
              device_id: bleConnectionState[device].device_id,
              mac_address: bleConnectionState[device].mac_address,
            },
            "Home",
            "info",
            "Device disconnected..."
          );
          updateDeviceState(bleConnectionState[device], bleConnectionDispatch);
        }
      });
      console.log("On disconnect state::", bleConnectionState);
    }
  };

  const onPairingLost = (disconnectedDevice: any) => {
    console.log("On pairing lost::", disconnectedDevice);
    if (bleConnectionState) {
      Object.keys(bleConnectionState).map((device: any) => {
        if (
          bleConnectionState[device].mac_address ===
          disconnectedDevice.mac_address
        ) {
          bleConnectionState[device] = {
            ...bleConnectionState[device],
            status: "disconnected",
            isConnected: false,
          };
          updateDeviceState(bleConnectionState[device], bleConnectionDispatch);
        }
      });
      present({
        ...toastConfig.error,
        message: `${
          disconnectedDevice?.name || disconnectedDevice?.mac_address
        } disconnected. Please remove device from bluetooth settings and try again in pairing mode!`,
      });
    }
  };

  const checkDeviceTimeZone = (deviceId: string) => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (localStorage)
      checkAndUpdateTimeZone(
        deviceId,
        timeZone,
        (response: any, error: any) => {
          console.log("TimeZone update device response::", response);
          AppLogger(
            {
              device_id: bleConnectionState[deviceId].device_id,
              mac_address: bleConnectionState[deviceId].mac_address,
            },
            "Home",
            "info",
            `Update timezone response ${JSON.stringify(response || error)}`
          );
        }
      );
  };

  const updateDeviceOnConnect = (
    mac_address: string,
    device_id: string,
    ctcList: any
  ) => {
    ctcList?.map((ctc: any) => {
      if (ctc.device_reference === mac_address) {
        console.log("Updating goal::", mac_address, ctc.daily_goal);
        AppLogger(
          {
            device_id: bleConnectionState[mac_address].device_id,
            mac_address: bleConnectionState[mac_address].mac_address,
          },
          "Home",
          "info",
          `Start::Update goal ${JSON.stringify(ctc.daily_goal)}`
        );
        writeCTCGoal(
          device_id,
          ctc?.daily_goal,
          (response: any, error: any) => {
            console.log("Daily Goal updated::", response);
            AppLogger(
              {
                device_id: bleConnectionState[mac_address].device_id,
                mac_address: bleConnectionState[mac_address].mac_address,
              },
              "Home",
              "info",
              `End::Update goal response ${JSON.stringify(
                ctc.daily_goal || error
              )}`
            );
          }
        );
        readDeviceFirmwareVersion(device_id, ctc);
        readDeviceLock(bleConnectionState[mac_address]);
        readBattery(bleConnectionState[mac_address]);
      }
    });
  };

  const updateDeviceInfo = (status: boolean, device_id: number) => {
    const device = {
      status: status,
    };
    updateAudioRecordingStatus(device_id, device).then((response) => {
      console.log("Audio recording update in back-end response::", response);
    });
  };

  const writeDeviceAudioRecStatus = (deviceId: string) => {
    console.log("Write audio recording on device connect::", 3);
    AppLogger(
      {
        device_id: bleConnectionState[deviceId]?.device_id,
        mac_address: bleConnectionState[deviceId]?.mac_address,
      },
      "Home",
      "info",
      `Start::Update Audio recording in ${deviceId}:: ${3}}`
    );
    writeAudioRecording(deviceId, 3, (res: null | number, error: any) => {
      AppLogger(
        {
          device_id: bleConnectionState[deviceId]?.device_id,
          mac_address: bleConnectionState[deviceId]?.mac_address,
        },
        "Home",
        "info",
        `End::Update Audio recording response ${deviceId}:: ${JSON.stringify(
          res || error
        )}`
      );
      updateDeviceInfo(res === 3, bleConnectionState[deviceId]?.device_id);
    });
  };

  const clearError = (device: any) => {
    console.log("Clearing error in device", device.device_reference);
    AppLogger(
      {
        device_id: device?.device_id,
        mac_address: device?.mac_address,
      },
      "Home",
      "info",
      `Start::Clearing device error ${device?.device_reference}`
    );
    clearDeviceError(
      device.device_reference,
      (res: string | null, error: any) => {
        AppLogger(
          {
            device_id: device?.device_id,
            mac_address: device?.mac_address,
          },
          "Home",
          "info",
          `End::Clearing device error response for ${
            device?.device_reference
          }:: ${JSON.stringify(res || error)}`
        );
      }
    );
  };

  const updateErrorInformation = (device: any, error: string) => {
    AppLogger(
      {
        device_id: device?.device_id,
        mac_address: device?.mac_address,
      },
      "Home",
      "info",
      `Error info read response:: ${JSON.stringify(error)}`
    );
  };

  const checkForDeviceError = (device_id: string) => {
    console.log("Check device error on device connect::", device_id);
    AppLogger(
      {
        device_id: bleConnectionState[device_id]?.device_id,
        mac_address: bleConnectionState[device_id]?.mac_address,
      },
      "Home",
      "info",
      `Start::Checking device error ${device_id}`
    );

    readDeviceError(device_id, (res: string | null, error: any) => {
      console.log("Read device error::", res);
      AppLogger(
        {
          device_id: bleConnectionState[device_id]?.device_id,
          mac_address: bleConnectionState[device_id]?.mac_address,
        },
        "Home",
        "info",
        `End:: Error info read response:: ${JSON.stringify(res || error)}`
      );
      if (res) {
        clearError(bleConnectionState[device_id]);
      }
    });
  };

  const onDeviceConnect = (device: any, ctcList: any) => {
    console.log("On device connect::", device, ctcList);
    bleConnectionState[device?.mac_address] = {
      ...bleConnectionState[device?.mac_address],
      device_name: device.name,
      device_reference: device.address,
      mac_address: device?.mac_address,
      status: "connected",
      isConnected: true,
    };

    console.log("On connect state::", bleConnectionState);
    AppLogger(
      {
        device_id: bleConnectionState[device.mac_address]?.device_id,
        mac_address: bleConnectionState[device.mac_address]?.mac_address,
      },
      "Home",
      "info",
      "Device connected"
    );
    updateDeviceState(
      bleConnectionState[device?.mac_address],
      bleConnectionDispatch
    );
    if (device.mac_address) {
      updateDeviceOnConnect(device.mac_address, device.address, ctcList);
      checkDeviceTimeZone(device.address);
      writeDeviceAudioRecStatus(device.address);
      checkForDeviceError(device.address);
    }
  };

  const connectBleDevice = (device: any, ctcList: any) => {
    connectDevice(
      {
        ...device,
        device_id: bleConnectionState[device?.mac_address]?.device_id,
        mac_address: device?.mac_address,
      },
      (response: any) => {
        console.log("Connect callback response::", response);
        AppLogger(
          {
            device_id: bleConnectionState[device?.mac_address]?.device_id,
            mac_address: bleConnectionState[device?.mac_address]?.mac_address,
          },
          "Home",
          "info",
          `Device connection response::${JSON.stringify(response)}`
        );
        if (response?.isConnected) {
          onDeviceConnect(response, ctcList);
        }
        if (response?.status === "disconnected") {
          onDeviceDisConnect(response);
        }

        if (response?.status === "pairingLost") {
          AppLogger(
            {
              device_id: bleConnectionState[device?.mac_address]?.device_id,
              mac_address: bleConnectionState[device?.mac_address]?.mac_address,
            },
            "Home",
            "error",
            `Pairing information lost:: ${JSON.stringify(response)} `
          );
          onPairingLost(response);
        }
      }
    );
  };

  const scanForAvailableDevices = (deviceList: any, ctcList: any) => {
    console.log("Home: Scanning", deviceList);
    stopBleScan();
    setTimeout(() => {
      readFromConnectedDevices();
      scanAvailableDevices((device: any) => {
        if (device?.status === "scanResult" && deviceList[device.mac_address]) {
          connectBleDevice(device, ctcList);
        }
      });
    }, 1000);
  };

  const connectAndroidDevices = (deviceList: any, ctcList: any) => {
    console.log("Home:: Connect Android devices", deviceList);
    Object.keys(deviceList).map((device: any) => {
      console.log("Android", deviceList[device]);
      if (!deviceList[device].isConnected) {
        AppLogger(
          {
            device_id: bleConnectionState[device]?.device_id,
            mac_address: bleConnectionState[device]?.mac_address,
          },
          "Home",
          "info",
          `Connect Android devices:: ${bleConnectionState[device]?.mac_address} `
        );
        connectBleDevice(
          {
            address: deviceList[device].mac_address,
            mac_address: deviceList[device].mac_address,
          },
          ctcList
        );
      }
    });
  };

  const handleDeviceConnection = (deviceList: any, ctcList: any) => {
    checkBLEPermission((isEnabled: any) => {
      console.log("Ble permission response::", isEnabled);
      if (isEnabled) {
        permissionDispatch(BLE_TURNED_ON);
        if (platform === "ios") {
          scanForAvailableDevices(deviceList, ctcList);
        } else {
          connectAndroidDevices(deviceList, ctcList);
        }
      } else {
        console.log("Permissions not enabled.....");
        permissionDispatch(BLE_TURNED_OFF);
      }
    });
  };

  const getOSVersionAndConnect = async (deviceList: any, ctcList: any) => {
    const info = await Device.getInfo();
    console.log("Device Info::", info);

    if (info?.osVersion) {
      localStorage.osVersion = info.osVersion;
      handleDeviceConnection(deviceList, ctcList);
    }
  };

  const formatCTCInfo = (ctcInfo: any) => {
    let childCTC: any = {};
    let device: any = {};

    ctcInfo?.map((ctc: any) => {
      childCTC = {};
      device = {};
      childCTC.child_id = ctc.child_id;
      childCTC.child = `${ctc?.first_name} ${ctc?.last_name}`;
      childCTC.total_ctc = ctc.total_ctc;
      childCTC.device_name = ctc.device_name;
      childCTC.device_id = ctc.device_id;
      childCTC.device_reference = ctc?.device_reference;
      childCTC.total_ns = ctc.total_ns ? ctc.total_ns * 60 : 0; //Convert hour to minutes
      if (
        isNativePlatform &&
        bleConnectionState[ctc?.device_reference]?.isConnected
      ) {
        childCTC.battery =
          bleConnectionState[ctc?.device_reference]?.batteryPercent;
        if (bleConnectionState[ctc?.device_reference]?.firmware > 40) {
          readDeviceRecordingTime(bleConnectionState[ctc?.device_reference]);
        }
      } else {
        childCTC.battery = "N/A";
        childCTC.recording = "N/A";
        childCTC.device_live_minutes = "N/A";
      }

      childCTC.daily_goal = ctc?.goal;
      childCTC.firmware_version = ctc.firmware_version;
      childCTC.firmware_id = ctc.firmware_id;
      childCTC.wifi_access = ctc?.wifi_access;
      if (ctc?.wifi_access === null) {
        childCTC.device_issue = "N/A";
      } else if (!ctc?.wifi_access) {
        childCTC.device_issue = "WiFi not setup";
      } else {
        childCTC.device_issue = ctc.device_issue;
      }
      if (ctc?.device_reference) {
        if (!bleConnectionState[ctc.device_reference]) {
          bleConnectionState[ctc.device_reference] = {
            device_id: ctc?.device_id,
            device_reference: ctc?.device_reference,
            device_name: ctc?.device_name,
            status: "connecting",
            mac_address: ctc?.device_reference,
            isConnected: false,
          };
        }
      }
      ctcList.push(childCTC);
    });

    setCTCInfo(ctcList);
    console.log("On init state::", bleConnectionState);
    setConnectionStatus(bleConnectionState, bleConnectionDispatch);
    if (isTeacher) {
      if (localStorage.osVersion) {
        handleDeviceConnection(bleConnectionState, ctcList);
      } else {
        getOSVersionAndConnect(bleConnectionState, ctcList);
      }
    } else {
      disconnectConnectedDevices();
    }
  };

  const getCTCInfo = (classroom: number, date: string) => {
    const queryParams = {
      start,
      length,
      draw,
    };
    getCTCByData(classroom, date, queryParams)
      .then((res: any) => {
        setIsLoading(false);
        formatCTCInfo(res?.data?.data);
        if (start + res?.data?.recordsFiltered < res?.data?.recordsTotal) {
          start += res?.data?.recordsFiltered;
          getCTCInfo(classroom, date);
        }
      })
      .catch((err: any) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const getDeviceIssues = (id: number, wifiIssues: any) => {
    const queryParams = {
      start,
      length,
      draw,
    };
    getDeviceIssuesByClassroom(id || 0, queryParams)
      .then((res: any) => {
        if (res?.data?.data) {
          const issueList: any = [];
          let issue: any = {};
          res?.data?.data?.map((device: any) => {
            issue = {};
            issue.id = device.id;
            issue.child = `${device.child.first_name} ${device.child.last_name}`;
            issue.device_name = device.device_name;
            issue.issue =
              device?.message?.length > 50
                ? device?.message?.substring(0, 50) + "..."
                : device?.message;
            issue.issueFullText = device.message;
            issue.date = device.created_at
              ? device?.created_at?.split("T")[0]
              : "";
            issue.is_notified = device.is_notified;
            issueList.push(issue);
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const reloadData = () => {
    start = 0;
    setIsLoading(true);
    getCTCInfo(selectedClassroom || 0, currentDate);
    console.log("Device state::", bleConnectionState);
  };

  const disconnectConnectedDevices = () => {
    if (ctcInfo?.length) {
      ctcInfo?.map((ctc: any) => {
        if (
          bleConnectionState[ctc?.device_reference] &&
          bleConnectionState[ctc?.device_reference].isConnected
        ) {
          AppLogger(
            {
              device_id: bleConnectionState[ctc?.device_reference].device_id,
              mac_address:
                bleConnectionState[ctc?.device_reference].mac_address,
            },
            "Home",
            "info",
            `Disconnect connected device on classroom change::${
              bleConnectionState[ctc?.device_reference].mac_address
            }`
          );
          disconnect(
            bleConnectionState[ctc?.device_reference].device_reference,
            AppLogger,
            function (res: any) {
              console.log(`Disconnected ${res}`);
              AppLogger(
                {
                  device_id:
                    bleConnectionState[ctc?.device_reference].device_id,
                  mac_address:
                    bleConnectionState[ctc?.device_reference].mac_address,
                },
                "Home",
                "info",
                `Disconnected response on classroom change::${JSON.stringify(
                  res
                )}`
              );
              bleConnectionState[ctc?.device_reference] = false;
              updateDeviceState(
                bleConnectionState[ctc?.device_reference],
                bleConnectionDispatch
              );
            }
          );
        }
      });
    }
    setConnectionStatus({}, bleConnectionDispatch);
  };

  const onClassroomChange = (id: number) => {
    start = 0;
    disconnectConnectedDevices();
    getCTCInfo(id, currentDate);
    setSelectedClassroom(id);
  };

  const onSchoolChange = (id: number) => {
    start = 0;
    getCTCInfo(id, currentDate);
    setSelectedClassroom(id);
    if (isTeacher) {
      disconnectConnectedDevices();
    }
  };

  const subscribeeToBleStatus = () => {
    console.log("Subscribe to BLE state notify >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
    BLE.startStateNotifications().subscribe(
      function (state: any) {
        console.log("Bluetooth is >>>>>>>>>>>>>>>>>>>>>>>>>>>>>", state);
        if (state === "on") {
          permissionDispatch(BLE_TURNED_ON);
          handleDeviceConnection(bleConnectionState, ctcList);
        }
        if (state === "off") {
          permissionDispatch(BLE_TURNED_OFF);
          disconnectConnectedDevices();
        }
        checkBLEPermission((isEnabled: any) => {
          console.log("Ble response::", isEnabled);
          if (isEnabled) {
            permissionDispatch(BLE_TURNED_ON);
          } else {
            permissionDispatch(BLE_TURNED_OFF);
          }
        });
      },
      function (err) {
        console.log("BLE notify", err);
      }
    );
  };

  useEffect(() => {
    if (selectedClassroom) {
      getCTCInfo(selectedClassroom, currentDate);
    } else {
      setIsLoading(false);
    }

    if (isNativePlatform) {
      App.addListener("appStateChange", ({ isActive }) => {
        console.log("Home:: App state changed. App active status", isActive);
        if (isActive) {
          delete localStorage.isConnectingInProgress;
          subscribeeToBleStatus();
        }
      });
    }

    return () => {
      if (isNativePlatform) {
        ctcInfo?.map((ctc: any, index: number) => {
          if (
            bleConnectionState[ctc?.device_reference] &&
            bleConnectionState[ctc?.device_reference].isConnected
          ) {
            unsubscribeCharacteristic(
              bleConnectionState[ctc?.device_reference].device_reference,
              deviceServices.luetInfo.service,
              deviceServices.luetInfo.ctcHourlyCount
            );
          }
        });
      }
    };
  }, []);

  const createCTCForChild = (ctcList: any, child: number) => {
    createCTC(child, ctcList)
      .then((response: any) => {
        if (response?.status === 200) {
          console.log("CTC updated successfully");
        }
      })
      .catch((error) => {
        console.log("CTC update error::", error);
      });
  };

  const parseCTCWithDate = (ctc: any, child: number) => {
    let currentFullHour = getCurrentHourFullDate();
    let currentHour = getCurrentHour() + 1;
    if (ctc?.length) {
      console.log("24 Hours CTC", ctc);
      let hourCtc: any = {};
      const ctcList: any = [];
      if (ctc?.length) {
        ctc?.map((count: string) => {
          hourCtc = {};
          hourCtc.ctc = parseInt(count);
          hourCtc.created_at = getUTCDateTime(currentFullHour);
          currentHour = currentHour - 1;
          ctcList.push(hourCtc);
          currentFullHour = `${format(
            subHours(new Date(currentFullHour), 1),
            "yyyy-MM-dd HH:mm:ss"
          )}`;
        });
        if (ctcList.length) {
          createCTCForChild(ctcList, child);
        }
      }
    }
  };

  const updateCTC = (dev_ref_id: number, allCTC: any, macAddress: string) => {
    ctcInfo?.map((ctc: any) => {
      if (ctc.device_reference === macAddress) {
        const currentHour = getCurrentHour();
        const todayCTC = allCTC?.slice(0, currentHour);
        ctc.total_ctc = todayCTC.reduce(
          (pre: any, curr: any) => parseInt(pre) + parseInt(curr),
          0
        );
        parseCTCWithDate(allCTC, ctc.child_id);
      }
    });
    dev_ref_id && updateAppSyncDateTime(dev_ref_id, macAddress);
    setCTCInfo((ctcInfo: any) => [...ctcInfo]);
  };

  const updateAppSyncDateTime = (
    dev_ref_id: number,
    mac_address: string
  ) => {
    const device = {
      id: dev_ref_id,
      last_synced_at: getCurrentDate(),
    };
    updateDevice(dev_ref_id, device)
      .then((response) => {
        if (response?.status === 200) {
          console.log("Last app sync with device time updated!");
          AppLogger(
            { device_id: dev_ref_id, mac_address },
            "BLE_Read_Write",
            "info",
            `last synced date write response for ${dev_ref_id}::${response?.status}`
          );
        }
      })
      .catch((error) => {
        AppLogger(
          { device_id: dev_ref_id, mac_address },
          "BLE_Read_Write",
          "error",
          `last synced date write error for ${dev_ref_id}::${JSON.stringify(
            error
          )}`
        );
      });
  };

  const setRecordMinForOldFirmwares = (mac_address: string) => {
    console.log(
      "Show recording min for old firmwares >>>>>>>>>>>",
      mac_address,
      ctcInfo
    );

    ctcInfo?.map((ctc: any) => {
      if (ctc.device_reference === mac_address) {
        if (bleConnectionState[mac_address]) {
          bleConnectionState[mac_address].device_live_minutes =
            getMinutesToHours(ctc?.total_ns || 0);
          updateDeviceState(
            bleConnectionState[mac_address],
            bleConnectionDispatch
          );
        }
      }
    });
  };

  const updateBatteryPercent = (
    battery: number | string,
    macAddress: string
  ) => {
    console.log("Update battery percent::", battery);
    if (bleConnectionState[macAddress]) {
      bleConnectionState[macAddress].batteryPercent = battery;
      updateDeviceState(bleConnectionState[macAddress], bleConnectionDispatch);
    }
    ctcInfo?.map((ctc: any) => {
      if (ctc.device_reference === macAddress) {
        ctc.battery = battery !== null ? `${battery}%` : battery;
      }
    });
    setCTCInfo((ctcInfo: any) => [...ctcInfo]);
  };

  const writeOTAURL = (
    deviceId: string,
    targetUrl: string,
    deviceInfo: any
  ) => {
    console.log("Updating latest firmwares URL", targetUrl);
    AppLogger(
      {
        device_id: deviceInfo?.device_id,
        mac_address: deviceInfo?.device_reference,
      },
      "Home",
      "info",
      `Start: Updating latest OTA update URL :: ${JSON.stringify(targetUrl)}`
    );
    writeOTAUpdateURL(deviceId, targetUrl, (response: any) => {
      console.log("OTA URL update response from device::", response);
      AppLogger(
        {
          device_id: deviceInfo?.device_id,
          mac_address: deviceInfo?.device_reference,
        },
        "Home",
        "info",
        `End: Updating latest OTA update URL response:: ${JSON.stringify(
          response
        )}`
      );
    });
  };

  const setLatestFirmwareOTAURL = (
    deviceId: string,
    deviceInfo: any,
    firmware: number,
    hardware: number
  ) => {
    console.log("Set latest firmware OTA URL");
    AppLogger(
      {
        device_id: deviceInfo?.device_id,
        mac_address: deviceInfo?.device_reference,
      },
      "Home",
      "info",
      `Getting latest firmware detail`
    );
    getFirmwareDetail(firmware, hardware)
      .then((response) => {
        console.log("Latest firmware detail info::", response);
        if (response?.data?.download_url) {
          AppLogger(
            {
              device_id: deviceInfo?.device_id,
              mac_address: deviceInfo?.device_reference,
            },
            "Home",
            "info",
            `Updating latest firmware OTA URL (${JSON.stringify(
              response?.data?.download_url
            )})`
          );
          writeOTAURL(deviceId, response?.data?.download_url, deviceInfo);
        }
      })
      .catch((err: any) => {
        console.log("err: ", err);
        AppLogger(
          {
            device_id: deviceInfo?.device_id,
            mac_address: deviceInfo?.device_reference,
          },
          "Home",
          "error",
          `Firmware (${JSON.stringify(
            firmware
          )}) detail back-end error:: ${JSON.stringify(err)}`
        );
      });
  };

  const checkNextAvailableVersion = (
    deviceId: string,
    deviceInfo: any,
    firmware: number,
    hardware: number
  ) => {
    console.log("Check next available version::", deviceId, firmware, hardware);
    AppLogger(
      {
        device_id: deviceInfo.device_id,
        mac_address: deviceInfo.device_reference,
      },
      "Home",
      "info",
      `Start:: Checking next OTA URL for firmware ${firmware} and hardware ${hardware}`
    );
    getFirmwareVersionById(firmware, hardware)
      .then((response) => {
        AppLogger(
          {
            device_id: deviceInfo?.device_id,
            mac_address: deviceInfo?.device_reference,
          },
          "Home",
          "info",
          `Next available version for ${
            deviceInfo?.device_name
          }:: ${JSON.stringify(response?.data)}`
        );
        console.log(`Next version for ${deviceInfo?.device_name}::`, response);
        AppLogger(
          {
            device_id: deviceInfo.device_id,
            mac_address: deviceInfo.device_reference,
          },
          "Home",
          "info",
          `End:: Checking next OTA URL for firmware ${firmware} and hardware ${hardware} is:: ${JSON.stringify(
            response?.data
          )}`
        );
        if (response?.data?.is_latest) {
          console.log(`Firmware up to date for::${deviceInfo.device_name}`);
          AppLogger(
            {
              device_id: deviceInfo?.device_id,
              mac_address: deviceInfo?.device_reference,
            },
            "Home",
            "info",
            `Firmware (${JSON.stringify(firmware)}) is latest.`
          );
          setLatestFirmwareOTAURL(deviceId, deviceInfo, firmware, hardware);
        } else if (response?.data?.next_update) {
          const targetUrl = response?.data?.next_update?.download_url.split(
            "/ns_status_esp32.txt"
          )[0];
          AppLogger(
            {
              device_id: deviceInfo.device_id,
              mac_address: deviceInfo.device_reference,
            },
            "Home",
            "info",
            `Next OTA update URL:: ${JSON.stringify(targetUrl)}`
          );
          console.log("Next OTA update URL:", targetUrl);
          AppLogger(
            {
              device_id: deviceInfo?.device_id,
              mac_address: deviceInfo?.device_reference,
            },
            "Home",
            "info",
            `Start: Updating next OTA update URL:: ${JSON.stringify(targetUrl)}`
          );
          writeOTAUpdateURL(deviceId, targetUrl, (response: any) => {
            console.log("OTA URL update response from device::", response);
            AppLogger(
              {
                device_id: deviceInfo?.device_id,
                mac_address: deviceInfo?.device_reference,
              },
              "Home",
              "info",
              `End: Updating next OTA update URL response:: ${JSON.stringify(
                response
              )}`
            );
          });
        }
      })
      .catch((err: any) => {
        console.log("err: ", err);
        AppLogger(
          {
            device_id: deviceInfo.device_id,
            mac_address: deviceInfo.device_reference,
          },
          "Home",
          "info",
          `Failed to fetch next firmware:: ${JSON.stringify(err)}`
        );
      });
  };

  const updateChildDevice = (deviceId: number, deviceInfo: any) => {
    console.log("Update child device::", deviceId, deviceInfo);
    updateDevice(deviceId, deviceInfo)
      .then((response: any) => {
        console.log("Firmware update BE response", response);
        console.log(
          `Firmware is latest for ${response?.data?.device_name}::`,
          response?.data?.is_firmware_latest
        );
        if (response.status === 200) {
          console.log("BE Firmware update success");
        }
      })
      .catch((err: any) => {
        console.log("err: ", err);
      });
  };

  const getFirmwareDetailByVersion = (
    device_id: string,
    device: any,
    firmware: number,
    hardware: number
  ) => {
    console.log("getFirmwareDetailByVersion", device, firmware, hardware);
    getFirmwareDetail(firmware, hardware)
      .then((response) => {
        console.log("Current firmware detail::", response);
        AppLogger(
          {
            device_id: device.device_id,
            mac_address: device.device_reference,
          },
          "Home",
          "info",
          `Firmware (${JSON.stringify(
            firmware
          )}) detail response:: ${JSON.stringify(response?.data)}`
        );
        if (response?.data) {
          const deviceInfo: any = {};
          deviceInfo.firmware = response?.data?.id;
          deviceInfo.firmware_updated_on = new Date();
          deviceInfo.hw_version = hardware;
          updateChildDevice(device.device_id, deviceInfo);
        }
      })
      .catch((err: any) => {
        console.log("Error in getting current firmware:: ", err);
        AppLogger(
          {
            device_id: device.device_id,
            mac_address: device.device_reference,
          },
          "Home",
          "error",
          `Firmware (${JSON.stringify(
            firmware
          )}) detail back-end error:: ${JSON.stringify(err)}`
        );
      });
  };

  const getDeviceHardwareVersion = (
    deviceId: string,
    device: any,
    firmware: number
  ) => {
    console.log("Read hardware version::", deviceId, device, firmware);
    AppLogger(
      {
        device_id: device.device_id,
        mac_address: device.device_reference,
      },
      "Home",
      "info",
      `Start::Read hardware version`
    );
    readHardWareVersion(
      deviceId,
      {
        mac_address: device.device_reference,
        device_reference_id: device.device_id,
      },
      (response: any) => {
        console.log("Read hardware::", response);
        AppLogger(
          {
            device_id: device.device_id,
            mac_address: device.device_reference,
          },
          "Home",
          "info",
          `Read hardware version response::${JSON.stringify(response)}`
        );
        if (response.hardware) {
          bleConnectionState[device.device_reference].hardware =
            response?.hardware;
          updateDeviceState(
            bleConnectionState[device.device_reference],
            bleConnectionDispatch
          );
          if (device.firmware_version !== firmware) {
            AppLogger(
              {
                device_id: device.device_id,
                mac_address: device.device_reference,
              },
              "Home",
              "info",
              `Getting firmware (${JSON.stringify(firmware)}) detail`
            );
            getFirmwareDetailByVersion(
              deviceId,
              device,
              firmware,
              response.hardware
            );
          }
          AppLogger(
            {
              device_id: device.device_id,
              mac_address: device.device_reference,
            },
            "Home",
            "info",
            `Checking next available firmware version`
          );
          checkNextAvailableVersion(
            deviceId,
            device,
            firmware,
            response.hardware
          );
        }
      }
    );
  };

  const readDeviceFirmwareVersion = (deviceId: string, device: any) => {
    AppLogger(
      {
        device_id: device.device_id,
        mac_address: device.device_reference,
      },
      "Home",
      "info",
      `Start:: Read firmware version`
    );
    readDeviceVersion(
      deviceId,
      {
        mac_address: device.device_reference,
        device_reference_id: device.device_id,
      },
      (response: any, error: any) => {
        console.log(`Read firmware for :: ${deviceId}`, response);
        AppLogger(
          {
            device_id: device.device_id,
            mac_address: device.device_reference,
          },
          "Home",
          "info",
          `End:: Read firmware version:: ${JSON.stringify(response || error)}`
        );
        if (response.firmware) {
          bleConnectionState[device.device_reference].firmware =
            response?.firmware;
          // To read recording minutes If firmware is greater than 40
          if (response?.firmware > 40) {
            readDeviceRecordingTime(
              bleConnectionState[device.device_reference]
            );
          } else {
            setRecordMinForOldFirmwares(device.device_reference);
          }
          updateDeviceState(
            bleConnectionState[device.device_reference],
            bleConnectionDispatch
          );
          getDeviceHardwareVersion(deviceId, device, response.firmware);
        }
      }
    );
  };

  const subscribeToCTTHourlyChange = (
    dev_ref_id: number,
    device_id: string,
    mac_address: string
  ) => {
    subscribeCTCHoury(
      device_id,
      mac_address,
      (res: number | null, error: any) => {
        AppLogger(
          {
            device_id: dev_ref_id,
            mac_address: mac_address,
          },
          "Home",
          "info",
          `End:: Subscribe to CTC Hour response:: ${JSON.stringify(
            res || error
          )}`
        );
        console.log("CTC hourly change notify >>>>", res);
        if (res !== null) {
          readCTC(dev_ref_id, device_id, mac_address, AppLogger, (response: any) => {
            console.log("CTC callback::", response);
            const splittedCTC = response?.ctc?.split(",")?.slice(0, 24);
            console.log("24 Hour CTC", splittedCTC);
            if (splittedCTC?.length === 24) {
              updateCTC(dev_ref_id, splittedCTC, response?.mac_address);
            }
          });
        }
      }
    );
  };

  const readDeviceLock = (device: any) => {
    console.log("Read device lock::", device);
    AppLogger(
      {
        device_id: device.device_id,
        mac_address: device.mac_address,
      },
      "Home",
      "info",
      "Read device lock status"
    );
    readDeviceLockStatus(
      device?.device_reference,
      (res: number, error: any) => {
        console.log(`Device lock read response::${device.mac_address}::`, res);
        AppLogger(
          {
            device_id: device.device_id,
            mac_address: device.mac_address,
          },
          "Home",
          "info",
          `Read device lock status ${JSON.stringify(res || error)}`
        );
        if (res !== null) {
          bleConnectionState[device?.mac_address].is_locked = res === 1;
          setConnectionStatus(bleConnectionState, bleConnectionDispatch);
        }
      }
    );
  };

  const readBattery = (device: any) => {
    console.log("Home:: Read battery percent for ", device?.mac_address);
    AppLogger(
      {
        device_id: device?.device_id,
        mac_address: device?.mac_address,
      },
      "Home",
      "info",
      "Start:: Read battery percent"
    );
    readBatteryPercent(device?.device_reference, (battery: any, error: any) => {
      console.log("Home:: Read battery percent::", battery);
      AppLogger(
        {
          device_id: device.device_id,
          mac_address: device.mac_address,
        },
        "Home",
        "info",
        `End:: Read battery percent ${JSON.stringify(battery || error)}`
      );
      if (battery !== null) {
        updateBatteryPercent(battery, device?.mac_address);
      }
    });
  };

  const readCTCFromDevice = (device: any) => {
    AppLogger(
      {
        device_id: device?.device_id,
        mac_address: device?.mac_address,
      },
      "Home",
      "info",
      "Start:: Read CTC"
    );
    readCTC(
      device?.device_id,
      device?.device_reference,
      device?.mac_address,
      AppLogger,
      (response: any) => {
        console.log("CTC callback::", response);
        AppLogger(
          {
            device_id: device?.device_id,
            mac_address: device?.mac_address,
          },
          "Home",
          "info",
          `End:: Read CTC ${JSON.stringify(response)}`
        );
        const splittedCTC = response?.ctc?.split(",")?.slice(0, 24);
        console.log("24 Hour CTC", splittedCTC);
        if (splittedCTC?.length === 24) {
          updateCTC(device?.device_id, splittedCTC, response?.mac_address);
        }
      }
    );
  };

  const writeUploadServerURL = (child_id: number, device: any) => {
    AppLogger(
      {
        device_id: device?.device_id,
        mac_address: device?.mac_address,
      },
      "Home",
      "info",
      "Start:: Write upload URL"
    );
    writeUploadURL(device?.device_reference, child_id, (response: any) => {
      console.log("Upload URL response", response);
      AppLogger(
        {
          device_id: device?.device_id,
          mac_address: device?.mac_address,
        },
        "Home",
        "info",
        `End:: Write upload URL response after write:: ${JSON.stringify(
          response
        )}`
      );
    });
  };

  const subscribeCTCHourly = (device: any) => {
    AppLogger(
      {
        device_id: device?.device_id,
        mac_address: device?.mac_address,
      },
      "Home",
      "info",
      "Start:: Subscribe to CTC Hourly change..."
    );
    subscribeToCTTHourlyChange(
      device?.device_id,
      device?.device_reference,
      device?.mac_address
    );
  };

  const readFirmware = (device: any, ctc: any) => {
    AppLogger(
      {
        device_id: device?.device_id,
        mac_address: device?.mac_address,
      },
      "Home",
      "info",
      "Read device firmware version"
    );
    readDeviceFirmwareVersion(device?.device_reference, ctc);
  };

  const showRecordingMinuteCount = (mac_address: string, minutes: number) => {
    console.log("Updating recording minutes>>", mac_address, minutes);
    if (bleConnectionState[mac_address]) {
      bleConnectionState[mac_address].device_live_minutes =
        getMinutesToHours(minutes);
      updateDeviceState(bleConnectionState[mac_address], bleConnectionDispatch);
    }
  };

  console.log("Home page connected devices list >>>>>>>>>>>>>", ctcInfo);

  const readDeviceRecordingTime = (device: any) => {
    console.log("Read recording minutes count >>", device);
    AppLogger(
      {
        device_id: device?.device_id,
        mac_address: device?.mac_address,
      },
      "Home",
      "info",
      "Start:: Read recording minutes daily count"
    );
    readRecordingCountInMinutes(device?.device_reference, (response: any) => {
      console.log("Read recording minutes daily count response", response);
      AppLogger(
        {
          device_id: device?.device_id,
          mac_address: device?.mac_address,
        },
        "Home",
        "info",
        `End:: Read recording minutes daily count:: "${JSON.stringify(
          response
        )}`
      );
      if (response !== null) {
        showRecordingMinuteCount(device?.mac_address, response);
      }
    });
  };

  const readFromConnectedDevices = () => {
    console.log("Read from connected devices", ctcInfo);
    if (ctcInfo?.length) {
      setReadCTCInProgress(true);
      ctcInfo?.map((ctc: any, index: number) => {
        if (
          bleConnectionState[ctc?.device_reference] &&
          bleConnectionState[ctc?.device_reference].isConnected
        ) {
          ctc.battery = "N/A";
          // Read Battery percent
          readBattery(bleConnectionState[ctc?.device_reference]);
          // Read CTC
          readCTCFromDevice(bleConnectionState[ctc?.device_reference]);
          // Writing upload server URL
          writeUploadServerURL(
            ctc?.child_id,
            bleConnectionState[ctc?.device_reference]
          );
          //SUbscribe to CTC hourly change
          subscribeCTCHourly(bleConnectionState[ctc?.device_reference]);
          if (bleConnectionState[ctc?.device_reference]?.firmware > 40) {
            //Read device recording time in minutes
            readDeviceRecordingTime(bleConnectionState[ctc?.device_reference]);
          }
          if (!ctc.firmware_id) {
            //Read device firmware version
            readFirmware(bleConnectionState[ctc?.device_reference], ctc);
          }
          // Read device lock status
          readDeviceLock(bleConnectionState[ctc?.device_reference]);
        } else {
          ctc.battery = "N/A";
        }
        if (index === ctcInfo?.length - 1) {
          setTimeout(() => {
            setReadCTCInProgress(false);
          }, 5000);
        }
      });
      setCTCInfo((ctcInfo: any) => [...ctcInfo]);
    } else {
      setReadCTCInProgress(false);
    }
  };

  useEffect(() => {
    if (
      isNativePlatform &&
      isRoleExist(roleState, [AUTHENTICATION_PRIMARY_CAREGIVER])
    ) {
      if (!isReadCTCInProgress) {
        readFromConnectedDevices();
      }
    }

    ctcInfo?.map((ctc: any, index: number) => {
      if (
        bleConnectionState[ctc?.device_reference] &&
        bleConnectionState[ctc?.device_reference].isConnected
      ) {
        if (
          ctc.device_reference ===
          bleConnectionState[ctc?.device_reference]?.mac_address
        ) {
          // Battery
          if (bleConnectionState[ctc?.device_reference].batteryPercent >= 0) {
            ctc.battery =
              bleConnectionState[ctc?.device_reference].batteryPercent + "%";
          }
          //Recording minutes
          if (
            bleConnectionState[ctc?.device_reference]?.firmware > 40 &&
            bleConnectionState[ctc?.device_reference]?.device_live_minutes
          ) {
            ctc.device_live_minutes =
              bleConnectionState[ctc?.device_reference]?.device_live_minutes;
          } else {
            ctc.device_live_minutes = getMinutesToHours(ctc.total_ns);
          }
        }
      } else {
        ctc.battery = "N/A";
        ctc.recording = "N/A";
        ctc.device_live_minutes = "N/A";
      }
    });

    setTimeout(() => {
      setCTCInfo((ctcInfo: any) => [...ctcInfo]);
    }, 500);
  }, [bleConnectionState]);

  console.log("Disconnected devices::", disconnectedDevices);
  console.log("Connected devices::", connectedDevices);

  return (
    <IonPage>
      <Hamburger></Hamburger>
      <IonContent fullscreen>
        <IonGrid>
          {/* <IonRow> */}
          <Classroom
            onClassroomChange={onClassroomChange}
            onSchoolChange={onSchoolChange}
          ></Classroom>
          {/* </IonRow> */}
        </IonGrid>
        <AppDataTable
          loading={isLoading}
          headers={tableHeader.disconnected}
          data={disconnectedDevices}
          order="asc"
          orderBy="total_ctc"
          searchBy="child"
          title={"DISCONNECTED"}
          enableSearch={true}
          totalRecords={disconnectedDevices?.length}
          start={start}
          reloadData={reloadData}
          rowsPerPage={10}
          enableRefresh={true}
        ></AppDataTable>

        {isTeacher && (
          <AppDataTable
            loading={isLoading}
            headers={tableHeader.connected}
            data={connectedDevices}
            order="asc"
            orderBy="total_ctc"
            searchBy="child"
            title={"CONNECTED"}
            enableSearch={true}
            totalRecords={connectedDevices?.length}
            start={start}
            reloadData={reloadData}
            rowsPerPage={10}
            enableRefresh={true}
          ></AppDataTable>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Home;
