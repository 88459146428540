import { millisecondsToSeconds } from "date-fns";
import { getTimezoneOffset } from "date-fns-tz";
import deviceServices from "../constant/DeviceServices.json";
import { addLog } from "./Ble";

type DeviceInfo = {
  id: number;
  device_reference: string;
  device_name: string;
  bluetooth_access: boolean;
  wifi_access: boolean;
  last_synced_at: string;
  app_version: string;
  firmware_updated_on: string;
  tf_version: string;
  audio_record: boolean;
  audio_record_time: string;
  hw_version: number;
  firmware: number;
};

export const bleIntToBytes = (value: any) => {
  const bytes = new Uint8Array(8);
  bytes[0] = value & 0xff;
  bytes[1] = (value >> 8) & 0xff;
  bytes[2] = (value >> 16) & 0xff;
  bytes[3] = (value >> 24) & 0xff;
  return bytes;
};

export function stringToBytes(string: any, lastchunk: any, off: any) {
  console.log("string to array started");
  const array = new Uint8Array(string.length + 2);
  array[0] = lastchunk;
  array[1] = off;
  for (let i = 0, l = string.length; i < l; i++) {
    array[i + 2] = string.charCodeAt(i);
  }
  return array;
}

export const unsubscribeCharacteristic = (
  deviceId: string,
  service: string,
  characteristic: string
) => {
  console.log("unsubscribe characteristic");
  (window as any).bluetoothle.unsubscribe(
    function (res: any) {
      console.log("Unsubscribe response", res);
    },
    function (err: any) {
      console.log("Unsubscribe error", err);
    },
    {
      address: deviceId,
      service,
      characteristic,
    }
  );
};

export const readDevice = (
  deviceId: string,
  service: string,
  characteristic: string,
  callback: any
) => {
  (window as any).bluetoothle.read(
    function (res: any) {
      console.log(`Read response:: ${characteristic}`, res);
      if (res?.value) {
        const bytes = (window as any).bluetoothle.encodedStringToBytes(
          res.value
        );
        const value = new Int32Array(bytes.buffer);
        callback(value[0], null);
      }
    },
    function (err: any) {
      console.log("Read error::", err);
      callback(null, err);
    },
    {
      address: deviceId,
      service,
      characteristic,
    }
  );
};

export const readLongDevice = (
  deviceId: string,
  service: string,
  characteristic: string,
  callback: any
) => {
  console.log("Read Long Device", characteristic);

  (window as any).bluetoothle.read(
    function (res: any) {
      callback(res, null);
    },
    function (err: any) {
      console.log("Read error::", err);
      callback(null, err);
    },
    {
      address: deviceId,
      service,
      characteristic,
    }
  );
};

export const writeIntValues = (
  deviceId: string,
  service: string,
  characteristic: string,
  value: number | any,
  callback: any
) => {
  new Promise(function (resolve, reject) {
    const bytes = bleIntToBytes(value);
    (window as any).bluetoothle.write(resolve, reject, {
      address: deviceId,
      service,
      characteristic,
      value: (window as any).bluetoothle.bytesToEncodedString(bytes),
    });
  }).then(
    function writeSuccess(res) {
      console.log("Write response", res);
      callback(res, null);
    },
    function writeError(err) {
      console.log("Write error", err);
      callback(null, err);
    }
  );
};

export const writeStringValues = (
  deviceId: string,
  service: string,
  characteristic: string,
  string_value: string,
  callback: any
) => {
  console.log("Write value >>>", string_value);
  new Promise(function (resolve, reject) {
    const size = 18;
    const numChunks = Math.ceil(string_value.length / size);
    let chunks;
    let offset = 0;
    let last_chunk = 0;
    for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
      if (i === numChunks - 1) {
        last_chunk = 1;
      }
      chunks = string_value.substr(o, size);
      const strToBytes = stringToBytes(chunks, last_chunk, offset);
      (window as any).bluetoothle.write(resolve, reject, {
        address: deviceId,
        service,
        characteristic,
        value: (window as any).bluetoothle.bytesToEncodedString(strToBytes),
      });
      offset += chunks.length;
    }
  }).then(
    function writeSuccess(res: any) {
      console.log("Write success", res);
      callback(res, null);
    },
    function writeError(err) {
      console.log("Write error", err);
      callback(null, err);
    }
  );
};

export const readLongCharacteristics = (
  deviceId: string,
  service: string,
  characteristic: string,
  callback: any
) => {
  let string_value = "";
  (window as any).bluetoothle.subscribe(
    function (res: any) {
      console.log("Long characters subscribe response", deviceId, res);
      if (res?.value) {
        const stringToBytes = (window as any).bluetoothle.encodedStringToBytes(
          res.value
        );
        const ctcArray = new Uint8Array(stringToBytes);
        const splittedArray: any = ctcArray.slice(2, ctcArray?.length);
        const byteToString = String.fromCharCode.apply(null, splittedArray);
        string_value += byteToString;
        if (stringToBytes[0] === 1) {
          callback(string_value, null);
          unsubscribeCharacteristic(deviceId, service, characteristic);
          string_value = "";
        }
      }
    },

    function (err: any) {
      console.log("Long characters subscribe error", err);
      unsubscribeCharacteristic(deviceId, service, characteristic);
      callback(null, err);
      string_value = "";
    },
    {
      address: deviceId,
      service,
      characteristic,
    }
  );

  readLongDevice(deviceId, service, characteristic, (response: any) => {
    console.log(`Read long characters::${deviceId}`, response);
  });
};

const subscribeToCharacteristics = (
  deviceId: string,
  service: string,
  characteristic: string,
  callback: any
) => {
  (window as any).bluetoothle.subscribe(
    function (res: any) {
      console.log("Subscribe Response for ", deviceId, res);
      if (res?.value) {
        console.log("Subscribe Read response::", res);
        const bytes = (window as any).bluetoothle.encodedStringToBytes(
          res.value
        );
        const value = new Int32Array(bytes.buffer);
        console.log("Subscribe resonse:: Parsed", value);
        callback(value[0], null);
      }
    },
    function (err: any) {
      console.log(`Subscribe response error.....${deviceId}`, err);
      callback(null, err);
    },
    {
      address: deviceId,
      service,
      characteristic,
    }
  );
};

export const readBatteryPercent = (deviceId: string, callback: any) => {
  console.log("Read battery percent::", deviceId);
  readDevice(
    deviceId,
    deviceServices.luetInfo.service,
    deviceServices.luetInfo.batteryLevel,
    (response: any, error: any) => {
      console.log("Battery read response::", response);
      callback(response, error);
      subscribeToCharacteristics(
        deviceId,
        deviceServices.luetInfo.service,
        deviceServices.luetInfo.batteryLevel,
        (response: any, error: any) => {
          console.log("Subscribed battery read response", response);
          callback(response, error);
        }
      );
    }
  );
};

export const readDeviceLockStatus = (deviceId: string, cb: any) => {
  readDevice(
    deviceId,
    deviceServices.luetInfo.service,
    deviceServices.luetInfo.deny_power,
    (response: any, error: any) => {
      console.log("Device lock read response::", response);
      cb(response, error);
    }
  );
};

export const writeDeviceLock = (
  deviceId: string,
  value: number,
  callback: any
) => {
  console.log("Write device lock::", value);
  writeIntValues(
    deviceId,
    deviceServices.luetInfo.service,
    deviceServices.luetInfo.deny_power,
    value,
    (response: any, error: any) => {
      console.log("Write device lock response::", response);
      if (response.status === "written") {
        readDeviceLockStatus(deviceId, callback);
      } else {
        callback(response, error);
      }
    }
  );
};

export const subscribeLongCharacteristics = (
  deviceId: string,
  service: string,
  characteristic: string,
  callback: any
) => {
  let string_value = "";
  (window as any).bluetoothle.subscribe(
    function (res: any) {
      console.log("Subscribe Response", deviceId, res);
      if (res?.value) {
        const stringToBytes = (window as any).bluetoothle.encodedStringToBytes(
          res.value
        );
        const ctcArray = new Uint8Array(stringToBytes);
        const splittedArray: any = ctcArray.slice(2, ctcArray?.length);
        const byteToString = String.fromCharCode.apply(null, splittedArray);
        string_value += byteToString;
        if (stringToBytes[0] === 1) {
          callback(string_value);
          string_value = "";
        }
      }
    },

    function (err: any) {
      console.log("Subscribe error", err);
      callback(null, err);
    },
    {
      address: deviceId,
      service,
      characteristic,
    }
  );
};

export const subscribeCTCHoury = (
  deviceId: string,
  mac_address: string,
  callback: any
) => {
  subscribeToCharacteristics(
    deviceId,
    deviceServices.luetInfo.service,
    deviceServices.luetInfo.ctcHourlyCount,
    (response: any, error: any) => {
      console.log("Subscribed CTC hourly response", response);
      callback(response, error);
    }
  );
};

export const readRecordingCountInMinutes = (
  deviceId: string,
  callback: any
) => {
  console.log("read recording minutes count >>>>>", deviceId);
  readDevice(
    deviceId,
    deviceServices.luetInfo.service,
    deviceServices.luetInfo.ctcRecordingMinutes,
    (response: any) => {
      console.log(`Read recording minutes count::${deviceId}`, response);
      callback(response);
      console.log("Subscribing for recording minutes count >>>>>", deviceId);
      subscribeToCharacteristics(
        deviceId,
        deviceServices.luetInfo.service,
        deviceServices.luetInfo.ctcRecordingMinutes,
        callback
      );
    }
  );
};

export const readWiFiSetupStatus = (deviceId: string, callback: any) => {
  console.log("readWIFIInfo");
  readDevice(
    deviceId,
    deviceServices.luetInfo.service,
    deviceServices.luetInfo.wifi_status,
    (response: any, error: any) => {
      console.log(`WiFi status::${deviceId}`, response);
      callback(response, error);
    }
  );
};

const readAndSubscribeWiFiStatus = (deviceId: string, callback: any) => {
  readDevice(
    deviceId,
    deviceServices.luetInfo.service,
    deviceServices.luetInfo.wifi_status,
    (response: any, error: any) => {
      console.log(`Read WiFi status::${deviceId}`, response);
      callback(response, error);
      subscribeToCharacteristics(
        deviceId,
        deviceServices.luetInfo.service,
        deviceServices.luetInfo.wifi_status,
        callback
      );
    }
  );
};

const removePasswordFourSlot = (deviceId: string, callback: any) => {
  console.log("Remove slot 4 password to trigger connecting");
  new Promise(function (resolve, reject) {
    const strToBytes = stringToBytes("", 1, 0);
    (window as any).bluetoothle.write(resolve, reject, {
      address: deviceId,
      service: deviceServices.luetInfo.service,
      characteristic: deviceServices.luetInfo.wifi_pwd_4,
      value: (window as any).bluetoothle.bytesToEncodedString(strToBytes),
    });
  }).then(
    function writeSuccess(res: any) {
      console.log(`Remove password remove`, res);
    },
    function writeError(err: any) {
      console.log(`Remove password error`, err);
      callback(null, err);
    }
  );
};

const writeWifiPwd = (pwd: string, deviceId: string, callback: any) => {
  console.log("Wifi password");
  writeStringValues(
    deviceId,
    deviceServices.luetInfo.service,
    deviceServices.luetInfo.wifi_pwd_1,
    pwd,
    (response: any, error: any) => {
      console.log(
        `WiFi password write response for ${deviceId}:: ${JSON.stringify(
          response
        )}`,
        response
      );
      if (response.status === "written") {
        readLongCharacteristics(
          deviceId,
          deviceServices.luetInfo.service,
          deviceServices.luetInfo.wifi_pwd_1,
          (response: any, error: any) => {
            console.log(
              `WiFi password read response for :: ${JSON.stringify(response)}`,
              response
            );
            if (response === pwd) {
              console.log("Matching password for ::", deviceId);
              removePasswordFourSlot(deviceId, callback);
            } else {
              callback(null, error);
            }
          }
        );
      } else {
        callback(null, error);
      }
    }
  );
};

export const writeWifiSSID = (
  ssid: string,
  pwd: string,
  deviceId: string,
  callback: any
) => {
  unsubscribeCharacteristic(
    deviceId,
    deviceServices.luetInfo.service,
    deviceServices.luetInfo.wifi_status
  );
  writeStringValues(
    deviceId,
    deviceServices.luetInfo.service,
    deviceServices.luetInfo.wifi_ssid_1,
    ssid,
    (response: any, error: any) => {
      console.log(`SSID write response for ${deviceId}::`, response);
      if (response.status === "written") {
        readLongCharacteristics(
          deviceId,
          deviceServices.luetInfo.service,
          deviceServices.luetInfo.wifi_ssid_1,
          (response: any, error: any) => {
            console.log(
              `WiFi SSID read response for ${deviceId} :: ${JSON.stringify(
                response
              )}`,
              response
            );
            if (response === ssid) {
              console.log("Matching SSID for ::", deviceId);
              writeWifiPwd(pwd, deviceId, (response: any) => {
                callback(response, null);
              });
            } else {
              callback(null, error);
            }
          }
        );
      } else {
        callback(null, error);
      }
    }
  );

  setTimeout(() => {
    readAndSubscribeWiFiStatus(deviceId, callback);
  }, 2000);
};

export const buzzDevice = (deviceId: any, value: number) => {
  console.log("Buzz Device");
  writeIntValues(
    deviceId,
    deviceServices.luetInfo.service,
    deviceServices.luetInfo.findmy_device,
    value,
    (response: any) => {
      console.log("Buzz device write::", response);
    }
  );
};

export const writeDeviceName = (
  deviceId: string,
  device_name: string,
  callback: any
) => {
  writeStringValues(
    deviceId,
    deviceServices.luetInfo.service,
    deviceServices.luetInfo.device_name,
    device_name,
    (response: any, error: any) => {
      console.log("Device name write response::", response);
      if (response.status === "written") {
        unsubscribeCharacteristic(
          deviceId,
          deviceServices.luetInfo.service,
          deviceServices.luetInfo.device_name
        );
        setTimeout(() => {
          readDeviceName(response.address, callback);
        }, 1000);
      } else {
        callback(response, error);
      }
    }
  );
};

export const readDeviceName = (deviceId: string, callback: any) => {
  console.log("Read Device Name");
  readLongCharacteristics(
    deviceId,
    deviceServices.luetInfo.service,
    deviceServices.luetInfo.device_name,
    (response: any, error: any) => {
      console.log("Device name read response::", response);
      if (response) {
        callback(response, null);
      } else {
        callback(null, error);
      }
    }
  );
};

const readCTCGoal = (deviceId: string, callback: any) => {
  console.log("Read CTC Target");
  readDevice(
    deviceId,
    deviceServices.luetInfo.service,
    deviceServices.luetInfo.ctc_target,
    (response: any, error: any) => {
      callback(response, error);
    }
  );
};

export const writeCTCGoal = (deviceId: string, goal: number, callback: any) => {
  console.log("Write CTC Goal");
  writeIntValues(
    deviceId,
    deviceServices.luetInfo.service,
    deviceServices.luetInfo.ctc_target,
    goal || 320,
    (response: any, error: any) => {
      console.log("CTC Goal write::", response);
      if (response.status === "written") {
        readCTCGoal(deviceId, callback);
      } else {
        callback(response, error);
      }
    }
  );
};

export const readUploadURL = (deviceId: string, callback: any) => {
  console.log("Read Upload URL");
  readLongCharacteristics(
    deviceId,
    deviceServices.luetInfo.service,
    deviceServices.luetInfo.uploadURL,
    (response: any, error: any) => {
      console.log("Upload URL read response::", response);
      if (response) {
        callback(response, null);
      } else {
        callback(null, error);
      }
    }
  );
};

export const readTimeZone = (deviceId: string, callback: any) => {
  console.log("Read timezone:::::", deviceId);
  readDevice(
    deviceId,
    deviceServices.luetInfo.service,
    deviceServices.luetInfo.tz_offset,
    (response: any, error: any) => {
      console.log("Read TZ offset response::", response);
      callback(response, error);
    }
  );
};

export const writeTimeZone = (deviceId: string, tz: string, callback: any) => {
  console.log("Write tz offset", tz);
  const tzOffset = getTimezoneOffset(tz);
  const tzInSeconds: any = millisecondsToSeconds(tzOffset);
  console.log("TZ in seconds::", tzInSeconds);
  writeIntValues(
    deviceId,
    deviceServices.luetInfo.service,
    deviceServices.luetInfo.tz_offset,
    tzInSeconds,
    (response: any, error: any) => {
      console.log("Write timezone response::", response);
      if (response.status === "written") {
        readTimeZone(deviceId, callback);
      } else {
        callback(response, error);
      }
    }
  );
};

export const checkAndUpdateTimeZone = (
  deviceId: string,
  tz: string,
  callback: any
) => {
  console.log("Check and update timezone::", tz);
  readTimeZone(deviceId, (response: any, error: any) => {
    console.log("Device timezone", response);
    if (response !== null) {
      const tzOffset = getTimezoneOffset(tz);
      const tzInSeconds: any = millisecondsToSeconds(tzOffset);
      console.log("New timezone offset::", tzInSeconds);
      if (tzInSeconds === response) {
        console.log("Both timezone same");
      } else {
        writeTimeZone(deviceId, tz, (response: any) => {
          console.log("TimeZone write response::", response);
          callback(response, null);
        });
      }
    } else {
      callback(null, error);
    }
  });
};

export const readDeviceVersion = (
  deviceId: string,
  callbackOptions: any,
  callback: any
) => {
  console.log("readDeviceESPVersion");
  readDevice(
    deviceId,
    deviceServices.luetOtaInfo.service,
    deviceServices.luetOtaInfo.esp_firmw_ver,
    (response: any, error: any) => {
      console.log(`Firmware version response::${deviceId}`, response);
      callback(
        { firmware: response, address: deviceId, ...callbackOptions },
        error
      );
    }
  );
};

export const readHardWareVersion = (
  deviceId: string,
  callbackOptions: any,
  callback: any
) => {
  console.log("readHardWareVersion", deviceId);
  readDevice(
    deviceId,
    deviceServices.luetInfo.service,
    deviceServices.luetInfo.hardwareVersion,
    (response: any, error: any) => {
      console.log(`Hardware version response::${deviceId}`, response);
      callback(
        { hardware: response, address: deviceId, ...callbackOptions },
        error
      );
    }
  );
};

export const readOTAURL = (deviceId: string, callback: any) => {
  console.log("Read OTA URL");
  readLongCharacteristics(
    deviceId,
    deviceServices.luetOtaInfo.service,
    deviceServices.luetOtaInfo.ota_esp,
    (response: any, error: any) => {
      console.log("OTA URL read response::", response);
      callback(response, error);
    }
  );
};

export const writeOTAUpdateURL = (
  deviceId: string,
  ota_url: string,
  callback: any
) => {
  writeStringValues(
    deviceId,
    deviceServices.luetOtaInfo.service,
    deviceServices.luetOtaInfo.ota_esp,
    ota_url,
    (response: any, error: any) => {
      console.log("OTA URL write response::", response);
      if (response.status === "written") {
        readOTAURL(response.address, callback);
      } else {
        callback(response, error);
      }
    }
  );
};

const readBuzzVolume = (deviceId: string, callback: any) => {
  console.log("Read Buzz Volume");
  readDevice(
    deviceId,
    deviceServices.luetInfo.service,
    deviceServices.luetInfo.buzzer_vol,
    (response: any, error: any) => {
      console.log("Buzz volume read response::", response);
      if (callback) {
        callback(response, error);
      }
    }
  );
};

export const writeBuzzVolume = (
  deviceId: string,
  value: number,
  callback?: any
) => {
  writeIntValues(
    deviceId,
    deviceServices.luetInfo.service,
    deviceServices.luetInfo.buzzer_vol,
    value,
    (response: any, error: any) => {
      console.log("Write buzz volume response::", response);
      if (response.status === "written") {
        readBuzzVolume(deviceId, callback);
      } else if (callback) {
        callback(response, error);
      }
    }
  );
};

export const writeChildId = (
  deviceId: string,
  childId: any,
  callback?: any
) => {
  console.log(`Write child id for ${deviceId}:: ${childId}`);
  writeStringValues(
    deviceId,
    deviceServices.luetInfo.service,
    deviceServices.luetInfo.childID,
    childId,
    (response: any, error: any) => {
      console.log(`Write child id response for ${deviceId}::`, response);
      if (response.status === "written") {
        callback(response, null);
      } else {
        callback(null, error);
      }
    }
  );
};

export const readDeviceError = (deviceId: string, callback: any) => {
  readLongCharacteristics(
    deviceId,
    deviceServices.luetInfo.service,
    deviceServices.luetInfo.error,
    (response: any, error: any) => {
      console.log(
        `Read error from device ${deviceId}:: ${JSON.stringify(response)}`,
        response
      );
      callback(response, error);
    }
  );
};

export const clearDeviceError = (deviceId: string, callback: any) => {
  console.log("Clear device error::", deviceId);
  writeStringValues(
    deviceId,
    deviceServices.luetInfo.service,
    deviceServices.luetInfo.error,
    "",
    (response: any, error: any) => {
      console.log(
        `Device error cleared for ${deviceId}:: ${JSON.stringify(response)}`,
        response
      );
      if (response.status === "written") {
        callback(response, null);
      } else {
        callback(null, error);
      }
    }
  );
};

//----------------------------------------------------------------------

const removeWiFiInDevice = (
  deviceId: string,
  device_ref_id: number,
  characteristic: string,
  wifi_slot: string,
  AppLogger: any
) => {
  console.log(`Remove ${wifi_slot} characteristic::`, characteristic);
  addLog(
    AppLogger,
    {
      device_id: device_ref_id,
      mac_address: deviceId,
    },
    "info",
    `Start:: Reset WiFi slot ${wifi_slot}`
  );
  new Promise(function (resolve, reject) {
    const strToBytes = stringToBytes("", 1, 0);
    (window as any).bluetoothle.write(resolve, reject, {
      address: deviceId,
      service: deviceServices.luetInfo.service,
      characteristic,
      value: (window as any).bluetoothle.bytesToEncodedString(strToBytes),
    });
  }).then(
    function writeSuccess(res) {
      console.log(`WiFi ${wifi_slot} reset response::`, res);
      addLog(
        AppLogger,
        {
          device_id: device_ref_id,
          mac_address: deviceId,
        },
        "info",
        `End:: Reset WiFi slot ${wifi_slot} response:: ${JSON.stringify(res)}`
      );
    },
    function writeError(err) {
      console.log(`WiFI ${wifi_slot} write error::`, err);
      addLog(
        AppLogger,
        {
          device_id: device_ref_id,
          mac_address: deviceId,
        },
        "error",
        `End:: Reset WiFi slot ${wifi_slot} error:: ${JSON.stringify(err)}`
      );
    }
  );
};

export const resetAllWiFI = (device: DeviceInfo, AppLogger: any) => {
  console.log("Reset all Wi-Fi slots for ::", device);
  [1, 2, 3, 4].forEach((slot: number) => {
    // TO remove SSID
    console.log("Resetting Wi-FI SSID for slot::", slot);
    removeWiFiInDevice(
      device?.device_reference,
      device?.id,
      (deviceServices as any).luetInfo[`wifi_ssid_${slot}`],
      `wifi_ssid_${slot}`,
      AppLogger
    );
    // To remove passwords
    console.log("Resetting  Wi-FI password for slot::", slot);
    removeWiFiInDevice(
      device?.device_reference,
      device?.id,
      (deviceServices as any).luetInfo[`wifi_pwd_${slot}`],
      `wifi_pwd_${slot}`,
      AppLogger
    );
  });
};

export const readCTC = (
  dev_ref_id: number,
  deviceId: string,
  mac_address: string,
  AppLogger: any,
  cb: any
) => {
  console.log("readCTC >>>>>", deviceId);
  readLongCharacteristics(
    deviceId,
    deviceServices.luetInfo.service,
    deviceServices.luetInfo.totConvTurn,
    (response: any, error: any) => {
      addLog(
        AppLogger,
        { device_id: dev_ref_id, mac_address },
        "info",
        `CTC read response for ${deviceId}::${response}`
      );
      console.log(`CTC read response for ${deviceId}::`, response);
      if (response) {
        cb({ ctc: response, mac_address }, error);
        addLog(
          AppLogger,
          { device_id: dev_ref_id, mac_address },
          "info",
          `CTC read response for ${deviceId}::${response}`
        );
        subscribeLongCharacteristics(
          deviceId,
          deviceServices.luetInfo.service,
          deviceServices.luetInfo.totConvTurn,
          (response: any) => {
            console.log(
              `Subscribed CTC read response for ${deviceId}::`,
              response
            );
            if (response) {
              cb({ ctc: response, mac_address });
            } else {
              cb(null);
            }
          }
        );
      } else {
        cb(null, error);
      }
    }
  );
};
