import { Capacitor } from "@capacitor/core";
import deviceServices from "../constant/DeviceServices.json";

const platform = Capacitor.getPlatform();
const isNativePlatform = Capacitor.isNativePlatform();

export const addLog = (
  AppLogger: any,
  bleDevice: any,
  errorType: string,
  description: string
) => {
  if (AppLogger) {
    AppLogger(bleDevice, "BLE", errorType, description);
  }
}

export const BleConnection = (
  bleDevice: any,
  connectionType: any,
  AppLogger: any,
  callback: any
) => {
  console.log("Platform >>>>", platform);
  console.log("BleConnection >>>>", connectionType, bleDevice);
  let retryConnectionAttempt = 0;

  const saveBleLog = (errorType: string, description: string) => {
    addLog(AppLogger, bleDevice, errorType, description);
  }

  const onDeviceSelect = (device: any) => {
    retryConnectionAttempt = 1;
    localStorage.isConnectingInProgress = true;
    console.log("onDeviceSelect >>>>>>>><>", device?.address);
    saveBleLog("info", "On device select");
    verifyIsDeviceConnected(device);
  };

  const onDeviceReconnectById = (device: any) => {
    localStorage.isConnectingInProgress = true;
    console.log("onDeviceReconnectById >>>>>>>>>", device);
    saveBleLog("info", "On device reconnect by id");
    verifyIsDeviceConnected(device);
  };

  const verifyIsDeviceConnected = (device: any) => {
    console.log("verifyIsDeviceConnected");
    (window as any).bluetoothle.isConnected(
      function (res: any) {
        console.log("BLE::isConnected response", res);
        saveBleLog("info", "On device reconnect by id");
        if (res?.isConnected) {
          if (platform === "android") {
            isDeviceBonded(res);
          } else {
            getBleServiceForIOS(res);
          }
          delete localStorage.isConnectingInProgress;
        } else {
          reconnectDevice(res);
        }
      },
      function (err: any) {
        console.log("isConnected error", err);
        reconnectDevice(err);
      },
      {
        address: device.address,
      }
    );
  };

  const reconnectDevice = (res?: any) => {
    console.log("reconnectDevice", res);
    saveBleLog("info", "Reconnect device");
    (window as any).bluetoothle.reconnect(
      function (res: any) {
        console.log("Reconnect response >>>>>>>>>>>>>>>>>>>>>>>>>", res);
        saveBleLog(
          "info",
          `Reconnect response: ${JSON.stringify(res)}`
        );
        if (res?.status === "connected") {
          if (platform === "android") {
            bondDevice(res);
          } else {
            getBleServiceForIOS(res);
          }
          delete localStorage.isConnectingInProgress;
        } else if (res?.status === "disconnected") {
          console.log(
            "Checking for auto-connect capabilities in reconnect success"
          );
          handleBleAutoConnect();
        } else {
          disconnectConnectedDevice(res);
        }
      },
      function (err: any) {
        console.log("Reconnect device error", err);
        saveBleLog(
          "error",
          `Reconnect error: ${JSON.stringify(err)}`
        );
        if (err?.error === "isNotDisconnected") {
          if (platform === "android") {
            discoverDevice(err);
          } else {
            getBleServiceForIOS(err);
          }
        } else if (err?.error === "neverConnected") {
          connectNewBle(err);
        } else if (err?.error === "disconnected") {
          console.log(
            "Checking for auto-connect capabilities in reconnect error case"
          );
          handleBleAutoConnect();
        } else {
          disconnectConnectedDevice(err);
        }
      },
      {
        address: res.address,
      }
    );
  };

  const isDeviceBonded = (device: any) => {
    console.log("isDeviceBonded", device);
    saveBleLog("info", `Calling is device bonded`);
    (window as any).bluetoothle.isBonded(
      function (res: any) {
        console.log("isBonded response", res);
        saveBleLog(
          "info",
          `Is device bonded response: ${JSON.stringify(res)}`
        );
        if (res?.isBonded) {
          discoverDevice(device);
        } else {
          bondAgain(device);
        }
      },
      function (err: any) {
        console.log("isBonded error", err);
        saveBleLog(
          "error",
          `Is device bonded error: ${JSON.stringify(err)}`
        );
        connectNewBle(err);
      },
      {
        address: device.address,
      }
    );
  };

  const handleBleAutoConnect = () => {
    if (platform !== "ios") {
      (window as any).bluetoothle.isBonded(
        function (res: any) {
          console.log("AutoConnect:isBonded response", res);
          saveBleLog(
            "info",
            `AutoConnect:: Is bonded response: ${JSON.stringify(res)}`
          );
          if (res?.isBonded) {
            callback({
              name: res?.name,
              address: res?.address,
              mac_address: bleDevice?.mac_address,
              status: "disconnected",
            });

            setTimeout(() => {
              reconnectDevice(res);
            }, 1000);
          } else {
            callback({
              name: res?.name,
              address: res?.address,
              mac_address: bleDevice?.mac_address,
              status: "pairingLost",
            });
          }
        },
        function (err: any) {
          console.log("isBonded error >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", err);
          saveBleLog(
            "error",
            `AutoConnect:: Is bonded error: ${JSON.stringify(err)}`
          );
        },
        {
          address: bleDevice.address,
        }
      );
    } else {
      (window as any).bluetoothle.retrievePeripheralsByAddress(
        function (res: any) {
          console.log("retrievePeripheralsByAddress response", res);
          saveBleLog(
            "info",
            `AutoConnect:: Retrive peripherals by address response:: ${JSON.stringify(
              res
            )}`
          );
          if (res?.length) {
            reconnectDevice(res?.[0]);
            callback({
              name: res[0]?.name,
              address: res[0]?.address,
              mac_address: bleDevice?.mac_address,
              status: "disconnected",
            });
          } else {
            callback({
              name: bleDevice?.name,
              address: bleDevice?.address,
              mac_address: bleDevice?.mac_address,
              status: "pairingLost",
            });
          }
        },
        function (err: any) {
          console.log(
            "retrievePeripheralsByAddress error >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",
            err
          );
          saveBleLog(
            "error",
            `AutoConnect:: Retrive peripherals by address error:: ${JSON.stringify(
              err
            )}`
          );
        },
        {
          addresses: [bleDevice.address],
        }
      );
    }
  };

  const connectNewBle = (device: any) => {
    console.log("connectNewBle", device);
    saveBleLog("info", `Calling connect new ble device`);
    (window as any).bluetoothle.connect(
      function (res: any) {
        console.log("Connect response:: >>>>>>>>>>>>>>>>>>>>>>>>>>", res);
        saveBleLog(
          "info",
          `Connect new ble response:: ${JSON.stringify(res)}`
        );
        if (res?.status === "connected") {
          if (platform === "android") {
            bondDevice(res);
          } else {
            getBleServiceForIOS(res);
          }
        } else if (res?.status === "disconnected") {
          console.log(
            "Checking for auto-connect capabilities on connect in connect"
          );
          handleBleAutoConnect();
        }
        if (platform === "ios" && res?.error === "connect") {
          callback({ ...res, status: "pairingLost" });
        }

        if (platform === "android" && res?.status === "unbonded") {
          callback({ ...res, status: "pairingLost" });
        }
      },
      function (err: any) {
        console.log("Connection error >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", err);
        saveBleLog(
          "error",
          `Connect new ble error:: ${JSON.stringify(err)}`
        );
        disconnectConnectedDevice(err);
      },
      {
        address: device.address,
        autoConnect: true,
      }
    );
  };

  const disconnectConnectedDevice = (device: any) => {
    console.log("disconnectConnectedDevice");
    saveBleLog("info", `Calling disconnect connected device`);
    (window as any).bluetoothle.disconnect(
      function (res: any) {
        console.log("Device disconnected::", res);
        saveBleLog(
          "info",
          `Disconnect device: ${JSON.stringify(res)}`
        );
        if (res?.status === "disconnected") {
          closeExistingConnection(res);
        }
      },
      function (err: any) {
        console.log("Disconnect error", err);
        saveBleLog(
          "error",
          `Disconnect device error: ${JSON.stringify(err)}`
        );
        if (err?.error === "isDisconnected") {
          closeExistingConnection(err);
        }

        if (err?.error === "neverConnected") {
          connectNewBle(err);
        }
      },
      {
        address: device.address,
      }
    );
  };

  const closeExistingConnection = (device: any) => {
    console.log("closeExistingConnection");
    saveBleLog("info", `Calling close existing connection`);
    (window as any).bluetoothle.close(
      function (res: any) {
        console.log("Device connection closed::", res);
        saveBleLog(
          "info",
          `Close existing device: ${JSON.stringify(res)}`
        );
        if (res?.status === "closed") {
          console.log("retryConnectionAttempt >>>>", retryConnectionAttempt);
          if (retryConnectionAttempt < 2) {
            reconnectDevice(res);
          } else {
            retryConnect(res);
          }
        }
      },
      function (err: any) {
        console.log("Device connection close error", err);
        saveBleLog(
          "error",
          `Close existing device error: ${JSON.stringify(err)}`
        );
        if (err?.error === "neverConnected") {
          connectNewBle(err);
        }
      },
      {
        address: device.address,
      }
    );
  };

  const retryConnect = (device: any) => {
    console.log("retryConnect");
    saveBleLog("error", "Calling retry connect");
    (window as any).bluetoothle.connect(
      function (res: any) {
        console.log("Retry:: Connect response", res);
        saveBleLog(
          "info",
          `Retry connect response:: ${JSON.stringify(res)}`
        );
        if (res?.status === "connected") {
          if (platform === "android") {
            bondDevice(res);
          } else {
            getBleServiceForIOS(res);
          }
        }

        if (platform === "ios" && res?.error === "connect") {
          callback({ ...res, status: "pairingLost" });
        }
      },
      function (err: any) {
        console.log("Retry:: Connection error", err);
        saveBleLog(
          "error",
          `Retry connect error:: ${JSON.stringify(err)}`
        );
        if (
          err?.error === "connect" &&
          err?.message === "Connection failed" &&
          retryConnectionAttempt < 2
        ) {
          checkDeviceAvailability(err);
        } else if (
          connectionType === "connect" &&
          retryConnectionAttempt >= 1
        ) {
          delete localStorage.isConnectingInProgress;
          callback({ ...device, isConnected: false });
        }
      },
      {
        address: device.address,
      }
    );
  };

  const bondDevice = (device: any) => {
    console.log("bondDevice", device.address);
    saveBleLog("info", "Calling bond device");
    (window as any).bluetoothle.isBonded(
      function (res: any) {
        console.log("Device isBonded response::", res);
        saveBleLog(
          "info",
          `Calling bond device reponse:: ${JSON.stringify(res)}`
        );
        if (res.isBonded) {
          discoverDevice(res);
        } else {
          bondAgain(res);
        }
      },
      function (err: any) {
        console.log("isBonded error", err);
        saveBleLog(
          "error",
          `Calling bond device reponse:: ${JSON.stringify(err)}`
        );
        if (err?.error === "bond" && err?.message === "Device already bonded") {
          discoverDevice(err);
        }
      },
      {
        address: device.address,
      }
    );
  };

  const bondAgain = (device: any) => {
    saveBleLog("info", `Calling bond again device`);
    (window as any).bluetoothle.bond(
      function (res: any) {
        console.log("Bond response", res);
        saveBleLog(
          "info",
          `Bond again reponse:: ${JSON.stringify(res)}`
        );
        if (res?.status === "bonded") {
          discoverDevice(res);
        }

        if (res?.status === "unbonded") {
          callback({
            name: res?.name,
            address: res?.address,
            mac_address: bleDevice?.mac_address,
            status: "pairingLost",
          });
        }
      },
      function (err: any) {
        console.log("Bond again error", err);
        saveBleLog(
          "error",
          `Bond again error:: ${JSON.stringify(err)}`
        );
        if (err?.message === "Device already bonded") {
          discoverDevice(err);
        }
      },
      {
        address: device.address,
      }
    );
  };

  const discoverDevice = (device: any) => {
    console.log("discoverDevice", device);
    saveBleLog("info", "Calling discover device");
    (window as any).bluetoothle.discover(
      function (res: any) {
        saveBleLog(
          "info",
          `Discover device reponse:: ${JSON.stringify(res)}`
        );
        console.log(
          "Read device service and characteristics discover response: success::",
          res
        );
        if (res?.status === "discovered") {
          callback({
            ...device,
            mac_address: bleDevice?.mac_address,
            isConnected: true,
          });
        }
        delete localStorage.isConnectingInProgress;
      },
      function (err: any) {
        console.log("Device service and characteristics discover error::", err);
        saveBleLog(
          "error",
          `Discover device error:: ${JSON.stringify(err)}`
        );
        delete localStorage.isConnectingInProgress;
        if (err?.error === "isNotConnected") {
          connectNewBle(err);
        }
      },
      {
        address: device.address,
        clearCache: true,
      }
    );
  };

  const checkDeviceAvailability = (device: any) => {
    console.log("checkDeviceAvailability");
    saveBleLog(
      "info",
      `Calling check device availability:: start scanning for device`
    );
    console.log("Retry connecting attempt::", retryConnectionAttempt);
    (window as any).bluetoothle.startScan(
      function (res: any) {
        console.log("Search device:", res);
        saveBleLog(
          "info",
          `Device availability:: found devices:: ${JSON.stringify(res)}`
        );
        if (res?.address === device?.address) {
          (window as any).bluetoothle.stopScan();
          disconnectConnectedDevice(res);
          retryConnectionAttempt += 1;
        }
      },
      function (err: any) {
        console.log("Device availability search error", err);
        saveBleLog(
          "error",
          `Device availability:: found devices error:: ${JSON.stringify(err)}`
        );
        disconnectConnectedDevice(err);
      },
      {
        services: [
          deviceServices.serviceUUID.pairingMode,
          deviceServices.serviceUUID.notPairingMode,
        ],
        allowDuplicates: false,
      }
    );
  };

  const getBleServiceForIOS = (device: any) => {
    saveBleLog("info", "Calling ble services for ios");
    (window as any).bluetoothle.services(
      function (res: any) {
        console.log("Services IOS::", res);
        saveBleLog(
          "info",
          `iOS services response:: ${JSON.stringify(res)}`
        );
        if (res?.status === "services") {
          getBleCharacteristicsForIOS(res);
        }
      },
      function (err: any) {
        console.log("Device service discover error::", err);
        saveBleLog(
          "error",
          `iOS services error:: ${JSON.stringify(err)}`
        );
        if (err?.error === "isNotDisconnected") {
          reconnectDevice(err);
        }
      },
      {
        address: device.address,
        services: [
          deviceServices.luetInfo.service,
          deviceServices.luetOtaInfo.service,
        ],
      }
    );
  };

  const getBleCharacteristicsForIOS = (device: any) => {
    saveBleLog("info", "Calling ios ble characteristics");
    (window as any).bluetoothle.characteristics(
      function (res: any) {
        console.log("Characteristics for nortStarInfo service IOS::", res);
        saveBleLog(
          "info",
          `IOS characteristics response:: ${JSON.stringify(res)}`
        );
        if (res?.status === "characteristics") {
          (window as any).bluetoothle.characteristics(
            function (res: any) {
              console.log("Characteristics for luetOtaInfo serviceIOS::", res);
              if (res?.status === "characteristics") {
                delete localStorage.isConnectingInProgress;
                callback({
                  ...device,
                  mac_address: bleDevice?.mac_address,
                  isConnected: true,
                });
              }
            },
            function (err: any) {
              console.log(
                "Device service and characteristics discover error::",
                err
              );
            },
            {
              address: device.address,
              service: deviceServices.luetOtaInfo.service,
              characteristics: [],
            }
          );
          delete localStorage.isConnectingInProgress;
        }
      },
      function (err: any) {
        console.log("Device service and characteristics discover error::", err);
        saveBleLog(
          "error",
          `IOS characteristics error:: ${JSON.stringify(err)}`
        );
      },
      {
        address: device.address,
        service: deviceServices.luetInfo.service,
        characteristics: [],
      }
    );
  };

  if (isNativePlatform) {
    (window as any).bluetoothle.isEnabled(function (res: any) {
      console.log("BLE isEnabled", res);
      if (res?.isEnabled) {
        if (connectionType === "connect") {
          onDeviceSelect(bleDevice);
        } else if (connectionType === "reconnect") {
          onDeviceReconnectById(bleDevice);
        }
      }
    });
  }
};

const getIOSDeviceMacAddress = async (mfgAdvData: string) => {
  let mfgData = new Uint8Array(
    (window as any).bluetoothle.encodedStringToBytes(mfgAdvData)
  );
  mfgData = mfgData?.slice(2, mfgData?.length);
  return Array.prototype.map
    .call(mfgData, function (byte) {
      return ("0" + (byte & 0xff).toString(16)).slice(-2);
    })
    .join(":")
    ?.toUpperCase();
};

export const scanAvailableDevices = async (callback: any) => {
  (window as any).bluetoothle.startScan(
    async function (res: any) {
      console.log("Device", res);
      if (res?.status === "scanResult") {
        if (platform === "ios") {
          res.mac_address =
            (await getIOSDeviceMacAddress(
              res?.advertisement?.manufacturerData
            )) || "";
        } else {
          res.mac_address = res.address;
        }
        callback(res);
      }
    },
    function (err: any) {
      console.log("Device search error", err);
      callback(err);
    },
    {
      services: [
        deviceServices.serviceUUID.pairingMode,
        deviceServices.serviceUUID.notPairingMode,
      ],
      allowDuplicates: false,
    }
  );
};

export const BleSearchDevice = async (isPairingMode: boolean, cb: any) => {
  console.log("Calling Search NS");
  console.log("Searching Luet device");
  let services = [];

  if (isPairingMode) {
    services = [deviceServices.serviceUUID.pairingMode];
  } else {
    services = [
      deviceServices.serviceUUID.pairingMode,
      deviceServices.serviceUUID.notPairingMode,
    ];
  }
  (window as any).bluetoothle?.startScan(
    async (res: any) => {
      console.log("Device", res);
      if (res.status === "scanResult") {
        if (platform === "ios") {
          res.mac_address =
            (await getIOSDeviceMacAddress(
              res?.advertisement?.manufacturerData
            )) || "";
        } else {
          res.mac_address = res.address;
        }
        cb(res);
      }
    },
    (err: any) => {
      console.log("Ble sesrch device error", err);
      if (
        err.error === "startScan" &&
        err.message === "Scanning already in progress"
      ) {
        (window as any).bluetoothle.stopScan();
        BleSearchDevice(isPairingMode, cb);
      }
    },
    {
      services,
      allowDuplicates: false,
    }
  );
};

export const stopBleScan = () => {
  (window as any).bluetoothle.stopScan();
};

const bleIntToBytes = (value: any) => {
  const bytes = new Uint8Array(8);
  bytes[0] = value & 0xff;
  bytes[1] = (value >> 8) & 0xff;
  bytes[2] = (value >> 16) & 0xff;
  bytes[3] = (value >> 24) & 0xff;
  return bytes;
};

const closeConnection = (deviceId: any, AppLogger: any, callback: any) => {
  console.log("Closing connection");
  (window as any).bluetoothle.close(
    function (res: any) {
      console.log("Device connection closed::", res);
      addLog(
        AppLogger,
        deviceId,
        "info",
        `Device manual close response:: ${JSON.stringify(res)}`
      );
      callback({ disconnected: true });
    },
    function (err: any) {
      console.log("Device connection close error", err);
      addLog(
        AppLogger,
        deviceId,
        "info",
        `Device manual close error:: ${JSON.stringify(err)}`
      );
      callback({ disconnected: true });
    },
    {
      address: deviceId,
    }
  );
};

export const disconnect = (deviceId: any, AppLogger: any, callback: any) => {
  (window as any).bluetoothle.disconnect(
    function (res: any) {
      console.log("Device disconnected >>>>>>>>>>>", res);
      addLog(
        AppLogger,
        deviceId,
        "info",
        `Device manual disconnect response:: ${JSON.stringify(res)}`
      );
      if (res?.status === "disconnected") {
        closeConnection(deviceId, AppLogger, callback);
      }
    },
    function (err: any) {
      console.log("Device disconnect error", err);
      addLog(
        AppLogger,
        deviceId,
        "info",
        `Device manual disconnect error:: ${JSON.stringify(err)}`
      );
      if (
        err?.error === "connect" ||
        err?.error === "neverConnected" ||
        err?.error === "isDisconnected"
      ) {
        closeConnection(deviceId, AppLogger, callback);
      }
    },
    {
      address: deviceId,
    }
  );
};

const checkIOSPairedState = (deviceId: any, AppLogger: any, callback: any) => {
  (window as any).bluetoothle.read(
    function (res: any) {
      console.log("Test pairing:: read Hardware version read response::", res);
      addLog(
        AppLogger,
        deviceId,
        "info",
        `Test pairing:: read Hardware version read response:: ${JSON.stringify(
          res
        )}`
      );
      const bytes = (window as any).bluetoothle.encodedStringToBytes(res.value);
      const hwVersion = new Int32Array(bytes.buffer);
      console.log("Test pairing:: Hardware version read::", hwVersion[0]);
      callback({ isPaired: true });
    },
    function (err: any) {
      console.log("Test pairing:: Hardware version read error", err);
      addLog(
        AppLogger,
        deviceId,
        "error",
        `Test pairing:: read Hardware version read error:: ${JSON.stringify(
          err
        )}`
      );

      callback({ isPaired: false });
    },
    {
      address: deviceId,
      service: deviceServices.luetInfo.service,
      characteristic: deviceServices.luetInfo.hardwareVersion,
    }
  );
};

const checkAndroidPairedState = (
  deviceId: any,
  AppLogger: any,
  callback: any
) => {
  (window as any).bluetoothle.isBonded(
    function (res: any) {
      console.log("Android state response::", res);
      addLog(
        AppLogger,
        deviceId,
        "info",
        `Test pairing:: Android state respons:: ${JSON.stringify(res)}`
      );
      if (res?.isBonded) {
        callback({ isPaired: true });
      } else {
        callback({ isPaired: false });
      }
    },
    function (err: any) {
      console.log("Android state response error", err);
      addLog(
        AppLogger,
        deviceId,
        "info",
        `Test pairing:: Android state error:: ${JSON.stringify(err)}`
      );
    },
    {
      address: deviceId,
    }
  );
};

export const checkPairedState = (
  deviceId: any,
  AppLogger: any,
  callback: any
) => {
  if (platform === "android") {
    checkAndroidPairedState(deviceId, AppLogger, callback);
  } else {
    checkIOSPairedState(deviceId, AppLogger, callback);
  }
};

export const requestConnectionPriority = (deviceId: any) => {
  console.log("Request Connection Priority");
  (window as any).bluetoothle.requestConnectionPriority(
    function (res: any) {
      console.log("Device connection priority response::", res);
    },
    function (err: any) {
      console.log("Device connection priority error", err);
    },
    {
      address: deviceId,
      connectionPriority: "low",
    }
  );
};

export const writeAudioRecording = (
  deviceId: string,
  statusCode: number,
  cb: any
) => {
  console.log("Write audio recording status", statusCode, deviceId);
  const bytes = bleIntToBytes(statusCode);
  console.log("bytes::", bytes);
  new Promise(function (resolve, reject) {
    (window as any).bluetoothle.write(resolve, reject, {
      address: deviceId,
      service: deviceServices.luetInfo.service,
      characteristic: deviceServices.luetInfo.audio_rec,
      value: (window as any).bluetoothle.bytesToEncodedString(bytes),
    });
  }).then(
    function writeSuccess(res: any) {
      console.log(`Audio recording write::${deviceId}`, res);
      if (res.status === "written") {
        readAudioRecordingStatus(deviceId, cb);
      }
    },
    function writeError(err) {
      console.log("Audio recording write error", err);
      cb(null, err);
    }
  );
};

export const readAudioRecordingStatus = (deviceId: string, cb: any) => {
  console.log("read audio recording flag", deviceId);
  (window as any).bluetoothle.read(
    function (res: any) {
      console.log("Audio recording read response::", res);
      const bytes = (window as any).bluetoothle.encodedStringToBytes(res.value);
      const audioFlag = new Int32Array(bytes.buffer);
      console.log(`Audio recording read::${deviceId}`, audioFlag[0]);
      cb(audioFlag[0], null);
    },

    function (err: any) {
      console.log(`Audio recording read error::${deviceId}`, err);
      cb(null, err);
    },
    {
      address: deviceId,
      service: deviceServices.luetInfo.service,
      characteristic: deviceServices.luetInfo.audio_rec,
    }
  );
};
