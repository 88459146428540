import { Capacitor } from "@capacitor/core";
import { SplashScreen } from "@capacitor/splash-screen";
import { useHistory } from "react-router";
import { Network } from "@capacitor/network";
import { checkAppHealth, verifyRefreshToken } from "./Services/Auth";

const platform = Capacitor.getPlatform();
const Auth = () => {
  const history = useHistory();

  const closeSplashScreen = () => {
    if (Capacitor.isNativePlatform()) {
      SplashScreen.hide();
    }
  };

  const isSessionExit = () => {
    verifyRefreshToken(localStorage?.refresh)
      .then((res) => {
        if (res) {
          closeSplashScreen();
        } else {
          localStorage.clear();
          history.push("/");
          setTimeout(() => {
            closeSplashScreen();
          }, 2000);
        }
      })
      .catch(() => {
        setTimeout(() => {
          closeSplashScreen();
        }, 2000);
      });
  };

  const checkAuthentication = async () => {
    const status = await Network.getStatus();
    if (localStorage.token && localStorage.refresh && status?.connected) {
      isSessionExit();
    } else {
      checkAppHealth()
        .then((res) => {
          if (res?.status === 200) {
            if (platform === "ios") {
              setTimeout(() => {
                closeSplashScreen();
              }, 3000);
            } else {
              setTimeout(() => {
                closeSplashScreen();
              }, 500);
            }
          }
        })
        .catch((err) => {
          if (err?.response?.status === 406) {
            closeSplashScreen();
          }
        });
    }
  };

  if (Capacitor.isNativePlatform()) {
    checkAuthentication();
  }
  return null;
};

export default Auth;
